import { v4 as uuidv4 } from 'uuid';
import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import Lottie from 'react-lottie';
import toast from 'react-hot-toast';

import { GeneralEnglishSprintStore } from 'src/contexts/generalEnglishSprint';
import { useMixpanel } from 'src/utils/hooks';
import { speechToText } from 'src/api/aiSpeakingPartner';
import { IChat, ISpeechToTextPayload } from 'src/interfaces/aiSpeakingPartner';
import loadingAnimation from 'src/assets/lottie/loading-spinner-dots.json';

import { generateTopicRecommendation } from 'src/api/generalEnglishSprint';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const TopicRecommendation = () => {
  const { state, dispatch } = useContext(GeneralEnglishSprintStore);
  const { aiSpeakingPartner } = state;

  const [recommendations, setRecommendations] = useState<Array<string>>([]);

  const { trackEvent } = useMixpanel();

  const { mutate: generateTopics, isLoading } = useMutation(generateTopicRecommendation, {
    onSuccess: (response) => {
      const topicRecommendations = response?.data?.topics;
      setRecommendations(topicRecommendations);
    },
  });

  const { mutate: getSpeech } = useMutation((input: ISpeechToTextPayload) => speechToText(input), {
    onSuccess: (response) => {
      if (aiSpeakingPartner && aiSpeakingPartner.chats) {
        const chatWithId: IChat = {
          ...response.data.chat,
          id: uuidv4(),
        };
        dispatch({
          type: 'SET_DATA',
          payload: {
            aiSpeakingPartner: {
              ...aiSpeakingPartner,
              chats: [...aiSpeakingPartner.chats, chatWithId],
            },
          },
        });
      }
    },
    onError: () => {
      toast.error('Something went wrong. Please try again.', { position: 'bottom-center' });

      if (aiSpeakingPartner) {
        dispatch({
          type: 'SET_DATA',
          payload: {
            aiSpeakingPartner: {
              ...aiSpeakingPartner,
              isRecordingEnabled: true,
              status: 'IDLE',
            },
          },
        });
      }
    },
  });

  const onSelectTopic = (text: string) => () => {
    trackEvent('ASP Set Topic', {
      difficulty: aiSpeakingPartner?.difficulty,
      topicType: 'recommendation',
      topicText: text,
    });
    dispatch({
      type: 'SET_DATA',
      payload: {
        aiSpeakingPartner: {
          ...aiSpeakingPartner,
          isRecordingEnabled: false,
        },
      },
    });
    getSpeech({ text });
  };

  useEffect(() => {
    generateTopics();
  }, []);

  return (
    <div className="fixed bottom-[120px] w-full max-w-[480px]">
      {isLoading && <Lottie options={defaultOptions} width={100} height={50} />}

      <div className="flex overflow-y-scroll gap-2 px-4 no-scrollbar">
        {recommendations?.map((value) => {
          return (
            <button
              key={value}
              className={`p-2 text-center rounded-lg border hover:border-primary hover:bg-secondary transition-all duration-150`}
              onClick={onSelectTopic(value)}
            >
              <span className="block text-xs text-text-primary whitespace-nowrap">{value}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default TopicRecommendation;
