import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from 'src/constants';
import { Storage } from 'src/utils';
import { PrivateRouteProps } from './types';

const PrivateRoute: FC<PrivateRouteProps> = ({ component, protectedType }) => {
  const navigate = useNavigate();
  const Component = component;

  useEffect(() => {
    const userData = Storage.getUserData();
    const accessToken = userData?.accessToken;
    const name = userData?.name;
    const language = userData?.language;
    const courseID = userData?.courseID;

    if (!accessToken) {
      Storage.logout();
      navigate(paths.LOGIN);
    } else {
      if (protectedType === 'profile') {
        if (name && language) {
          navigate(paths.HOME);
        } else {
          navigate(paths.CREATE_PROFILE);
        }
      }
      if (protectedType === 'course') {
        if (!courseID) {
          navigate(paths.HOME);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Component />;
};

export default PrivateRoute;
