import { api } from 'src/config/api';
import { Storage } from 'src/utils';
import {
  IGenerateTextQuestionsResponse,
  ISubmitAnswerPayload,
  ISubmitAnswerResponse,
} from 'src/interfaces/readingExamTrainer';
import { GenerateCourseResponseType } from 'src/interfaces/generalEnglishSprint';

export const createCourseID = async (): Promise<GenerateCourseResponseType> => {
  const token = Storage.getUserData()?.accessToken;
  const response = await api.post('course/exam-trainer-reading', null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const getTextQuestions = async (): Promise<IGenerateTextQuestionsResponse> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const input = {
    course_id: courseID,
  };

  const response = await api.get('exam-trainer/reading/generate-text-question', {
    params: input,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const submitAnswer = async (data: ISubmitAnswerPayload): Promise<ISubmitAnswerResponse> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const input = {
    ...data,
    course_id: courseID,
  };

  const response = await api.post('exam-trainer/reading/submit-answer', input, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
