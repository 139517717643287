import { api } from 'src/config/api';
import { Storage } from 'src/utils';
import {
  IChooseDifficultyPayload,
  IChooseDifficultyResponse,
  IReplyPayload,
  IReplyResponse,
  IResultResponse,
  ISpeechToTextPayload,
  ISpeechToTextResponse,
} from 'src/interfaces/aiSpeakingPartner';
import { GenerateCourseResponseType } from 'src/interfaces/generalEnglishSprint';

export const createCourseID = async (): Promise<GenerateCourseResponseType> => {
  const token = Storage.getUserData()?.accessToken;
  const response = await api.post('course/assistance-speaking-partner', null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const chooseDifficulty = async (
  data: IChooseDifficultyPayload,
): Promise<IChooseDifficultyResponse> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const input = {
    ...data,
    course_id: courseID,
  };

  const response = await api.post('assistance-speaking-partner/difficulty', input, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const speechToText = async (input: ISpeechToTextPayload): Promise<ISpeechToTextResponse> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const formData = new FormData();
  formData.append('course_id', courseID as string);

  Object.entries(input).forEach(([key, value]) => {
    if (key === 'audio') {
      formData.append(key, value, 'audio.wav');
    } else {
      formData.append(key, value);
    }
  });

  const response = await api.post('assistance-speaking-partner/speech-to-text', formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
  return response?.data;
};

export const getReply = async (data: IReplyPayload): Promise<IReplyResponse> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const input = {
    ...data,
    course_id: courseID,
  };

  const response = await api.get('assistance-speaking-partner/reply', {
    params: input,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const getResult = async (): Promise<IResultResponse> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const input = {
    course_id: courseID,
  };

  const response = await api.get('assistance-speaking-partner/result', {
    params: input,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
