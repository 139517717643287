import { Button, Image, Loading, Text } from 'src/components';
import {
  Divider,
  GeneratedTextTitleContainer,
  PracticeDescriptionContainer,
  PracticeStepContainer,
  RefreshContainer,
} from '../styled';
import { useContext, useEffect, useState } from 'react';
import { GeneralEnglishSprintStore } from 'src/contexts/generalEnglishSprint';
import { Storage } from 'src/utils';
import { useMutation } from '@tanstack/react-query';
import RefreshIcon from 'src/assets/images/icon-refresh.svg';
import Lottie from 'react-lottie';
import loadingAnimation from 'src/assets/lottie/loading-skeleton-text.json';
import {
  createCourseID,
  chooseDifficulty,
  generateQuestion,
  generateFeedback,
} from 'src/api/speakingExamTrainer';
import { colors } from 'src/constants';
import AudioControl from '../AudioControl';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Step2 = () => {
  const { dispatch, state } = useContext(GeneralEnglishSprintStore);
  const { speakingExamTrainer } = state;

  // ----- create course -----
  const { mutate: onCreateCourseID, isLoading: isLoadingCreateCourseID } = useMutation(
    createCourseID,
    {
      onSuccess: (response) => {
        console.log('onCreateCourseID terpanggil');

        const courseID = response?.data?.course_id;
        if (courseID) {
          Storage.setUserData({ courseID });
          setNewCourseID(courseID);
        }
      },
    },
  );

  const [newCourseID, setNewCourseID] = useState('');

  useEffect(() => {
    if (newCourseID !== '' && speakingExamTrainer?.difficulty) {
      console.log('terpanggil lagi');

      onChooseDifficulty({ difficulty: speakingExamTrainer?.difficulty });
    }
    // console.log('ada perubahan course id ', userData.courseID);
  }, [newCourseID]);

  // ----- choose difficulty -----
  const { mutate: onChooseDifficulty, isLoading: isLoadingChooseDifficulty } = useMutation(
    chooseDifficulty,
    {
      onSuccess: () => {
        onGenerateQuestion();
      },
    },
  );

  // ----- generate question -----
  const { mutate: onGenerateQuestion, isLoading: isLoadingGenerateQuestion } = useMutation(
    () => generateQuestion(),
    {
      onSuccess: (response) => {
        dispatch({
          type: 'SET_DATA',
          payload: {
            speakingExamTrainer: {
              ...speakingExamTrainer,
              question: response.data?.question,
              answer: response.data?.answer,
              answer_voice_url: response.data?.answer_voice_url,
              answer_voice_timestamps: response.data?.answer_voice_timestamps,
            },
            step: 2,
          },
        });
      },
    },
  );

  const { mutate: onGenerateFeedback, isLoading: isLoadingGenerateFeedback } = useMutation(
    () => generateFeedback(),
    {
      onSuccess: (response) => {
        const {
          voice_eval,
          ielts_estimated_score,
          ielts_estimated_score_feedback,
          grammar_corrections,
          suggestions,
        } = response.data;
        dispatch({
          type: 'SET_DATA',
          payload: {
            speakingExamTrainer: {
              ...speakingExamTrainer,
              voice_eval,
              ielts_estimated_score,
              ielts_estimated_score_feedback,
              grammar_corrections,
              suggestions,
            },
          },
        });
      },
    },
  );

  const onRefresh = () => {
    onCreateCourseID();
  };

  const onSubmit = () => {
    if (speakingExamTrainer?.difficulty === 'easy') {
      dispatch({
        type: 'SET_DATA',
        payload: {
          step: 3,
        },
      });
    } else {
      onGenerateFeedback();
    }
  };

  return (
    <>
      <Loading show={isLoadingGenerateFeedback} />

      <PracticeDescriptionContainer>
        <Text id="txt-heading" size="22px" weight="500" value={'Speaking Exam Trainer'} />
        <Text
          id="txt-description"
          color={colors.grey.G300}
          size="14px"
          margin="0.5rem 0 2rem"
          value={
            speakingExamTrainer?.difficulty === 'easy'
              ? 'Read both the mockup question and its answer carefully. You will be asked to read them aloud to practice your pronunciation.'
              : 'Answer the mockup exam speaking question below to the best of your ability. The AI will provide feed-back on how you can improve your answer.'
          }
        />
      </PracticeDescriptionContainer>

      <PracticeStepContainer>
        {/* <Loading show={isLoadingGenerateResult} /> */}

        <GeneratedTextTitleContainer>
          <Text id="txt-titleGeneratedText" value="Mockup Exam Question" weight="500" size="17px" />
          <RefreshContainer role="button" tabIndex={0} onClick={onRefresh} aria-hidden>
            <Image id="image-refreshText" alt="Refresh" src={RefreshIcon} width="24px" />
          </RefreshContainer>
        </GeneratedTextTitleContainer>

        {isLoadingCreateCourseID || isLoadingChooseDifficulty || isLoadingGenerateQuestion ? (
          <div className="-mx-4 -my-5">
            <Lottie options={defaultOptions} />
          </div>
        ) : (
          <Text
            id="txt-generatedTextResult"
            value={speakingExamTrainer?.question}
            weight="500"
            font="Gelasio"
          />
        )}
        <Divider />

        {speakingExamTrainer?.difficulty === 'easy' ? (
          <>
            <Text
              id="txt-titleTranslateText"
              value="Example Of The Answer"
              weight="500"
              size="17px"
              margin="0 0 0.5rem"
            />
            {isLoadingCreateCourseID || isLoadingChooseDifficulty || isLoadingGenerateQuestion ? (
              <div className="-mx-4 -my-5">
                <Lottie options={defaultOptions} />
              </div>
            ) : (
              <Text
                id="txt-generatedTextResult"
                value={speakingExamTrainer?.answer}
                weight="500"
                font="Gelasio"
              />
            )}
          </>
        ) : (
          <>
            <Text
              id="txt-titleTranslateText"
              value="Record Your Answer"
              weight="500"
              size="17px"
              margin="0 0 0.5rem"
            />
            {speakingExamTrainer?.status === 'LOADING_SPEECH_TO_TEXT' ? (
              <div className="-mx-4 -my-5">
                <Lottie options={defaultOptions} />
              </div>
            ) : (
              <div className="px-4 py-3 rounded-lg bg-[#F2F2F7] font-[Gelasio]">
                {speakingExamTrainer?.answer
                  ? speakingExamTrainer.answer
                  : 'Your answer will be transcribed here...'}
              </div>
            )}
          </>
        )}
        {/* <Divider /> */}
        <div
          className={`${
            !speakingExamTrainer?.answer && speakingExamTrainer?.difficulty === 'difficult'
              ? 'mb-24'
              : ''
          }`}
        />

        {speakingExamTrainer?.difficulty === 'easy' ||
        (speakingExamTrainer?.difficulty === 'difficult' && speakingExamTrainer.answer) ? (
          <Button
            className={`mt-8 ${speakingExamTrainer?.difficulty === 'difficult' ? 'mb-24' : ''}`}
            id="btn-checkGrammar"
            text={
              speakingExamTrainer?.difficulty === 'easy'
                ? 'Practice Your Pronunciation'
                : speakingExamTrainer?.status === 'LOADING_SPEECH_ASSESSMENT'
                ? 'Please wait...'
                : 'Check Your Answer'
            }
            height="50px"
            disabled={
              !speakingExamTrainer?.question ||
              isLoadingCreateCourseID ||
              isLoadingChooseDifficulty ||
              isLoadingGenerateQuestion ||
              speakingExamTrainer?.status === 'LOADING_SPEECH_TO_TEXT' ||
              speakingExamTrainer?.status === 'LOADING_SPEECH_ASSESSMENT'
            }
            onClick={onSubmit}
          />
        ) : null}
      </PracticeStepContainer>

      {speakingExamTrainer?.difficulty === 'difficult' && <AudioControl />}
    </>
  );
};

export default Step2;
