import styled, { css } from 'styled-components';
import { InputContainerProps, InputProps } from './types';
import { colors } from 'src/constants';

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${({ big }) => (big ? 'auto' : '44px')};
  ${({ big }) =>
    big &&
    css`
      min-height: 80px;
    `}
  background: ${colors.grey.G100};
  padding: 1px 1rem;
  border-radius: ${({ big }) => (big ? '8px' : '4px')};
  border: 1px solid ${colors.grey.G400};
  transition: 0.2s;
  :focus-within {
    border-color: ${colors.primary};
  }
`;

export const InputBase = styled.input<InputProps>`
  width: 100%;
  background: ${colors.grey.G100};
  font-family: ${({ font }) => font};
  font-size: 1rem;
  textindent: 0;
  outline: none;
  border: none;
  ::placeholder {
    font-family: 'Inter';
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  :focus {
    outline: none;
  }
`;

export const TextAreaBase = styled.textarea<InputProps>`
  width: 100%;
  min-height: 80px;
  background: ${colors.grey.G100};
  padding: 0.5rem 0;
  font-family: ${({ font }) => font};
  font-size: 1rem;
  text-indent: 0;
  outline: none;
  border: none;
  resize: none;
  overflow: hidden;
  ::placeholder {
    font-family: 'Inter';
  }
`;
