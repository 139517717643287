import { storage } from 'src/constants';
import { StorageTypes } from './types';

const { STORAGE_USER_DATA, STORAGE_IS_NEW_USER } = storage;
const Storage = {} as StorageTypes;

Storage.getUserData = () => {
  return JSON.parse(localStorage.getItem(STORAGE_USER_DATA) || '{}');
};

Storage.setUserData = (data) => {
  const userData = Storage.getUserData();
  const newUserData = { ...userData, ...data };
  localStorage.setItem(STORAGE_USER_DATA, JSON.stringify(newUserData));
};

Storage.removeUserData = () => {
  localStorage.removeItem(STORAGE_USER_DATA);
};

Storage.getIsNewUser = () => {
  const localData = JSON.parse(localStorage.getItem(STORAGE_IS_NEW_USER) || '0');
  return Number(localData);
};

Storage.setIsNewUser = (isNew) => {
  return localStorage.setItem(STORAGE_IS_NEW_USER, JSON.stringify(isNew));
};

Storage.removeIsNewUser = () => {
  localStorage.removeItem(STORAGE_IS_NEW_USER);
};

Storage.logout = () => {
  Storage.removeUserData();
};

export default Storage;
