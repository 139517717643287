import { FC } from 'react';
import Lottie from 'react-lottie';

import { Modal } from 'src/components';
import { IModalResultProps } from 'src/interfaces/aiSpeakingPartner';
import checkAnimation from 'src/assets/lottie/checklist.json';

import { colorHandler } from '../AISpeakingPartner';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: checkAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const ModalResult: FC<IModalResultProps> = ({ data, onLeave }) => {
  if (Object.values(data)?.length > 0) {
    return (
      <Modal id="modal-detailRecored" show={Object.values(data)?.length > 0} onLeave={onLeave}>
        <Lottie options={defaultOptions} width={150} height={150} />
        <div className="text-center">
          <div className="text-4xl font-medium mt-2">Congratulations!</div>
          <p className="text-sm text-text-secondary mt-4">
            You have completed today&apos;s session with your AI-Speaking Partner. Please return
            tomorrow and you will experience the cumulative benefits sooner than you anticipate.
          </p>
          <div className="flex gap-6 my-4 justify-center">
            <div className="text-center">
              <div className="text-primary font-semibold">Spoken words</div>
              <div className="">{data.words_count} words</div>
            </div>
            <div className="text-center">
              <div className="text-primary font-semibold">Pronunciation</div>
              <div className={`${colorHandler(parseInt(data.score))}`}>{data.score}</div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return null;
};

export default ModalResult;
