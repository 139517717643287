import { useContext } from 'react';
import { Button, Loading, Text } from 'src/components';
import { GeneralEnglishSprintStore } from 'src/contexts/generalEnglishSprint';
import {
  Divider,
  GrammarCorrectionItem,
  GrammarCorrectionLists,
  PracticeStepContainer,
} from '../styled';
import { String } from 'src/utils';
import { GenerateQuizInputType } from 'src/api/types';
import { generateQuiz } from 'src/api/generalEnglishSprint';
import { useMutation } from '@tanstack/react-query';
import { useMixpanel } from 'src/utils/hooks';

const Step3 = () => {
  const { dispatch, state } = useContext(GeneralEnglishSprintStore);

  const { trackEvent } = useMixpanel();

  const { mutate, isLoading } = useMutation((input: GenerateQuizInputType) => generateQuiz(input), {
    onSuccess: (response) => {
      dispatch({
        type: 'SET_DATA',
        payload: {
          quizzes: response?.data?.quizzes,
        },
      });
    },
  });

  return (
    <PracticeStepContainer>
      <Loading show={isLoading} />
      <Text id="title-yourText" value="Your Text" weight="500" size="17px" margin="0 0 0.5rem" />
      <Text id="txt-yourText" value={state?.translation?.originalText} font="Gelasio" size="17px" />
      <Divider />

      <Text
        id="title-correctedText"
        value="Corrected Text"
        weight="500"
        size="17px"
        margin="0 0 0.5rem"
      />
      <Text
        id="txt-correctedText"
        value={state?.translation?.correctedText}
        font="Gelasio"
        size="17px"
      />
      <Divider />

      <Text
        id="title-grammarCorrections"
        value="Grammar Corrections"
        weight="500"
        size="17px"
        margin="0 0 0.5rem"
      />
      <GrammarCorrectionLists>
        {state?.translation?.grammarCorrections?.map((val) => {
          return (
            <GrammarCorrectionItem
              key={val}
              dangerouslySetInnerHTML={{ __html: String.specialSentenceWithDoubleQuotes(val) }}
            />
          );
        })}
      </GrammarCorrectionLists>
      <Button
        id="btn-continue"
        text="Continue"
        height="50px"
        onClick={() => {
          trackEvent('GES Done Translation');
          mutate({
            text: state?.translation?.correctedText as string,
          });
        }}
      />
    </PracticeStepContainer>
  );
};

export default Step3;
