import { FC, useEffect, useState } from 'react';
import {
  PhotoContainer,
  ProfileContainer,
  SettingContainer,
  SettingTitleContainer,
  SettingMenu,
  SettingMenuItem,
  SettingMenuLink,
  TopNav,
  SettingMenuOutsideLink,
  SettingMenuLogout,
  TopNavContainer,
  UserCoinsContainer,
} from './styled';
import { Image, Loading, Text } from 'src/components';
import { Storage, String } from 'src/utils';
import AccountIcon from 'src/assets/images/icon-account-circle.svg';
import LanguageIcon from 'src/assets/images/icon-globe-passive.svg';
import ListIcon from 'src/assets/images/icon-list.svg';
import PrivacyIcon from 'src/assets/images/icon-privacy.svg';
import LogoutIcon from 'src/assets/images/icon-logout.svg';
import CoinsPassiveIcon from 'src/assets/images/icon-avidx-coins-passive.svg';
import CoinsActiveIcon from 'src/assets/images/icon-avidx-coins-active.svg';
import { paths } from 'src/constants';
import ModalConfirmation from 'src/components/ModalConfirmation';
import { useLogout, useMixpanel } from 'src/utils/hooks';
import { useQuery } from '@tanstack/react-query';
import { getBalance } from 'src/api/wallet';
import currencyFormat from 'src/utils/currencyFormat';

const Profile: FC = () => {
  const userData = Storage.getUserData();
  const [showLogoutConfimation, setShowLogoutConfirmation] = useState<boolean>(false);

  const { onLogout, isLoading } = useLogout();

  const { trackPageview } = useMixpanel();

  const { data: balance } = useQuery(['balance'], getBalance, {
    select: (response) => response?.data?.balance,
  });

  const menuList = [
    {
      label: 'Purchase Coins',
      icon: CoinsPassiveIcon,
      outside: false,
      link: paths.PURCHASE_COINS,
    },
    {
      label: 'Native Language',
      icon: LanguageIcon,
      outside: false,
      link: paths.PROFILE_LANGUANGE,
    },
    {
      label: 'Term of Service',
      icon: ListIcon,
      outside: true,
      link: 'https://www.avidx.app/terms.html',
    },
    {
      label: 'Privacy Policy',
      icon: PrivacyIcon,
      outside: true,
      link: 'https://www.avidx.app/privacy.html',
    },
  ];

  useEffect(() => {
    trackPageview();
  }, []);

  return (
    <>
      <Loading show={isLoading} />
      <ModalConfirmation
        title="Logout?"
        showModalConfirmation={showLogoutConfimation}
        onLeave={() => setShowLogoutConfirmation(false)}
        buttonProps={{
          left: {
            id: 'btn-logout',
            text: 'Logout',
            onClick: onLogout,
          },
          right: {
            id: 'btn-cancel',
            text: 'Cancel',
            onClick: () => setShowLogoutConfirmation(false),
          },
        }}
      />

      <TopNavContainer>
        <TopNav>
          <Text id="txt-heading" size="17px" value="Profile" align="center" />
        </TopNav>
      </TopNavContainer>
      <ProfileContainer>
        <PhotoContainer>
          <Image id="image-account" alt={userData?.name as string} src={AccountIcon} width="80px" />
          <Text
            id="txt-username"
            size="17px"
            weight="500"
            value={userData?.name}
            align="center"
            margin="0.5rem 0"
          />
          <UserCoinsContainer>
            <Image id="image-userCoins" alt="user coins" src={CoinsActiveIcon} width="23" />
            <Text
              id="txt-coins"
              size="15px"
              weight="500"
              value={currencyFormat(balance || 0, {
                delimiter: ',',
                currencySymbol: '',
              })}
            />
          </UserCoinsContainer>
        </PhotoContainer>
        <SettingContainer>
          <SettingTitleContainer>
            <Text id="txt-setting" size="17px" weight="500" value="Setting" margin="0.5rem 0 0" />
          </SettingTitleContainer>
          <SettingMenu>
            {menuList?.map(({ label, icon, outside, link }) => {
              return (
                <SettingMenuItem key={label}>
                  {outside ? (
                    <SettingMenuOutsideLink href={link} target="_blank" rel="noreferrer">
                      <Image
                        id={`image-${String.convertToCamelCase(label)}`}
                        alt={label}
                        src={icon}
                        width="24"
                      />
                      <Text
                        id={`txt-${String.convertToCamelCase(label)}`}
                        size="17px"
                        value={label}
                      />
                    </SettingMenuOutsideLink>
                  ) : (
                    <SettingMenuLink to={link}>
                      <Image
                        id={`image-${String.convertToCamelCase(label)}`}
                        alt={label}
                        src={icon}
                        width="24"
                      />
                      <Text
                        id={`txt-${String.convertToCamelCase(label)}`}
                        size="17px"
                        value={label}
                      />
                    </SettingMenuLink>
                  )}
                </SettingMenuItem>
              );
            })}
            <SettingMenuItem>
              <SettingMenuLogout
                role="button"
                tabIndex={0}
                onClick={() => setShowLogoutConfirmation(true)}
                aria-hidden
              >
                <Image id="image-logout" alt="logout" src={LogoutIcon} width="24" />
                <Text id="txt-logout" size="17px" value="Logout" />
              </SettingMenuLogout>
            </SettingMenuItem>
          </SettingMenu>
        </SettingContainer>
      </ProfileContainer>
    </>
  );
};

export default Profile;
