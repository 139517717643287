import { api } from 'src/config/api';
import {
  GetTopUpPriceListResponse,
  GetTransactionHistoryInput,
  GetTransactionHistoryResponse,
  GetWalletResponse,
  TopUpInput,
  TopUpResponse,
} from 'src/interfaces/wallet';
import { Storage } from 'src/utils';

export const getBalance = async (): Promise<GetWalletResponse> => {
  const token = Storage.getUserData()?.accessToken;
  const response = await api.get('wallet/balance', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const getTopUpPriceList = async (): Promise<GetTopUpPriceListResponse> => {
  const token = Storage.getUserData()?.accessToken;
  const response = await api.get('wallet/topup', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const topUp = async (input: TopUpInput): Promise<TopUpResponse> => {
  const token = Storage.getUserData()?.accessToken;
  const response = await api.post('wallet/topup', input, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const getTransactionHistory = async ({
  limit,
  page,
}: GetTransactionHistoryInput): Promise<GetTransactionHistoryResponse> => {
  const token = Storage.getUserData()?.accessToken;
  const response = await api.get(`wallet/history?record-per-page=${limit}&current-page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
