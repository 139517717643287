import { Button, Text } from 'src/components';
import { FinishContainer, PracticeStepContainer } from '../styled';
import Lottie from 'react-lottie';
import checklistAnimation from 'src/assets/lottie/checklist.json';
import { useNavigate } from 'react-router-dom';
import { colors, paths } from 'src/constants';
import { useContext } from 'react';
import { GeneralEnglishSprintStore } from 'src/contexts/generalEnglishSprint';

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: checklistAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const FinishStep = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(GeneralEnglishSprintStore);

  const onBackHome = () => {
    dispatch({
      type: 'INIT_STEP',
    });
    navigate(paths.HOME);
  };

  return (
    <PracticeStepContainer>
      <FinishContainer>
        <div>
          <Lottie options={defaultOptions} height={150} width={150} />
          <Text
            id="txt-cool"
            value="Cool!"
            size="34px"
            weight="500"
            align="center"
            margin="0.5rem 0 0"
          />
          <Text
            id="txt-decsFinish"
            value={`You've done Today's Writing Exam Training and get one day closer to have a breakthrough in your English Writing skills. \n \n Please comeback tomorrow and you'll feel the compound effect sooner than you expect.`}
            size="14px"
            align="center"
            margin="1rem 0 1.25rem"
            lineHeight="20px"
            color={colors.grey.G300}
          />
          <Button id="btn-backToHome" text="Back To Home" height="50px" onClick={onBackHome} />
        </div>
      </FinishContainer>
    </PracticeStepContainer>
  );
};

export default FinishStep;
