import { FC } from 'react';
import { CircleButtonProps } from './types';
import { CircleButtonBase } from './styled';
import { colors } from 'src/constants';
import Image from '../Image';

const CircleButton: FC<CircleButtonProps> = ({
  id,
  size,
  background,
  onClick,
  onKeyDown,
  disabled,
  icon,
  iconSize,
  tabIndex,
  className,
}) => {
  return (
    <CircleButtonBase
      id={id}
      tabIndex={tabIndex}
      size={size}
      background={disabled ? colors.grey.G100 : background}
      onClick={onClick}
      onKeyDown={onKeyDown}
      disabled={disabled}
      className={className}
    >
      {icon && (
        <Image
          id={`${id}-buttonIcon`}
          src={icon}
          alt="Button Icons"
          width={iconSize}
          height={iconSize}
        />
      )}
    </CircleButtonBase>
  );
};

CircleButton.defaultProps = {
  size: '50px',
  background: colors.primary,
  onClick: () => null,
  onKeyDown: () => null,
  disabled: false,
  icon: '',
  iconSize: '20px',
  tabIndex: undefined,
};

export default CircleButton;
