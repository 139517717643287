import { Button, Image, Loading, Text } from 'src/components';
import {
  Divider,
  GeneratedTextTitleContainer,
  InputTranslate,
  PracticeStepContainer,
  RefreshContainer,
  TopicsRecommendationItem,
} from '../styled';
import { useContext, useState } from 'react';
import { GeneralEnglishSprintStore } from 'src/contexts/generalEnglishSprint';
import { colors } from 'src/constants';
import { String } from 'src/utils';
import { useMutation } from '@tanstack/react-query';
import {
  CheckGrammarInputType,
  CreateTextFromTopicsInputType,
  GeneratedTextSubmitInputType,
} from 'src/api/types';
import {
  checkGrammar,
  createTextFromTopics,
  generatedTextSubmit,
} from 'src/api/generalEnglishSprint';
import RefreshIcon from 'src/assets/images/icon-refresh.svg';
import { useMixpanel } from 'src/utils/hooks';

const Step2 = () => {
  const { dispatch, state } = useContext(GeneralEnglishSprintStore);
  const topic = state?.topic;
  const mode = state?.mode as string;
  const [translate, setTranslate] = useState<string>('');

  const { trackEvent } = useMixpanel();

  const { mutate: refreshText, isLoading: isLoadingRefreshText } = useMutation(
    (input: CreateTextFromTopicsInputType) => createTextFromTopics(input),
    {
      onSuccess: (response) => {
        dispatch({
          type: 'SET_DATA',
          payload: {
            topic: state?.topic,
            mode: state?.mode,
            generatedText: response?.data?.generated_text,
            step: state?.step,
          },
        });
      },
    },
  );

  const { mutate: onCheckGrammar, isLoading: isLoadingCheckGrammar } = useMutation(
    (input: CheckGrammarInputType) => checkGrammar(input),
    {
      onSuccess: (response) => {
        dispatch({
          type: 'SET_DATA',
          payload: {
            translation: {
              originalText: response?.data?.original_text,
              correctedText: response?.data?.corrected_text,
              grammarCorrections: Array.from(
                new Set(response?.data?.grammar_corrections?.filter(Boolean)),
              ),
            },
          },
        });
      },
    },
  );

  const { mutate: onGeneratedTextSubmit, isLoading: isLoadingGeneratedTextSubmit } = useMutation(
    (input: GeneratedTextSubmitInputType) => generatedTextSubmit(input),
    {
      onSuccess: () => {
        onCheckGrammar({
          native_text: state?.generatedText as string,
          user_translated_text: translate,
        });
      },
    },
  );

  const onRefresh = () => {
    refreshText({
      mode,
      topic,
    });
  };

  const onSubmit = () => {
    trackEvent('GES Check Grammar');
    onGeneratedTextSubmit({
      topic,
      generated_text: state?.generatedText as string,
    });
  };

  return (
    <PracticeStepContainer>
      <Loading
        show={isLoadingRefreshText || isLoadingGeneratedTextSubmit || isLoadingCheckGrammar}
      />
      {state?.topic && (
        <TopicsRecommendationItem isActive>
          <Text
            id="txt-titleTopics"
            value="Your Selected Topic"
            size="12px"
            color={colors.grey.G300}
            margin="0 0 0.25rem"
          />
          <Text
            id={`txt-${String.convertToCamelCase(`${state?.topic}`)}`}
            value={state?.topic}
            weight="500"
            size="17px"
          />
        </TopicsRecommendationItem>
      )}

      <GeneratedTextTitleContainer>
        <Text
          id="txt-titleGeneratedText"
          value="Generated Text Based on Topic"
          weight="500"
          size="17px"
        />
        <RefreshContainer role="button" tabIndex={0} onClick={onRefresh} aria-hidden>
          <Image id="image-refreshText" alt="Refresh" src={RefreshIcon} width="24px" />
        </RefreshContainer>
      </GeneratedTextTitleContainer>
      <Text id="txt-generatedTextResult" value={state?.generatedText} weight="500" font="Gelasio" />
      <Divider />
      <Text
        id="txt-titleTranslateText"
        value="Translate The Generated Text"
        weight="500"
        size="17px"
        margin="0 0 0.5rem"
      />
      <InputTranslate
        id="txt-tranlate"
        bigAutoResize
        placeholder="Enter the translate...."
        value={translate}
        font="Gelasio"
        onChange={(e) => setTranslate(e?.target?.value)}
      />
      <Button
        id="btn-checkGrammar"
        text="Check Your Grammar"
        height="50px"
        disabled={!translate}
        onClick={onSubmit}
      />
    </PracticeStepContainer>
  );
};

export default Step2;
