import { colors, paths } from 'src/constants';
import Image from '../Image/Image';
import Text from '../Text/Text';
import { BottomNav, Container, MenuItemContainer } from './styled';
import { Link, useLocation } from 'react-router-dom';
import HomeActive from 'src/assets/images/icon-home-active.svg';
import HomePassive from 'src/assets/images/icon-home-passive.svg';
import PersonActive from 'src/assets/images/icon-person-active.svg';
import PersonPassive from 'src/assets/images/icon-person-passive.svg';
import { FC } from 'react';

const Menu: FC = () => {
  const location = useLocation();
  const { pathname } = location;
  const isHome = pathname?.includes('home');
  const pathNeedMenu = [paths.HOME, paths.PROFILE];

  if (pathNeedMenu?.includes(pathname)) {
    return (
      <Container>
        <BottomNav>
          <MenuItemContainer>
            <Link to={paths.HOME}>
              <Image
                id="image-home"
                alt="Home"
                src={isHome ? HomeActive : HomePassive}
                width="28px"
              />
              <Text
                id="txt-home"
                align="center"
                value="Home"
                color={isHome ? colors.primary : colors.grey.G700}
                size="10px"
              />
            </Link>
          </MenuItemContainer>
          <MenuItemContainer>
            <Link to={paths.PROFILE}>
              <Image
                id="image-profile"
                alt="Profile"
                src={isHome ? PersonPassive : PersonActive}
                width="28px"
              />
              <Text
                id="txt-profile"
                align="center"
                value="Profile"
                color={isHome ? colors.grey.G700 : colors.primary}
                size="10px"
              />
            </Link>
          </MenuItemContainer>
        </BottomNav>
      </Container>
    );
  }

  return null;
};

export default Menu;
