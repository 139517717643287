import { FC } from 'react';
import { ButtonProps } from './types';
import { ButtonBase } from './styled';
import { colors } from 'src/constants';
import Text from '../Text';
import Image from '../Image';

const Button: FC<ButtonProps> = ({
  id,
  text,
  customTextColor,
  width,
  height,
  background,
  onClick,
  onKeyDown,
  disabled,
  submit,
  icon,
  iconPosition,
  iconSize,
  tabIndex,
  className,
  spaceBetweenIcon,
}) => {
  const textColor = disabled
    ? colors?.grey?.G200
    : customTextColor
    ? customTextColor
    : colors?.white;

  const textWeight = background === 'transparent' ? '400' : '600';

  return (
    <ButtonBase
      id={id}
      tabIndex={tabIndex}
      width={width}
      height={height}
      background={disabled ? colors.grey.G100 : background}
      onClick={onClick}
      onKeyDown={onKeyDown}
      disabled={disabled}
      className={className}
      type={submit ? 'submit' : 'button'}
      spaceBetweenIcon={spaceBetweenIcon}
    >
      {text && iconPosition === 'left' && icon && (
        <Image id={`${id}-buttonIcon`} src={icon} alt="Button Icons" width={iconSize} />
      )}

      {text ? (
        <Text id={`${id}-buttonText`} value={text} weight={textWeight} color={textColor} />
      ) : icon ? (
        <Image id={`${id}-buttonIcon`} src={icon} alt="Button Icons" width={iconSize} />
      ) : null}

      {text && iconPosition === 'right' && icon && (
        <Image id={`${id}-buttonIcon`} src={icon} alt="Button Icons" width={iconSize} />
      )}
    </ButtonBase>
  );
};

Button.defaultProps = {
  text: '',
  width: '100%',
  height: '40px',
  background: colors.primary,
  onClick: () => null,
  onKeyDown: () => null,
  disabled: false,
  submit: false,
  icon: '',
  iconPosition: undefined,
  iconSize: '20px',
  tabIndex: undefined,
};

export default Button;
