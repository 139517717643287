import { FC, useEffect, useState } from 'react';
import { Button, Loading, Text } from 'src/components';
import { LoginContainer } from './styled';
import HerroBannerAnimation from 'src/assets/lottie/hero-login.json';
import GoogleIcon from 'src/assets/images/icon-google.svg';
import { colors, paths } from 'src/constants';
import { UserCredential, getAdditionalUserInfo, signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from 'src/config/firebase';
import { Storage } from 'src/utils';
import { useMutation } from '@tanstack/react-query';
import { LoginInput } from 'src/api/types';
import { signIn } from 'src/api/auth';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import { useMixpanel } from 'src/utils/hooks';

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: HerroBannerAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Login: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const { trackPageview, trackEvent } = useMixpanel();

  const { mutate: login } = useMutation((userData: LoginInput) => signIn(userData), {
    onSuccess: (response) => {
      const isProfileSet = response?.data?.already_select_native_language;
      const accessToken = response?.data?.access_token;
      const refreshToken = response?.data?.refresh_token;
      const name = response?.data?.display_name;
      const language = response?.data?.language;
      trackEvent('Signin Completed', { newUser: !isProfileSet, nativeLanguage: language });
      Storage.setUserData({ accessToken, refreshToken, name, language });
      if (isProfileSet) {
        navigate(paths.HOME);
      } else {
        navigate(paths.CREATE_PROFILE);
      }
    },
  });

  const signInWithGoogle = async () => {
    try {
      const result: UserCredential = await signInWithPopup(auth, googleProvider);
      console.log(getAdditionalUserInfo(result));

      const token = await result?.user?.getIdToken();
      if (token) {
        login({
          token,
          provider: 'google',
        });
        setLoading(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const userData = Storage.getUserData();
    const isHasToken = !!userData?.accessToken;
    const isProfileCreate = !!userData?.name && !!userData?.language;
    trackPageview();

    if (isHasToken) {
      if (isProfileCreate) {
        navigate(paths.HOME);
      } else {
        navigate(paths.CREATE_PROFILE);
      }
    }
  }, []);

  return (
    <LoginContainer className="plausible-event-name=Google+Signin">
      <Loading show={loading} />
      <div>
        <Lottie options={defaultOptions} width="80%" />
      </div>
      <Text
        id="txt-headingLogin"
        size="28px"
        weight="700"
        value="Start practicing your English skills today!"
      />
      <Text
        id="txt-descriptionLogin"
        margin="0.5rem 0 3rem"
        value="Build a new language learning habit. Have a breakthrough on your Intermediate English Plateau. Achieve this by doing deliberate and intentional daily practice."
      />
      <Button
        id="btn-loginByGoogle"
        background={colors.blue.B200}
        text="Sign in with Google"
        icon={GoogleIcon}
        iconPosition="left"
        iconSize="24p"
        height="50px"
        onClick={signInWithGoogle}
      />
      <div style={{ textAlign: 'center', marginTop: '1.5rem' }}>
        <Text
          id="txt-continuing"
          color={colors.grey.G300}
          display="inline"
          weight="500"
          value="By continuing, you agree to our "
        />
        <a href="https://www.avidx.app/terms.html" target="_blank" rel="noreferrer">
          <Text
            id="txt-tnc"
            color={colors?.blue?.B400}
            display="inline"
            weight="500"
            value="Terms of Service "
          />
        </a>
        <Text id="txt-and" color={colors.grey.G300} display="inline" weight="500" value="and " />
        <a href="https://www.avidx.app/privacy.html" target="_blank" rel="noreferrer">
          <Text
            id="txt-privacyPolicy"
            color={colors?.blue?.B400}
            display="inline"
            weight="500"
            value="Privacy Policy."
          />
        </a>
      </div>
    </LoginContainer>
  );
};

export default Login;
