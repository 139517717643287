const paths = {
  LOGIN: '/login',
  HOME: '/home',
  CREATE_PROFILE: '/create-profile',
  PROFILE: '/profile',
  PROFILE_LANGUANGE: '/profile/languange',
  PRACTICE: '/practice',
  AI_SPEAKING_PARTNER: '/ai-speaking-partner',
  WRITING_EXAM_TRAINER: '/writing-exam-trainer',
  SPEAKING_EXAM_TRAINER: '/speaking-exam-trainer',
  LISTENING_EXAM_TRAINER: '/listening-exam-trainer',
  READING_EXAM_TRAINER: '/reading-exam-trainer',
  PURCHASE_COINS: '/purchase-coins',
  TRANSACTION_HISTORY: '/transaction-history',
};

export default paths;
