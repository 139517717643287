import { rgba } from 'polished';
import { Button, Input } from 'src/components';
import styled, { css, keyframes } from 'styled-components';
import {
  IsActiveChecker,
  IsUseAnimation,
  TableItemProps,
  TopicsRecommendationItemProps,
} from './types';
import { colors } from 'src/constants';

const Shake = keyframes`
  0% {
    transform: translate(0, 2px);
  }
  50% {
    transform: translate(0, -2px);
  }
  100% {
    transform: translate(0, 0);
  }
`;

export const TopNavContainer = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  z-index: 50;
`;

export const TopNav = styled.div`
  max-width: 480px !important;
  margin: auto;
  background: ${colors.white};
  padding: 7px 1rem 7px 6px;
  box-shadow: 0 0.6px 1.8px ${rgba(0, 0, 0, 0.11)}, 0 3.2px 7.2px ${rgba(0, 0, 0, 0.13)};
`;

export const PracticeContainer = styled.div`
  height: 100%;
  padding-top: 5.25rem;
`;

export const PracticeDescriptionContainer = styled.div`
  padding: 0 1rem;
`;

export const PracticeStepContainer = styled.div`
  padding: 0 1rem 2rem;
`;

export const InputTopics = styled(Input)`
  margin: 1.5rem 0 1rem;
`;
export const InputTranslate = styled(Input)`
  margin-bottom: 1.5rem;
`;

export const TopicsRecommendationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

export const TopicsRecommendationItem = styled.div<TopicsRecommendationItemProps>`
  ${({ withIcon }) =>
    withIcon &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3px;
    `}
  background: ${({ isActive }) => (isActive ? colors.purple.P300 : colors.white)};
  padding: 8px;
  border: 1px solid ${({ isActive }) => (isActive ? colors.primary : colors.grey.G400)};
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;

  :hover {
    background: ${colors.purple.P300};
    border-color: colors.primary;
  }
`;

export const GeneratedTextTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1.25rem 0 11px;
`;

export const RefreshContainer = styled.div`
  display: grid;
  place-items: center;
  cursor: pointer;
`;

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: ${colors.grey.G600};
  margin: 1.5rem 0;
`;

export const GrammarCorrectionItem = styled.li`
  font-family: 'Gelasio';
  margin-bottom: 1.5rem;
  font-size: 17px;
`;

export const GrammarCorrectionLists = styled.ul`
  ${GrammarCorrectionItem} {
    span {
      color: ${colors.primary};
      font-family: 'Gelasio';
      font-weight: 600;
    }
  }
`;

export const QuizContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

export const QuizAnswerExplanation = styled.div`
  background: ${colors.blue.B500};
  padding: 8px;
  border: 1px solid ${colors.blue.B400};
  border-radius: 8px;
`;

export const PracticeStepFiveContainer = styled(PracticeStepContainer)`
  padding: 0 1rem 7rem;
`;

export const TextHighlightContainer = styled.span<IsActiveChecker>`
  display: inline;
  background: ${({ isActive }) => (isActive ? rgba(colors.primary, 0.1) : 'unset')};
`;

export const AudioControlContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
`;

export const AudioControlContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 480px !important;
  box-shadow: 0px -3px 7px 0px #00000021;
  padding: 12px 2rem 1.5rem;
  background: ${colors.blue.B600};
`;

export const PlayerButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ButtonControlContainer = styled.button<IsUseAnimation>`
  background: none;
  transition: 0.2s;
  border: none;
  cursor: pointer;
  ${({ useAnimation }) =>
    useAnimation &&
    css`
      animation: ${Shake} 150ms infinite;
    `}
`;

export const ButtonContinue = styled(Button)`
  margin-top: 1.5rem;
`;

export const PracticeStepSixContainer = styled(PracticeStepContainer)`
  padding: 0 1rem 8rem;
`;

export const Card = styled.div<IsActiveChecker>`
  background: ${colors.white};
  margin-bottom: 1rem;
  padding: 12px 8px;
  border: 1px solid ${({ isActive }) => (isActive ? colors.primary : colors.grey.G400)};
  border-radius: 8px;
  cursor: pointer;

  :last-child {
    margin-bottom: 0;
  }
`;

export const CardDivider = styled(Divider)`
  margin: 0.5rem 0;
`;

export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
`;

export const ButtonDetail = styled.button<IsActiveChecker>`
  width: 72px;
  height: 32px;
  background: ${colors.white};
  color: ${({ isActive }) => (isActive ? colors.primary : colors.grey.G400)};
  padding: 0.5rem 1rem;
  border: 1px solid ${({ isActive }) => (isActive ? colors.primary : colors.grey.G400)};
  border-radius: 100px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'not-allowed')};
`;

export const FinishContainer = styled.div`
  display: grid;
  place-items: center;
  height: calc(100vh - 116px);
`;

export const Table = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
`;

export const TableItems = styled.div<TableItemProps>`
  width: 100%;
  @media (min-width: 300px) {
    width: calc(100% / ${({ grid }) => grid?.sm});
  }
  @media (min-width: 425px) {
    width: calc(100% / ${({ grid }) => grid?.md});
  }
`;

export const TableHead = styled.div`
  color: ${colors.white};
  background: ${colors.primary};
  padding: 8px 14px;
  border: 1px solid #e3e8ef;
`;

export const TableData = styled.div`
  background: ${colors.white};
  padding: 8px 14px;
  border: 1px solid #e3e8ef;
`;

export const LinkingText = styled.p`
  display: inline;
  font-size: 15px;
  span {
    font-weight: 600;
  }
`;
