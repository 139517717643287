import styled, { css } from 'styled-components';
import { RadioContainerProps } from './types';
import { colors } from 'src/constants';

export const RadioCircleChild = styled.div`
  width: 15px;
  height: 15px;
  background: ${colors.white};
  border-radius: 50%;
  transition: 0.2s;
`;

export const RadioCircle = styled.div`
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  width: 20px;
  height: 20px;
  margin: 5px 0;
  padding: 1px;
  border-radius: 50%;
  border: 1px solid ${colors.grey.G500};
  transition: 0.2s;
`;

export const RadioInput = styled.input`
  position: absolute;
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 0;
`;

export const RadioLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: 0.2s;

  ${RadioInput}:checked ~ ${RadioCircle} {
    width: 20px;
    height: 20px;
    border: 1px solid ${colors.blue.B100};

    ${RadioCircleChild} {
      background: ${colors.blue.B100};
    }
  }
  :hover {
    ${RadioCircle} {
      border: 1px solid ${colors.blue.B100};
      ${RadioCircleChild} {
        background: ${colors.blue.B100};
      }
    }
  }
`;

export const RadioContainer = styled.div<RadioContainerProps>`
  ${({ direction }) =>
    direction === 'horizontal' &&
    css`
      display: flex;
      ${RadioLabel} {
        margin-right: 2.5rem;
      }
    `}
`;
