import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { CircleButton, Image, Loading, Options, Text } from 'src/components';
import { GeneralEnglishSprintStore } from 'src/contexts/generalEnglishSprint';
import {
  AudioControlContainer,
  PlayerButtonGroup,
  ButtonControlContainer,
  TextHighlightContainer,
  PracticeStepFiveContainer,
  ButtonContinue,
  AudioControlContent,
} from '../styled';
import PlayIcon from 'src/assets/images/icon-play-circle.svg';
import PauseIcon from 'src/assets/images/icon-pause-circle.svg';
import ActorVoiceIcon from 'src/assets/images/icon-voice-actor.svg';
import SkipPrevIcon from 'src/assets/images/icon-skip-previous.svg';
import SkipNextIcon from 'src/assets/images/icon-skip-next.svg';
import { colors, playbackSpeed, voiceOver } from 'src/constants';
import { SpeechFromTextInputType, SpeechFromTextResponseType } from 'src/api/types';
import { useMutation } from '@tanstack/react-query';
import { speechFromText } from 'src/api/generalEnglishSprint';
import { String } from 'src/utils';
import { isAlmostEqual } from '../functions';
import { useMixpanel } from 'src/utils/hooks';

const Step5 = () => {
  const { state, dispatch } = useContext(GeneralEnglishSprintStore);
  const audioRef = useRef<HTMLAudioElement>(null);
  const animationRef = useRef<number>();
  const [dataSpeech, setDataSpeech] = useState<SpeechFromTextResponseType['Timestamps']>([]);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [timeProgress, setTimeProgress] = useState<number>(0);
  const [isRewind, setIsRewind] = useState<boolean>(false);
  const [isForward, setIsForward] = useState<boolean>(false);
  const [isShowVoiceOverOption, setIsShowVoiceOverOption] = useState<boolean>(false);
  const [isChangeVoice, setIsChangeVoice] = useState<boolean>(false);
  const [selectedVoiceOver, setSelectedVoiceOver] = useState<string>('male-0');
  const [isShowPlaybackSpeed, setIsShowPlaybackSpeed] = useState<boolean>(false);
  const [selectedPlaybackSpeed, setSelectedPlaybackSpeed] = useState<number>(1.0);
  const [finishPlay, setFinishPlay] = useState<boolean>(false);

  const { trackEvent } = useMixpanel();

  const { mutate: getSpeech, isLoading } = useMutation(
    (input: SpeechFromTextInputType) => speechFromText(input),
    {
      onSuccess: (response) => {
        dispatch({
          type: 'SET_DATA',
          payload: {
            speechFromText: {
              audioUrl: response?.audio_url,
              voice: selectedVoiceOver,
              Timestamps: response?.Timestamps,
            },
            step: state?.step,
          },
        });
        setStepIndex(0);
        setTimeProgress(0);
        setIsPlaying(false);
        setIsChangeVoice(false);
        if (audioRef.current) {
          audioRef.current.src = response?.audio_url;
          audioRef.current.load();
        }
      },
    },
  );

  useEffect(() => {
    setStepIndex(0);
    setTimeProgress(0);
    setIsPlaying(false);
    setDataSpeech(state?.speechFromText?.Timestamps as SpeechFromTextResponseType['Timestamps']);

    return () => audioRef.current?.pause();
  }, []);

  const repeat = useCallback(() => {
    if (audioRef.current?.currentTime) {
      const currentTime = audioRef.current.currentTime;
      if (!audioRef.current.paused) {
        setTimeProgress(currentTime);
      }
    }
    animationRef.current = requestAnimationFrame(repeat);
  }, [audioRef]);

  const onRewind = () => {
    if (stepIndex > 0) {
      setStepIndex((prev) => {
        const newIndex = prev - 1;
        setIsRewind(true);
        return newIndex;
      });
    } else {
      if (audioRef.current?.currentTime) {
        audioRef.current.currentTime = 0;
      }
    }
  };

  const onForward = (isSetCurrentTime = true) => {
    if (stepIndex < (state?.speechFromText?.Timestamps?.length as number) - 1) {
      setStepIndex((prev) => {
        const newIndex = prev + 1;
        if (isSetCurrentTime) {
          setIsForward(true);
        }
        return newIndex;
      });
    } else {
      if (!isSetCurrentTime) {
        setIsPlaying(false);
        setTimeout(() => {
          if (audioRef.current?.currentTime) {
            audioRef.current.currentTime = 0;
            setTimeProgress(0);
            setStepIndex(0);
          }
        }, 500);
      }
    }
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current?.play();
    } else {
      audioRef.current?.pause();
    }

    animationRef.current = requestAnimationFrame(repeat);
  }, [isPlaying]);

  useEffect(() => {
    if (audioRef.current?.playbackRate) {
      audioRef.current.playbackRate = selectedPlaybackSpeed;
    }
  }, [selectedPlaybackSpeed]);

  useEffect(() => {
    const end = state?.speechFromText?.Timestamps?.[stepIndex]?.end as number;
    if (isPlaying && isAlmostEqual(timeProgress, end) && !isRewind) {
      onForward(false);
    }
  }, [stepIndex, timeProgress, isPlaying]);

  useEffect(() => {
    if (isRewind || isForward) {
      const newValue = state?.speechFromText?.Timestamps?.[stepIndex]?.start as number;
      if (audioRef.current?.currentTime) {
        audioRef.current.currentTime = newValue;
      }
      if (isRewind) {
        setIsRewind(false);
      }
      if (isForward) {
        setIsForward(false);
      }
    }
  }, [stepIndex, isRewind, isForward]);

  useEffect(() => {
    if (isChangeVoice) {
      audioRef.current?.pause();
      getSpeech({
        text: state?.paraphrasedText as string,
        voice_id: selectedVoiceOver,
      });
    }
  }, [selectedVoiceOver, isChangeVoice]);

  useEffect(() => {
    const scrollHandler = () => {
      const element = document.getElementById(`txt-speech-${stepIndex + 1}`);
      const button = document.getElementById('audioControlContainer');
      const elementTop = element?.offsetTop as number;
      const buttonTop = button?.offsetTop as number;

      if (elementTop + 100 >= buttonTop) {
        window.scrollTo({
          top: elementTop,
          behavior: 'smooth',
        });
      }
    };
    if (isPlaying) {
      scrollHandler();
    }
  }, [stepIndex, isPlaying]);

  useEffect(() => {
    if (stepIndex === (state?.speechFromText?.Timestamps?.length as number) - 1) {
      setFinishPlay(true);
    }
  }, [stepIndex]);

  return (
    <>
      <Loading show={isLoading} />
      <audio ref={audioRef} src={state?.speechFromText?.audioUrl} />
      <Options
        id="option-voiceOver"
        title="Voice-over"
        dataList={voiceOver}
        show={isShowVoiceOverOption}
        onLeave={() => setIsShowVoiceOverOption(false)}
        selected={selectedVoiceOver}
        onSelected={(value) => {
          setIsChangeVoice(true);
          setSelectedVoiceOver(value as string);
          trackEvent('GES Change Voice', {
            voiceId: value,
          });
        }}
      />
      <Options
        id="option-playbackSpeed"
        title="Playback speed"
        dataList={playbackSpeed}
        show={isShowPlaybackSpeed}
        onLeave={() => setIsShowPlaybackSpeed(false)}
        selected={selectedPlaybackSpeed}
        onSelected={(value) => {
          setSelectedPlaybackSpeed(value as number);
          trackEvent('GES Change Speed', {
            audioSpeed: value,
          });
        }}
      />
      <PracticeStepFiveContainer>
        {dataSpeech?.length > 0 &&
          dataSpeech?.map((val, index) => {
            return (
              <TextHighlightContainer key={index} isActive={stepIndex === index}>
                <Text
                  id={`txt-speech-${index + 1}`}
                  value={val?.text}
                  display="inline"
                  lineHeight="28px"
                  font="Gelasio"
                  size="17px"
                />
              </TextHighlightContainer>
            );
          })}
        <ButtonContinue
          id="btn-continue"
          text="Let's Test Your Pronunciation"
          height="50px"
          disabled={!finishPlay}
          onClick={() => {
            trackEvent('GES Done Listening');
            dispatch({
              type: 'SET_NEXT_STEP',
            });
          }}
        />
      </PracticeStepFiveContainer>
      <AudioControlContainer id="audioControlContainer">
        <AudioControlContent>
          <ButtonControlContainer tabIndex={0} onClick={() => setIsShowVoiceOverOption(true)}>
            <Image id="img-actorVoice" src={ActorVoiceIcon} width="32px" alt="Voice Over" />
          </ButtonControlContainer>
          <PlayerButtonGroup>
            <ButtonControlContainer
              tabIndex={0}
              onClick={() => {
                trackEvent('GES Sentence Backward');
                onRewind();
              }}
            >
              <Image id="img-skipPrevious" src={SkipPrevIcon} width="32px" alt="Skip Prev" />
            </ButtonControlContainer>
            <CircleButton
              id="btn-playerControl"
              icon={isPlaying ? PauseIcon : PlayIcon}
              background={colors.white}
              size="53px"
              iconSize="53px"
              onClick={() => {
                setIsPlaying((prev) => {
                  if (!prev) trackEvent('GES Play TTS');
                  return !prev;
                });
              }}
            />
            <ButtonControlContainer
              tabIndex={0}
              onClick={() => {
                trackEvent('GES Sentence Forward');
                onForward();
              }}
            >
              <Image id="img-skipNext" src={SkipNextIcon} width="32px" alt="Skip Next" />
            </ButtonControlContainer>
          </PlayerButtonGroup>
          <ButtonControlContainer tabIndex={0} onClick={() => setIsShowPlaybackSpeed(true)}>
            <Text
              id="txt-playbackSpeed"
              value={String.formatFloatString(selectedPlaybackSpeed)}
              size="17px"
              weight="600"
              color={colors.grey.G700}
            />
          </ButtonControlContainer>
        </AudioControlContent>
      </AudioControlContainer>
    </>
  );
};

export default Step5;
