import {
  AudioControlContainer,
  ButtonControlContainer,
  Card,
  CardFooter,
  CardDivider,
  PlayerButtonGroup,
  PracticeStepSixContainer,
  ButtonDetail,
  ButtonContinue,
  AudioControlContent,
} from '../styled';
import VolumePassiveIcon from 'src/assets/images/icon-volume-passive.svg';
import VolumeActiveIcon from 'src/assets/images/icon-volume-active.svg';
import GraphPassiveIcon from 'src/assets/images/icon-graphic-passive.svg';
import GraphActiveIcon from 'src/assets/images/icon-graphic-active.svg';
import RecordIcon from 'src/assets/images/icon-mic.svg';
import StopIcon from 'src/assets/images/icon-stop.svg';
import WaveLeftIcon from 'src/assets/images/icon-record-wave-left.svg';
import WaveRightIcon from 'src/assets/images/icon-record-wave-right.svg';
import { CircleButton, Image, Loading, Text } from 'src/components';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { GeneralEnglishSprintStore } from 'src/contexts/generalEnglishSprint';
import { colors } from 'src/constants';
import { useMutation } from '@tanstack/react-query';
import { SpeechEvalInputType, SpeechFromTextResponseType } from 'src/api/types';
import { speechEval } from 'src/api/generalEnglishSprint';
import { DoneProgressTypes, ModalDataTypes } from '../types';
import Lottie from 'react-lottie';
import loadingAnimation from 'src/assets/lottie/loading-spinner-dots.json';
import { checkAudioAlmostDone, colorHandler } from '../functions';
import ModalDetail from '../ModalDetail/ModalDetail';
import { getWaveBlob } from 'webm-to-wav-converter';
import { useMixpanel } from 'src/utils/hooks';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Step6 = () => {
  const { state, dispatch } = useContext(GeneralEnglishSprintStore);
  const audioRef = useRef<HTMLAudioElement>();
  const audioRecord = useRef<HTMLAudioElement>(null);
  const mediaRecorder = useRef<MediaRecorder>();
  const [dataSpeechExample, setDataSpeechExample] = useState<
    SpeechFromTextResponseType['Timestamps']
  >([]);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [stream, setStream] = useState<MediaStream>();
  const [audioChunks, setAudioChunks] = useState<Array<BlobEvent['data']>>([]);
  const [audio, setAudio] = useState<Array<string>>([]);
  const [isCustomLoading, setIsCustomLoading] = useState<boolean>(true);
  const [isPlayExample, setIsPlayExample] = useState<boolean>(false);
  const [isPlayRecord, setIsPlayRecord] = useState<boolean>(false);
  const [stepIndexActive, setStepIndexActive] = useState<number>(1);
  const [doneProgress, setDoneProgress] = useState<DoneProgressTypes>({});
  const [dataModal, setDataModal] = useState<ModalDataTypes>({} as ModalDataTypes);

  const { trackEvent } = useMixpanel();

  useEffect(() => {
    audioRef.current = new Audio(state?.speechFromText?.audioUrl);
    audioRef.current.load();
    const timer = setTimeout(() => {
      setStepIndexActive(0);
      setIsCustomLoading(false);
    }, 1000);

    return () => {
      audioRef?.current?.pause();
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    return () => {
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [stream]);

  const { mutate: getSpeech, isLoading } = useMutation(
    (input: SpeechEvalInputType) => speechEval(input),
    {
      onSuccess: (response) => {
        setDoneProgress((prev) => {
          return {
            ...prev,
            [stepIndexActive]: {
              overall: response?.data?.result?.overall,
              pronunciation: response?.data?.result?.pronunciation,
              fluency: response?.data?.result?.fluency,
              integrity: response?.data?.result?.integrity,
              rhythm: response?.data?.result?.rhythm,
              speed: response?.data?.result?.speed,
              words: response?.data?.result?.words,
              linking: response?.data?.linking,
              tone: response?.data?.tone,
            },
          };
        });
      },
    },
  );

  const createStream = () => {
    return navigator.mediaDevices.getUserMedia({
      audio: {
        sampleRate: 16000,
        sampleSize: 16,
      },
    });
  };

  const startRecording = async () => {
    //create new Media recorder instance using the stream
    let media: MediaRecorder;
    if (stream) {
      media = new MediaRecorder(stream, { audioBitsPerSecond: 128000 });
    } else {
      const newStream = await createStream();
      setStream(newStream);

      media = new MediaRecorder(newStream, { audioBitsPerSecond: 128000 });
    }

    setIsRecording(true);
    if (isPlayExample) setIsPlayExample(false);
    if (isPlayRecord) setIsPlayRecord(false);
    //set the MediaRecorder instance to the mediaRecorder ref
    mediaRecorder.current = media;
    //invokes the start method to start the recording process
    mediaRecorder.current.start();
    const localAudioChunks: Array<BlobEvent['data']> = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === 'undefined') return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };
    setAudioChunks(localAudioChunks);
  };

  const stopRecording = () => {
    setIsRecording(false);
    //stops the recording instance
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      mediaRecorder.current.onstop = async () => {
        //creates a blob file from the audiochunks data

        const webmBlob = new Blob(audioChunks, { type: 'audio/webm' });
        const wavBlob = await getWaveBlob(webmBlob, false, { sampleRate: 16000 });
        getSpeech({
          text: dataSpeechExample?.[stepIndexActive]?.text,
          index: stepIndexActive,
          total: dataSpeechExample?.length,
          audio: wavBlob,
        });

        const audioUrl = URL.createObjectURL(wavBlob);
        setAudio((prev) => {
          const newPrev = [...prev];
          newPrev[stepIndexActive] = audioUrl;

          return newPrev;
        });

        setAudioChunks([]);
      };
    }
  };

  const speechExample = useMemo(() => {
    return dataSpeechExample?.[stepIndexActive];
  }, [stepIndexActive]);

  const playAudioExample = (index?: number) => {
    audioRef.current = new Audio(state?.speechFromText?.audioUrl);
    audioRef.current.load();
    const newRange = typeof index === 'number' ? dataSpeechExample?.[index] : speechExample;
    const startRange = newRange?.start as number; // Start time in seconds
    if (audioRef.current) {
      if (isPlayRecord) setIsPlayRecord(false);
      audioRef.current.currentTime = startRange;
      setIsPlayExample(true);
    }
  };

  useEffect(() => {
    const audioElement = audioRef?.current;
    if (speechExample && audioElement && isPlayExample) {
      const startRange = speechExample?.start as number; // Start time in seconds
      const endRange = speechExample?.end as number; // End time in seconds
      audioElement?.play();

      // Stop playback when the end of the range is reached
      audioElement?.addEventListener('timeupdate', () => {
        if (checkAudioAlmostDone(audioElement.currentTime, endRange)) {
          setIsPlayExample(false);
          audioElement.pause();
          audioElement.currentTime = startRange;
        }
      });
    }
  }, [speechExample, isPlayExample]);

  const stopAudioExample = () => {
    const audioElement = audioRef?.current;
    const startRange = speechExample?.start as number; // Start time in seconds
    setIsPlayExample(false);
    audioElement?.pause();
    if (audioElement?.currentTime) {
      audioElement.currentTime = startRange;
    }
  };

  const playAudioRecorded = () => {
    const audioElement = audioRecord?.current;
    if (isPlayExample) setIsPlayExample(false);
    setIsPlayRecord(true);
    audioElement?.play();

    audioElement?.addEventListener('timeupdate', () => {
      if (audioElement.ended) {
        setIsPlayRecord(false);
        audioElement.pause();
      }
    });
  };

  const stopAudioRecord = () => {
    const audioElement = audioRecord?.current;
    setIsPlayRecord(false);
    audioElement?.pause();
  };

  useEffect(() => {
    setDataSpeechExample(
      state?.speechFromText?.Timestamps as SpeechFromTextResponseType['Timestamps'],
    );
  }, []);

  const isActiveButtonFinish = useMemo(() => {
    const newArray = Object.values(doneProgress);

    if (newArray?.length === dataSpeechExample?.length) {
      return newArray?.every((items) => items?.overall >= 0);
    }
    return false;
  }, [doneProgress, dataSpeechExample]);

  return (
    <>
      {/* <audio ref={audioRef} src={state?.speechFromText?.audioUrl} /> */}
      {audio[stepIndexActive] && <audio ref={audioRecord} src={audio[stepIndexActive]} />}

      <Loading show={isCustomLoading} />
      <ModalDetail data={dataModal} onLeave={() => setDataModal({} as ModalDataTypes)} />
      <PracticeStepSixContainer>
        {dataSpeechExample?.length > 0 &&
          dataSpeechExample?.map((val, index) => {
            const isActive = stepIndexActive === index;
            const doneProgressHasLength = doneProgress?.[index]?.words?.length > 0;
            const isRecorded = doneProgressHasLength && doneProgress?.[index]?.overall >= 0;

            return (
              <Card
                key={index}
                isActive={isActive}
                tabIndex={0}
                onClick={() => {
                  setStepIndexActive(index);
                  if (isPlayExample) {
                    stopAudioExample();
                    setTimeout(() => playAudioExample(index), 200);
                  }
                  if (isPlayRecord) stopAudioRecord();
                }}
              >
                {isLoading && isActive && (
                  <Lottie options={defaultOptions} width={100} height={50} />
                )}
                {(!isLoading || (isLoading && !isActive)) && (
                  <>
                    {doneProgressHasLength ? (
                      doneProgress[index]?.words?.map((progress, idx) => {
                        return (
                          <Text
                            id={`txt-speech-${index + 1}`}
                            key={idx}
                            value={`${progress?.word}${
                              doneProgress[index]?.words?.length - 1 === idx ? '' : ' '
                            }`}
                            font="Gelasio"
                            size="17px"
                            display="inline"
                            color={colorHandler(progress?.scores?.overall)}
                          />
                        );
                      })
                    ) : (
                      <Text
                        id={`txt-speech-${index + 1}`}
                        value={val?.text}
                        font="Gelasio"
                        size="17px"
                        color={!isActive ? colors.grey.G300 : undefined}
                      />
                    )}
                  </>
                )}
                <CardDivider />
                <CardFooter>
                  <Text
                    id={`txt-percentage-${index + 1}`}
                    value={`${doneProgress?.[index]?.overall || 0}%`}
                    color={
                      isRecorded ? colorHandler(doneProgress?.[index]?.overall || 0) : undefined
                    }
                    size="17px"
                    weight="700"
                  />
                  <ButtonDetail
                    tabIndex={0}
                    disabled={!isRecorded}
                    isActive={isRecorded}
                    onClick={() => {
                      trackEvent('GES Test Pronunciation', {
                        sentence: stepIndexActive,
                        score: doneProgress?.[index]?.overall,
                      });
                      setDataModal(doneProgress[index]);
                    }}
                  >
                    Detail
                  </ButtonDetail>
                </CardFooter>
              </Card>
            );
          })}
        <ButtonContinue
          id="btn-finish"
          text="Finish"
          height="50px"
          disabled={!isActiveButtonFinish}
          onClick={() => {
            trackEvent('GES Finish Session');
            dispatch({
              type: 'SET_FINISH',
            });
          }}
        />
      </PracticeStepSixContainer>
      <AudioControlContainer id="audioControlContainer">
        <AudioControlContent>
          <ButtonControlContainer
            tabIndex={0}
            onClick={() => {
              if (!isPlayExample) {
                playAudioExample();
                trackEvent('GES Play TTS', {
                  sentence: stepIndexActive,
                });
              } else {
                stopAudioExample();
              }
            }}
          >
            <Image
              id="img-playExample"
              src={isPlayExample ? VolumeActiveIcon : VolumePassiveIcon}
              width="32px"
              alt="Play Example Icon"
            />
          </ButtonControlContainer>
          <PlayerButtonGroup>
            {isRecording && (
              <ButtonControlContainer useAnimation>
                <Image id="img-waveLeft" src={WaveLeftIcon} width="24px" alt="Wave Left" />
              </ButtonControlContainer>
            )}
            <CircleButton
              id="btn-playerControl"
              icon={isRecording ? StopIcon : RecordIcon}
              size="64px"
              iconSize="48px"
              onClick={() => {
                if (isRecording) {
                  trackEvent('GES Stop Record', {
                    sentence: stepIndexActive,
                  });
                  stopRecording();
                } else {
                  trackEvent('GES Start Record', {
                    sentence: stepIndexActive,
                  });
                  startRecording();
                }
              }}
            />
            {isRecording && (
              <ButtonControlContainer useAnimation>
                <Image id="img-waveRight" src={WaveRightIcon} width="24px" alt="Wave Right" />
              </ButtonControlContainer>
            )}
          </PlayerButtonGroup>
          <ButtonControlContainer
            tabIndex={0}
            onClick={() => {
              if (!isPlayRecord && !!audio[stepIndexActive]) {
                trackEvent('GES Play Recorded', {
                  sentence: stepIndexActive,
                });
                playAudioRecorded();
              } else if (isPlayRecord && !!audio[stepIndexActive]) {
                stopAudioRecord();
              } else {
                setIsPlayRecord(false);
              }
            }}
          >
            <Image
              id="img-playRecord"
              src={isPlayRecord ? GraphActiveIcon : GraphPassiveIcon}
              width="32px"
              alt="Play Record Icon"
            />
          </ButtonControlContainer>
        </AudioControlContent>
      </AudioControlContainer>
    </>
  );
};

export default Step6;
