import { FC } from 'react';
import { RadioProps } from './types';
import { RadioCircle, RadioCircleChild, RadioContainer, RadioInput, RadioLabel } from './styled';

import { String } from 'src/utils';
import Text from '../Text';

const Radio: FC<RadioProps> = ({
  disabled,
  direction,
  choices,
  value,
  onChange,
  onClick,
  customColorText,
}) => {
  return (
    <RadioContainer direction={direction}>
      {choices?.map((choice) => {
        const checked = choice?.text === value;

        return (
          <RadioLabel key={choice?.text}>
            <RadioInput
              id={`rdb-${String.convertToCamelCase(choice?.text)}`}
              type="radio"
              onClick={(e) => {
                const target = e?.target as HTMLInputElement;
                if (typeof onClick === 'function') {
                  onClick(target.value, choice);
                }
              }}
              onChange={onChange}
              value={choice?.text}
              checked={checked}
              disabled={disabled}
            />
            <RadioCircle>
              <RadioCircleChild />
            </RadioCircle>
            {choice?.icon ? (
              <span id={`rdb-${String.convertToCamelCase(choice?.text)}-icon`}>{choice?.icon}</span>
            ) : null}
            <Text
              id={`rdb-${String.convertToCamelCase(choice?.text)}-text`}
              value={choice?.text}
              color={checked ? customColorText : undefined}
            />
          </RadioLabel>
        );
      })}
    </RadioContainer>
  );
};

Radio.defaultProps = {
  direction: 'vertical',
  disabled: false,
  value: '',
  onChange: () => null,
  customColorText: '',
};

export default Radio;
