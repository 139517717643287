import { FC, useEffect, useState } from 'react';
import { DataProfileInput, SectionTypes } from './type';
import {
  ButtonSubmitContainer,
  CreateProfileContainer,
  CreateProfileContent,
  CreateProfileLanguageContainer,
  CreateProfileNameContainer,
  LanguageOptionContainer,
} from './styled';
import { Button, Image, Input, Loading, Text } from 'src/components';
import LanguageIcon from 'src/assets/images/icon-globe.svg';
import AccountIcon from 'src/assets/images/icon-account.svg';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getLanguage, updateProfile } from 'src/api/profile';
import { UpdateProfileInput } from 'src/api/types';
import { useNavigate } from 'react-router-dom';
import { paths } from 'src/constants';
import { useMixpanel } from 'src/utils/hooks';
import { Storage } from 'src/utils';

const CreateProfile: FC = () => {
  const navigate = useNavigate();
  const [section, setSection] = useState<SectionTypes>('language');
  const [data, setData] = useState<DataProfileInput>({
    native_language: '',
    name: '',
  });

  const { trackPageview, trackEvent } = useMixpanel();

  const { mutate: updateDataUser, isLoading: loadingUpdate } = useMutation(
    (userData: UpdateProfileInput) => updateProfile(userData),
    {
      onSuccess: () => {
        Storage.setIsNewUser(1);
        setTimeout(() => {
          navigate(paths.HOME);
        }, 300);
      },
    },
  );

  const { isLoading, data: lang } = useQuery(['language'], getLanguage, {
    select: (response) => response?.lang,
  });

  const imageSrc = {
    language: LanguageIcon,
    name: AccountIcon,
  };

  const headingText = {
    language: 'What’s your native language?',
    name: "Let's get in touch! What's your name?",
  };

  useEffect(() => {
    trackPageview();
  }, []);

  return (
    <CreateProfileContainer>
      <Loading show={isLoading || loadingUpdate} />
      <CreateProfileContent>
        <Image id="icon-createProfile" src={imageSrc[section]} alt="Create Profile" width="80px" />
        <Text
          id="txt-headingCreateProfile"
          size="28px"
          weight="700"
          value={headingText[section]}
          align="center"
        />
        {section === 'language' ? (
          <CreateProfileLanguageContainer>
            {lang &&
              lang?.length > 0 &&
              lang?.map((val) => {
                return (
                  <LanguageOptionContainer key={`key-${val}`}>
                    <Button
                      id={`btn-${val}`}
                      text={val}
                      height="50px"
                      onClick={() => {
                        setData((prev) => ({ ...prev, native_language: val }));
                        setSection('name');
                      }}
                    />
                  </LanguageOptionContainer>
                );
              })}
          </CreateProfileLanguageContainer>
        ) : (
          <CreateProfileNameContainer>
            <Input
              id="txt-name"
              value={data?.name}
              onChange={(e) => setData((prev) => ({ ...prev, name: e?.target?.value }))}
            />
            <ButtonSubmitContainer>
              <Button
                id="btn-submit"
                text="Start My Practice"
                height="50px"
                disabled={!data?.name}
                onClick={() => {
                  trackEvent('Profile Created', {
                    user: data.name,
                    language: data?.native_language,
                  });
                  updateDataUser({
                    profile: data,
                  });
                }}
              />
            </ButtonSubmitContainer>
          </CreateProfileNameContainer>
        )}
      </CreateProfileContent>
    </CreateProfileContainer>
  );
};

export default CreateProfile;
