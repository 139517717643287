import { api } from 'src/config/api';
import { LoginInput, LoginResponseType, LogoutResponseType } from 'src/interfaces/profile';
import { Storage } from 'src/utils';
import { paths } from 'src/constants';

export const signIn = async (input: LoginInput): Promise<LoginResponseType> => {
  const response = await api.post('auth/login', input);
  return response?.data;
};

export const signOut = async (): Promise<LogoutResponseType> => {
  const token = Storage.getUserData()?.accessToken;
  const response = await api.post('auth/logout', null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

const refreshToken = async (): Promise<LoginResponseType> => {
  const userData = Storage.getUserData();
  const input = {
    access_token: userData?.accessToken,
    refresh_token: userData?.refreshToken,
  };

  const response = await api.post('auth/refresh', input);
  Storage.setUserData({
    accessToken: response?.data?.data?.access_token,
    refreshToken: response?.data?.data?.refresh_token,
  });
  return response?.data;
};

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return refreshToken()
        .then((response) => {
          originalRequest.headers.Authorization = `Bearer ${response?.data?.access_token}`;

          return api(originalRequest);
        })
        .catch(() => {
          Storage.logout();
          window.location.href = paths.LOGIN;
        });
    }

    return Promise.reject(error);
  },
);
