import { useNavigate } from 'react-router-dom';
import { ButtonBack, Text } from 'src/components';
import { colors, paths } from 'src/constants';
import { ButtonNavContainer, TopNav, TopNavContainer } from '../Profile/styled';
import {
  TextWithBullet,
  TransactionHistoryContainer,
  TransactionHistoryList,
  TransactionHistoryListItem,
  TransactionHistoryListItemTextContainer,
} from './styled';
import Lottie from 'react-lottie';
import { useQuery } from '@tanstack/react-query';
import { getTransactionHistory } from 'src/api/wallet';
import { useCallback, useEffect, useMemo, useState } from 'react';
import currencyFormat from 'src/utils/currencyFormat';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GetTransactionHistoryResponse } from 'src/interfaces/wallet';
import loadingAnimation from 'src/assets/lottie/loading-spinner-dots.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
const TransactionHistory = () => {
  const navigate = useNavigate();
  const LIMIT = 15;
  const [page, setPage] = useState<number>(1);
  const [list, setList] = useState<GetTransactionHistoryResponse['data']['history']>([]);

  const { data: historyList, isLoading } = useQuery(
    ['transaction', LIMIT, page],
    () => getTransactionHistory({ page, limit: LIMIT }),
    {
      cacheTime: 0,
      select: (response) => response?.data,
    },
  );
  const history = historyList?.history || [];

  const hasMore = useMemo(() => {
    return !!historyList?.pagination?.Next;
  }, [historyList?.pagination?.Next]);

  useEffect(() => {
    if (history?.length > 0) {
      setList((prev) => [...prev, ...history]);
    }
  }, [history]);

  const handleNextPage = useCallback(() => {
    setPage((prev) => prev + 1);
  }, [setPage]);

  return (
    <>
      <TopNavContainer>
        <TopNav>
          <ButtonNavContainer>
            <ButtonBack onBackButton={() => navigate(paths.PURCHASE_COINS)} width="auto" />
          </ButtonNavContainer>
          <Text id="txt-heading" size="17px" value="Transaction History" align="center" />
        </TopNav>
      </TopNavContainer>
      <TransactionHistoryContainer>
        <InfiniteScroll
          dataLength={historyList?.history?.length || 0}
          next={handleNextPage}
          hasMore={hasMore}
          loader={false}
        >
          <TransactionHistoryList>
            {list?.map((item) => {
              const date = new Date(item?.created_at * 1000);

              return (
                <TransactionHistoryListItem key={item?.id}>
                  <Text
                    id={`txt-transactionDate-${item?.id}`}
                    size="15px"
                    value={date.toLocaleDateString('id-ID')}
                  />
                  <TransactionHistoryListItemTextContainer>
                    <TextWithBullet
                      id={`txt-transactionDate-${item?.id}`}
                      size="15px"
                      value={item?.transaction_name}
                      color="#747474"
                    />
                    <Text
                      id="txt-transactionDate"
                      size="17px"
                      value={currencyFormat(item?.amount || 0, {
                        delimiter: ',',
                        currencySymbol: '',
                      })}
                      color={item?.amount < 0 ? colors.red : colors.green}
                    />
                  </TransactionHistoryListItemTextContainer>
                </TransactionHistoryListItem>
              );
            })}
          </TransactionHistoryList>
        </InfiniteScroll>
        {isLoading && <Lottie options={defaultOptions} width={160} height={40} />}
      </TransactionHistoryContainer>
    </>
  );
};

export default TransactionHistory;
