import { useContext, useEffect, useMemo } from 'react';
import { Button, Image, Loading, Radio, Text } from 'src/components';
import { GeneralEnglishSprintStore } from 'src/contexts/generalEnglishSprint';
import {
  GeneratedTextTitleContainer,
  PracticeStepContainer,
  QuizAnswerExplanation,
  QuizContainer,
  RefreshContainer,
} from '../styled';
import { LETTER_OPTIONS } from '../constants';
import { colors } from 'src/constants';
import { String, Storage } from 'src/utils';
import { useMutation } from '@tanstack/react-query';
import { useMixpanel } from 'src/utils/hooks';
import { createCourseID, getTextQuestions, submitAnswer } from 'src/api/readingExamTrainer';
import { ISubmitAnswerPayload } from 'src/interfaces/readingExamTrainer';
import RefreshIcon from 'src/assets/images/icon-refresh.svg';

const Step4 = () => {
  const { state, dispatch } = useContext(GeneralEnglishSprintStore);
  const { readingExamTrainer, step } = state;

  const { trackEvent } = useMixpanel();

  // ----- create course -----
  const { mutate: onCreateCourseID, isLoading: isLoadingCreateCourseID } = useMutation(
    createCourseID,
    {
      onSuccess: (response) => {
        const courseID = response?.data?.course_id;
        if (courseID) {
          Storage.setUserData({ courseID });
        }
      },
    },
  );

  const userData = Storage.getUserData();

  useEffect(() => {
    onCreateCourseID();
  }, []);

  useEffect(() => {
    if (userData.courseID !== '') {
      onGetTextQuestions();
    }
  }, [userData.courseID]);

  const { mutate: onGetTextQuestions, isLoading: isLoadingGetAudioQuestions } = useMutation(
    () => getTextQuestions(),
    {
      onSuccess: (response) => {
        const quizzes = response.data.Questions.map((item) => {
          return {
            ...item,
            answer: '',
          };
        });
        dispatch({
          type: 'SET_DATA',
          payload: {
            readingExamTrainer: {
              ...readingExamTrainer,
              text: response.data.text,
              quizzes,
            },
            step: 1,
          },
        });
      },
    },
  );

  const { mutate: onSubmitAnswer, isLoading: isLoadingSubmitAnswer } = useMutation(
    (input: ISubmitAnswerPayload) => submitAnswer(input),
    {
      onSuccess: (response) => {
        if (readingExamTrainer?.quizzes) {
          const updatedQuizzes = readingExamTrainer?.quizzes.map((quiz) => {
            const currentResult = response.data.result.find(
              (result) => result.number === quiz.number,
            );
            return {
              ...quiz,
              correct_answer: currentResult?.correct_answer,
              is_correct: currentResult?.is_correct,
              explanation: currentResult?.explanation,
            };
          });
          dispatch({
            type: 'SET_DATA',
            payload: {
              readingExamTrainer: {
                ...readingExamTrainer,
                quizzes: updatedQuizzes,
              },
              step: 2,
            },
          });
        }
      },
    },
  );

  const onRefresh = () => {
    onCreateCourseID();
  };

  const onSubmit = () => {
    const answers =
      readingExamTrainer?.quizzes?.map((item) => {
        return {
          number: item.number,
          answer: item.answer as string,
        };
      }) || [];
    onSubmitAnswer({
      answers,
    });
  };

  const isAllAnswered = useMemo(() => {
    return readingExamTrainer?.quizzes?.every((val) => !!val?.answer);
  }, [readingExamTrainer?.quizzes]);

  return (
    <PracticeStepContainer>
      <Loading
        show={isLoadingCreateCourseID || isLoadingGetAudioQuestions || isLoadingSubmitAnswer}
      />

      <GeneratedTextTitleContainer>
        <Text id="txt-titleGeneratedText" value="The Reading Text" weight="500" size="17px" />
        {step === 1 && (
          <RefreshContainer role="button" tabIndex={0} onClick={onRefresh} aria-hidden>
            <Image id="image-refreshText" alt="Refresh" src={RefreshIcon} width="24px" />
          </RefreshContainer>
        )}
      </GeneratedTextTitleContainer>
      <Text id="txt-correctedText" value={readingExamTrainer?.text} font="Gelasio" size="17px" />

      <Text
        id="title-questions"
        value="Mockup Exam Questions:"
        weight="500"
        size="17px"
        margin="2rem 0 0.5rem"
      />
      {readingExamTrainer?.quizzes?.map((val, index) => {
        const formattedOptions = val?.choices?.map((val, idx) => {
          return {
            text: `${LETTER_OPTIONS[idx]}. ${val}`,
          };
        });

        const correctedAnswer = formattedOptions?.filter((v) =>
          v?.text?.includes(val?.correct_answer as string),
        )?.[0]?.text;

        const colorTextValidate =
          val?.correct_answer && val?.answer?.includes(val?.correct_answer)
            ? colors.green
            : colors.red;
        const color = step === 2 && val?.answer ? colorTextValidate : undefined;

        return (
          <QuizContainer key={`quiz-${index + 1}`}>
            <Text
              id={`txt-question-${index + 1}`}
              value={`${index + 1}. ${val?.question}`}
              weight="500"
              size="17px"
            />
            <Radio
              disabled={step === 2}
              value={val?.answer}
              direction="vertical"
              customColorText={color}
              onChange={(e) => {
                if (step === 1) {
                  const idx = index;
                  const newQuizzes = [...(readingExamTrainer?.quizzes || [])];

                  newQuizzes[idx] = {
                    ...newQuizzes[idx],
                    answer: e?.target?.value,
                  };

                  dispatch({
                    type: 'SET_DATA',
                    payload: {
                      readingExamTrainer: {
                        ...readingExamTrainer,
                        quizzes: newQuizzes,
                      },
                      step: 1,
                    },
                  });
                }
              }}
              choices={formattedOptions}
            />
            {step === 2 && val?.correct_answer && (
              <QuizAnswerExplanation>
                <Text
                  id={`title-answer-${index + 1}`}
                  value="The correct answer is"
                  size="12px"
                  color={colors.grey.G300}
                />
                <Text
                  id={`txt-answer-${index + 1}`}
                  value={String.replaceCharacter(correctedAnswer, 1, ')')}
                  weight="500"
                  size="17px"
                  margin="0.25rem 0"
                />
                <Text id={`txt-explanation-${index + 1}`} value={val?.explanation} size="17px" />
              </QuizAnswerExplanation>
            )}
          </QuizContainer>
        );
      })}
      <Button
        id="btn-action"
        text={step === 2 ? 'Finish Training Session' : 'Check Your Answer'}
        height="50px"
        disabled={!isAllAnswered}
        onClick={() => {
          if (step === 1) {
            onSubmit();
          } else {
            const totalQuestion = readingExamTrainer?.quizzes?.length;
            let count = 0;
            readingExamTrainer?.quizzes?.forEach((val) => {
              if (val?.answer?.includes(val?.correct_answer as string)) {
                count += 1;
              }
            });
            const correctAnswer = `${count} of ${totalQuestion}`;

            trackEvent('RET Finish Practice', {
              correctAnswer,
            });
            dispatch({
              type: 'SET_FINISH',
            });
          }
        }}
      />
    </PracticeStepContainer>
  );
};

export default Step4;
