import mixpanel, { Dict } from 'mixpanel-browser';

mixpanel.init('5dbd62327b460d2465d081902c5f7ad3', {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
});

const useMixpanel = () => {
  const actions = {
    identify: (id: string) => {
      mixpanel.identify(id);
    },
    trackEvent: (name: string, props?: Dict) => {
      mixpanel.track(name, props);
    },
    trackPageview: () => {
      mixpanel.track_pageview();
    },
  };
  return actions;
};

export default useMixpanel;
