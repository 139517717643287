import { colors } from 'src/constants';
import styled from 'styled-components';
import { ProgressProps } from './types';

export const ProgressBarContainer = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background: ${colors.secondary};
  border-radius: 10px;
`;

export const Progress = styled.div<ProgressProps>`
  width: ${({ width }) => width};
  height: 10px;
  background: ${colors.primary};
  border-radius: 10px;
`;
