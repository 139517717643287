import { rgba } from 'polished';
import { colors } from 'src/constants';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slideUp = keyframes`
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
`;

export const OptionsWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  background: ${rgba(0, 0, 0, 0.4)};
  animation: ${fadeIn} 0.2s;
  z-index: 20;
`;

export const OptionsContainer = styled.div`
  max-width: 480px !important;
  width: 100%;
  margin: 0 auto;
`;

export const OptionContent = styled.div`
  position: relative;
  height: auto;
  max-height: calc(100vh / 2);
  background: ${colors.white};
  padding: 1rem;
  overflow: auto;
  animation: ${slideUp} 0.4s;
`;

export const OptionList = styled.div<{ spacedTop: boolean }>`
  width: 100%;
  margin-top: ${({ spacedTop }) => (spacedTop ? '1.25rem' : 'unset')};
`;

export const OptionItem = styled.div`
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
  background: ${colors.white};
  padding: 5px 5px 5px 0;
  transition: 0.2s;
  :hover {
    background: ${colors.grey.G600};
  }
`;
