import { Modal, Text } from 'src/components';
import { LinkingText, Table, TableData, TableHead, TableItems } from '../../Practice/styled';
import { colors } from 'src/constants';
import { FC, useMemo } from 'react';
import { String } from 'src/utils';
import { colorHandler } from '../../Practice/functions';

import { IModalDetailProps } from 'src/interfaces/speakingExamTrainer';

const ModalDetail: FC<IModalDetailProps> = ({ data, onLeave }) => {
  const tone = useMemo(() => {
    if (Object.values(data)?.length > 0) {
      const toneArray = data?.tone?.split(' ');
      return {
        first: toneArray?.[0]?.toLowerCase(),
        nextText: toneArray?.splice(1).join(' '),
      };
    }

    return {
      first: '',
      nextText: '',
    };
  }, [data]);

  const linkingData = useMemo(() => {
    return Array.from(new Set(data?.linking?.filter(Boolean)));
  }, [data]);

  if (Object.values(data)?.length > 0) {
    return (
      <Modal id="modal-detailRecored" show={Object.values(data)?.length > 0} onLeave={onLeave}>
        <Text id="title-overall" align="center" value="Overall" weight="500" size="17px" />
        <Text
          id="txt-overall"
          align="center"
          value={`${data?.overall || 0}%`}
          weight="700"
          size="34px"
          color={colorHandler(data?.overall || 0)}
          margin="3px 0 1.5rem"
        />
        <Table>
          <TableItems grid={{ sm: 2, md: 3 }}>
            <TableHead>Pronunciation</TableHead>
            <TableData>{`${data?.pronunciation}%`}</TableData>
          </TableItems>
          <TableItems grid={{ sm: 2, md: 3 }}>
            <TableHead>Fluency</TableHead>
            <TableData>{`${data?.fluency}%`}</TableData>
          </TableItems>
          <TableItems grid={{ sm: 1, md: 3 }}>
            <TableHead>Integrity</TableHead>
            <TableData>{`${data?.integrity}%`}</TableData>
          </TableItems>
          <TableItems grid={{ sm: 2 }}>
            <TableHead>Rhythm</TableHead>
            <TableData>{`${data?.rhythm}%`}</TableData>
          </TableItems>
          <TableItems grid={{ sm: 2 }}>
            <TableHead>Speed(wpm)</TableHead>
            <TableData>{data?.speed}</TableData>
          </TableItems>
        </Table>
        <Text
          id="title-tone"
          value="Tone"
          weight="600"
          size="15px"
          color={colors.primary}
          margin="1rem 0 4px"
        />
        <div>
          <Text id="txt-tone1" value="You used " size="15px" display="inline" />
          <Text id="txt-tone2" value={tone?.first} size="15px" weight="600" display="inline" />
          <Text id="txt-tone3" value={` ${tone?.nextText}`} size="15px" display="inline" />
        </div>

        <div>
          {linkingData.length > 0 && (
            <>
              <Text
                id="title-linking"
                value="Linking"
                weight="600"
                size="15px"
                color={colors.primary}
                margin="0.5rem 0 4px"
              />
              {linkingData?.map((val, index) => {
                return (
                  <div key={val}>
                    <Text
                      id={`txt-linking-1-${index}`}
                      value="You linked "
                      size="15px"
                      display="inline"
                    />
                    <LinkingText
                      id={`txt-linking-2-${index}`}
                      dangerouslySetInnerHTML={{
                        __html: String.specialSentenceWithDoubleQuotes(val),
                      }}
                    />
                  </div>
                );
              })}
            </>
          )}
        </div>
      </Modal>
    );
  }

  return null;
};

export default ModalDetail;
