import { rgba } from 'polished';
import styled from 'styled-components';

export const ModalConfirmationHeaderContainer = styled.div`
  padding: 1rem;
`;

export const ModalConfirmationFooterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  border-top: 1px solid ${rgba('#3c3c435c', 0.3)};
`;

export const ModalButtonContainer = styled.div`
  padding: 0.5rem 0.75rem 0.75rem;
`;

export const Divider = styled.div`
  width: 1px;
  min-height: 100%;
  background: ${rgba('#3c3c435c', 0.3)};
`;
