import styled from 'styled-components';
import { ProfileLanguageContainer } from '../Profile/styled';
import { colors } from 'src/constants';
import { Text } from 'src/components';

export const TransactionHistoryContainer = styled.div`
  padding: 3.5rem 0 1rem;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const TransactionHistoryList = styled(ProfileLanguageContainer)`
  gap: 0;
  padding: 0;
`;

export const TransactionHistoryListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 19px 16px;
  border-bottom: 1px solid ${colors.grey.G400};
  box-sizing: border-box;
`;

export const TransactionHistoryListItemTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TextWithBullet = styled(Text)`
  margin-right: 0.5rem;
  &::after {
    content: '';
    display: inline-block;
    background: ${colors.textPrimary};
    width: 5px;
    height: 5px;
    margin-bottom: 2px;
    margin-left: 0.5rem;
    border-radius: 50%;
  }
`;
