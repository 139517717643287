import { colors } from 'src/constants';

export const isAlmostEqual = (progress: number, end: number): boolean => {
  const endTime = end - 0.00001;
  return progress >= endTime;
};

export const checkAudioAlmostDone = (progress: number, end: number): boolean => {
  return progress + 0.25 >= end;
};

export const colorHandler = (overall: number) => {
  if (overall >= 0 && overall <= 50) {
    return colors['red'];
  }

  if (overall > 50 && overall <= 80) {
    return colors['orange'];
  }
  return colors['green'];
};
