import { MdMic, MdStop } from 'react-icons/md';
import { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import Lottie from 'react-lottie';
import { getWaveBlob } from 'webm-to-wav-converter';

import { GeneralEnglishSprintStore } from 'src/contexts/generalEnglishSprint';
import { speechAssessment, speechToText } from 'src/api/speakingExamTrainer';
import { ISpeechAssessmentPayload, ISpeechToTextPayload } from 'src/interfaces/speakingExamTrainer';
import loadingAnimation from 'src/assets/lottie/loading-spinner-dots.json';
import soundWaveAnimation from 'src/assets/lottie/sound-wave.json';

import { AudioControlContainer, AudioControlContent } from '../../Practice/styled';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const AudioControl = () => {
  const { state, dispatch } = useContext(GeneralEnglishSprintStore);
  const { speakingExamTrainer } = state;
  const audioRef = useRef<HTMLAudioElement>();
  const mediaRecorder = useRef<MediaRecorder>();

  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [stream, setStream] = useState<MediaStream>();
  const [audioChunks, setAudioChunks] = useState<Array<BlobEvent['data']>>([]);
  const [recordedWavBlob, setRecordedWavBlob] = useState<Blob>();

  useEffect(() => {
    audioRef.current = new Audio(state?.speechFromText?.audioUrl);
    audioRef.current.load();
    const timer = setTimeout(() => {
      // setStepIndexActive(0);
    }, 1000);

    return () => {
      audioRef?.current?.pause();
      clearTimeout(timer);
    };
  }, []);

  const { mutate: getSpeech } = useMutation((input: ISpeechToTextPayload) => speechToText(input), {
    onSuccess: (response, input) => {
      if (recordedWavBlob) {
        onSpeechAssessment({
          index: 0,
          audio: recordedWavBlob,
        });
      }

      dispatch({
        type: 'SET_DATA',
        payload: {
          speakingExamTrainer: {
            ...speakingExamTrainer,
            answer: response.data.text,
            status: 'LOADING_SPEECH_ASSESSMENT',
            voice_url: input.audio ? URL.createObjectURL(input.audio) : '',
          },
          step: 2,
        },
      });
    },
  });

  const { mutate: onSpeechAssessment } = useMutation(
    (input: ISpeechAssessmentPayload) => speechAssessment(input),
    {
      onSuccess: () => {
        dispatch({
          type: 'SET_DATA',
          payload: {
            speakingExamTrainer: {
              ...speakingExamTrainer,
              status: 'IDLE',
            },
            step: 2,
          },
        });
      },
    },
  );

  const createStream = () => {
    return navigator.mediaDevices.getUserMedia({
      audio: {
        sampleRate: 16000,
        sampleSize: 16,
      },
    });
  };

  const startRecording = async () => {
    //create new Media recorder instance using the stream
    const newStream = await createStream();
    setStream(newStream);

    const media: MediaRecorder = new MediaRecorder(newStream, { audioBitsPerSecond: 128000 });

    setIsRecording(true);
    //set the MediaRecorder instance to the mediaRecorder ref
    mediaRecorder.current = media;
    //invokes the start method to start the recording process
    mediaRecorder.current.start();
    const localAudioChunks: Array<BlobEvent['data']> = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === 'undefined') return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };
    setAudioChunks(localAudioChunks);
  };

  const stopRecording = () => {
    // // ----- disable record button -----
    dispatch({
      type: 'SET_DATA',
      payload: {
        speakingExamTrainer: {
          ...speakingExamTrainer,
          status: 'LOADING_SPEECH_TO_TEXT',
        },
        step: 2,
      },
    });

    setIsRecording(false);
    //stops the recording instance
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      mediaRecorder.current.onstop = async () => {
        //creates a blob file from the audiochunks data

        const webmBlob = new Blob(audioChunks, { type: 'audio/webm' });
        const wavBlob = await getWaveBlob(webmBlob, false, { sampleRate: 16000 });
        getSpeech({
          audio: wavBlob,
        });

        setRecordedWavBlob(wavBlob);

        setAudioChunks([]);
      };

      /** Stop all the tracks on the active stream in order to stop the stream and remove
       * the red flashing dot showing in the tab
       */
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }
    }
  };

  return (
    <>
      <AudioControlContainer id="audioControlContainer">
        <AudioControlContent>
          <div />
          <div className="flex items-center gap-2">
            {isRecording && (
              <Lottie
                options={{ ...defaultOptions, animationData: soundWaveAnimation }}
                width={50}
                height={50}
              />
            )}
            <button
              className="rounded-full bg-primary p-2 text-white disabled:bg-opacity-20"
              onClick={() => {
                if (isRecording) {
                  stopRecording();
                } else {
                  startRecording();
                }
              }}
            >
              {isRecording ? <MdStop size={50} /> : <MdMic size={50} />}
            </button>
            {isRecording && (
              <Lottie
                options={{ ...defaultOptions, animationData: soundWaveAnimation }}
                width={50}
                height={50}
              />
            )}
          </div>
          <div />
        </AudioControlContent>
      </AudioControlContainer>
    </>
  );
};

export default AudioControl;
