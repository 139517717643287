import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Loading, Radio, Text } from 'src/components';
import { GeneralEnglishSprintStore } from 'src/contexts/generalEnglishSprint';
import { PracticeStepContainer, QuizAnswerExplanation, QuizContainer } from '../styled';
import { LETTER_OPTIONS } from '../contants';
import { colors } from 'src/constants';
import { String } from 'src/utils';
import { useMutation } from '@tanstack/react-query';
import { ParaphraseTextInputType, SpeechFromTextInputType } from 'src/api/types';
import { paraphraseText, speechFromText } from 'src/api/generalEnglishSprint';
import { useMixpanel } from 'src/utils/hooks';

const Step4 = () => {
  const { state, dispatch } = useContext(GeneralEnglishSprintStore);
  const [isAnswered, setIsAnswered] = useState<boolean>(false);
  const [isLoadingAnswer, setLoadingAnswer] = useState<boolean>(false);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [totalCorrectAnswer, setTotalCorrectAnswer] = useState<number>(0);

  const { trackEvent } = useMixpanel();

  const { mutate: getSpeech, isLoading: loadingGetSpeech } = useMutation(
    (input: SpeechFromTextInputType) => speechFromText(input),
    {
      onSuccess: (response) => {
        dispatch({
          type: 'SET_DATA',
          payload: {
            speechFromText: {
              audioUrl: response?.audio_url,
              voice: 'male-0',
              Timestamps: response?.Timestamps,
            },
          },
        });
      },
    },
  );

  const { mutate, isLoading } = useMutation(
    (input: ParaphraseTextInputType) => paraphraseText(input),
    {
      onSuccess: (response) => {
        dispatch({
          type: 'SET_DATA',
          payload: {
            paraphrasedText: response?.data?.paraphrased_text,
            step: state?.step,
          },
        });

        getSpeech({
          text: response?.data?.paraphrased_text,
          voice_id: 'male-0',
        });
      },
    },
  );

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (isLoadingAnswer) {
      timer = setTimeout(() => {
        setIsAnswered(true);
        setLoadingAnswer(false);
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, [isLoadingAnswer]);

  const isAllAnswered = useMemo(() => {
    return state?.quizzes?.every((val) => !!val?.userAnswer);
  }, [state?.quizzes]);

  useEffect(() => {
    const isAnswered = state?.quizzes?.every((val) => !!val?.userAnswer);
    if (isAnswered && firstRender) {
      setIsAnswered(true);
    }

    return () => setFirstRender(false);
  }, [firstRender]);

  useEffect(() => {
    let count = 0;
    state?.quizzes?.forEach((val) => {
      if (val?.userAnswer?.includes(val?.answer)) {
        count += 1;
      }

      setTotalCorrectAnswer(count);
    });
  }, [state?.quizzes]);

  return (
    <PracticeStepContainer>
      <Loading show={isLoading || isLoadingAnswer || loadingGetSpeech} />
      <Text
        id="title-correctedText"
        value="Corrected Text"
        weight="500"
        size="17px"
        margin="0 0 0.5rem"
      />
      <Text
        id="txt-correctedText"
        value={state?.translation?.correctedText}
        font="Gelasio"
        size="17px"
      />

      <Text
        id="title-questions"
        value="Questions:"
        weight="500"
        size="17px"
        margin="2rem 0 0.5rem"
      />
      {state?.quizzes?.map((val, index) => {
        const formattedOptions = val?.options?.map((val, idx) => {
          return {
            text: `${LETTER_OPTIONS[idx]}. ${val}`,
          };
        });

        const correctedAnswer = formattedOptions?.filter((v) =>
          v?.text?.includes(val?.answer as string),
        )?.[0]?.text;

        const colorTextValidate = val?.userAnswer?.includes(val?.answer)
          ? colors.green
          : colors.red;
        const color = isAnswered && val?.userAnswer ? colorTextValidate : undefined;

        return (
          <QuizContainer key={`quiz-${index + 1}`}>
            <Text
              id={`txt-question-${index + 1}`}
              value={`${index + 1}. ${val?.question}`}
              weight="500"
              size="17px"
            />
            <Radio
              value={val?.userAnswer}
              direction="vertical"
              customColorText={color}
              onChange={(e) => {
                if (!isAnswered) {
                  dispatch({
                    type: 'SET_ANSWER_QUIZ',
                    payload: {
                      index,
                      answer: e?.target?.value,
                    },
                  });
                }
              }}
              choices={formattedOptions}
            />
            {isAnswered && val?.userAnswer && (
              <QuizAnswerExplanation>
                <Text
                  id={`title-answer-${index + 1}`}
                  value="The correct answer is"
                  size="12px"
                  color={colors.grey.G300}
                />
                <Text
                  id={`txt-answer-${index + 1}`}
                  value={String.replaceCharacter(correctedAnswer, 1, ')')}
                  weight="500"
                  size="17px"
                  margin="0.25rem 0"
                />
                <Text id={`txt-explanation-${index + 1}`} value={val?.explanation} size="17px" />
              </QuizAnswerExplanation>
            )}
          </QuizContainer>
        );
      })}
      <Button
        id="btn-action"
        text={isAnswered ? 'Continue' : 'Check Your Answer'}
        height="50px"
        disabled={!isAllAnswered}
        onClick={() => {
          if (!isAnswered) {
            setLoadingAnswer(true);
            trackEvent('GES Check Answer');
          } else {
            trackEvent('GES Done Reading Quiz', {
              correctAnswer: `${totalCorrectAnswer} correct answer`,
            });

            mutate({
              text: state?.translation?.correctedText as string,
            });
          }
        }}
      />
    </PracticeStepContainer>
  );
};

export default Step4;
