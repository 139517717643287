import { api } from 'src/config/api';
import { Storage } from 'src/utils';
import {
  IAudioQuestionsResponse,
  ISubmitAnswerPayload,
  ISubmitAnswerResponse,
} from 'src/interfaces/listeningExamTrainer';
import { GenerateCourseResponseType } from 'src/interfaces/generalEnglishSprint';

export const createCourseID = async (): Promise<GenerateCourseResponseType> => {
  const token = Storage.getUserData()?.accessToken;
  const response = await api.post('course/exam-trainer-listening', null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const getAudioQuestions = async (): Promise<IAudioQuestionsResponse> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const input = {
    course_id: courseID,
  };

  const response = await api.get('exam-trainer/listening/audio-question', {
    params: input,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const submitAnswer = async (data: ISubmitAnswerPayload): Promise<ISubmitAnswerResponse> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const input = {
    ...data,
    course_id: courseID,
  };

  const response = await api.post('exam-trainer/listening/submit-answer', input, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
