import Exercises1 from 'src/assets/images/exercises1-compressed.png';
import Exercises2 from 'src/assets/images/exercises2-compressed.png';
import Exercises3 from 'src/assets/images/exercises3-compressed.png';
import Exercises4 from 'src/assets/images/exercises4-compressed.png';
import Exercises5 from 'src/assets/images/exercises5-compressed.png';
import Exercises6 from 'src/assets/images/exercises6-compressed.png';
import { ExercisesType } from './types';
import { paths } from 'src/constants';

export const COURSES_IDS = {
  GENERAL_ENGLISH_SPRINT: 'general-english-sprint',
  ASSISTANCE_SPEAKING_PARTNER: 'assistance-speaking-partner',
  VOCABULARY_BUILDER: 'vocabulary-builder',
  EXAM_TRAINER_WRITING: 'exam-trainer-writing',
  EXAM_TRAINER_SPEAKING: 'exam-trainer-speaking',
  EXAM_TRAINER_LISTENING: 'exam-trainer-listening',
  EXAM_TRAINER_READING: 'exam-trainer-reading',
};

export const dataExercises: Array<ExercisesType> = [
  {
    id: COURSES_IDS.ASSISTANCE_SPEAKING_PARTNER,
    title: 'AI-Speaking Partner',
    description: 'Converse on any topic and get instant feedback',
    image: Exercises1,
    locked: false,
    item: [
      'There will be two speaking practice modes',
      'Easy: the AI partner with reply with text and audio and provide feedback for every reply',
      'Difficult: the AI partner will only reply with audio, it will start with a 2 ms monologue, and provide feedback by the end of the session',
    ],
    action: paths.AI_SPEAKING_PARTNER,
    actionName: 'ASP Start Practice',
  },
  {
    id: COURSES_IDS.VOCABULARY_BUILDER,
    title: 'Vocabulary Builder',
    description: 'Memorize any word and use it in context',
    image: Exercises2,
    locked: true,
    item: [
      'Add any new word to your library',
      'The app will provide definition and context',
      'You’ll be trained to pronounce the word',
      'You’ll practice using the word in sentences',
      'The AI method will help you with word memorization using Spaced Repetition',
    ],
  },
  {
    id: COURSES_IDS.EXAM_TRAINER_SPEAKING,
    title: 'Speaking Exam Trainer',
    description: 'Either train your speaking or sharpen your answers',
    image: Exercises3,
    locked: false,
    item: [
      'There will be two speaking practice modes',
      'Easy: you’ll be provided with question and answer, you’ll only train your pronunciation',
      'Difficult: you must provide your own answer, you’ll get feedback on your grammar errors, substances, as well as pronunciation',
    ],
    action: paths.SPEAKING_EXAM_TRAINER,
    actionName: 'SET Start Practice',
  },
  {
    id: COURSES_IDS.EXAM_TRAINER_WRITING,
    title: 'Writing Exam Trainer',
    description: 'Write your answer and get real-time assessment',
    image: Exercises4,
    locked: false,
    item: [
      'Youll be presented with a writing exam mockup question',
      'You have to type in your answer properly as  if you’re taking a writing exam',
      'The AI method will give you instant feedback on grammar errors and substances',
    ],
    action: paths.WRITING_EXAM_TRAINER,
    actionName: 'WET Start Practice',
  },
  {
    id: COURSES_IDS.EXAM_TRAINER_LISTENING,
    title: 'Listening Exam Trainer',
    description: 'Train your ears to amplify your listening prowess',
    image: Exercises5,
    locked: false,
    item: [
      'You will be provided with an audio transcript',
      'You must listen carefully while answering a set of multiple choice questions related to the transcript',
      'You will receive instant feedback on whether your answers are correct',
    ],
    action: paths.LISTENING_EXAM_TRAINER,
    actionName: 'LET Start Practice',
  },
  {
    id: COURSES_IDS.EXAM_TRAINER_READING,
    title: 'Reading Exam Trainer',
    description: 'Improve your reading skills and comprehension',
    image: Exercises6,
    locked: false,
    item: [
      'You will be provided with a reading text',
      'You must read and understand the text in order to be able to answer the questions that follow',
      'You will receive instant feedback on whether your answers are correct.',
    ],
    action: paths.READING_EXAM_TRAINER,
    actionName: 'RET Start Practice',
  },
];
