import { colors } from 'src/constants';
import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 50;
`;

export const BottomNav = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  max-width: 480px !important;
  background: ${colors.grey.G800};
  padding: 1rem;
  border-top: 1px solid ${colors.grey.G200};
`;

export const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
