import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import { paths } from './constants';
import Login from './pages/Login';
import Home from './pages/Home';
import CreateProfile from './pages/CreateProfile';
import Profile from './pages/Profile';
import Practice from './pages/Practice';
import { Menu, PrivateRoute } from './components';
import { GeneralEnglishSprintProvider } from './contexts/generalEnglishSprint';
import ProfileNativeLanguage from './pages/Profile/ProfileNativeLanguage';
import AISpeakingPartner from './pages/AISpeakingPartner';
import WritingExamTrainer from './pages/WritingExamTrainer';
import SpeakingExamTrainer from './pages/SpeakingExamTrainer';
import ListeningExamTrainer from './pages/ListeningExamTrainer';
import ReadingExamTrainer from './pages/ReadingExamTrainer';
import PurchaseCoins from './pages/PurchaseCoins';
import TransactionHistory from './pages/TransactionHistory';
import { CoursesProvider } from './contexts/courses';

function App() {
  return (
    <CoursesProvider>
      <GeneralEnglishSprintProvider>
        <main id="app">
          <BrowserRouter>
            <Routes>
              <Route index Component={() => <Navigate to={paths.LOGIN} />} />
              <Route path={paths.LOGIN} Component={Login} />
              <Route
                path={paths.HOME}
                element={<PrivateRoute component={Home} protectedType="profile" />}
              />
              <Route
                path={paths.CREATE_PROFILE}
                element={<PrivateRoute component={CreateProfile} protectedType="profile" />}
              />
              <Route path={paths.PROFILE} element={<PrivateRoute component={Profile} />} />
              <Route
                path={paths.PROFILE_LANGUANGE}
                element={<PrivateRoute component={ProfileNativeLanguage} />}
              />
              <Route
                path={paths.PRACTICE}
                element={<PrivateRoute component={Practice} protectedType="course" />}
              />
              <Route
                path={paths.AI_SPEAKING_PARTNER}
                element={<PrivateRoute component={AISpeakingPartner} protectedType="course" />}
              />
              <Route
                path={paths.WRITING_EXAM_TRAINER}
                element={<PrivateRoute component={WritingExamTrainer} protectedType="course" />}
              />
              <Route
                path={paths.SPEAKING_EXAM_TRAINER}
                element={<PrivateRoute component={SpeakingExamTrainer} protectedType="course" />}
              />
              <Route
                path={paths.LISTENING_EXAM_TRAINER}
                element={<PrivateRoute component={ListeningExamTrainer} protectedType="course" />}
              />
              <Route
                path={paths.READING_EXAM_TRAINER}
                element={<PrivateRoute component={ReadingExamTrainer} protectedType="course" />}
              />
              <Route
                path={paths.PURCHASE_COINS}
                element={<PrivateRoute component={PurchaseCoins} />}
              />
              <Route
                path={paths.TRANSACTION_HISTORY}
                element={<PrivateRoute component={TransactionHistory} />}
              />
            </Routes>
            <Menu />
          </BrowserRouter>

          <Toaster />
        </main>
      </GeneralEnglishSprintProvider>
    </CoursesProvider>
  );
}

export default App;
