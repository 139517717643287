import { FC, useState, useEffect, ReactPortal } from 'react';
import { createPortal } from 'react-dom';
import { ModalContent, ModalContainer, ModalWrapper } from './styled';
import { ModalProps } from './types';
import Text from '../Text';
import { colors } from 'src/constants';

const Modal: FC<ModalProps> = ({
  id,
  onLeave,
  show,
  children,
  width,
  padded,
  showClickEverywhere,
  background,
}): ReactPortal | null => {
  const [rendered, setRendered] = useState(false);
  useEffect(() => {
    setRendered(true);
  }, []);

  if (rendered && show) {
    return createPortal(
      <ModalWrapper
        id={id}
        onClick={(e) => {
          e.stopPropagation();
          if (typeof onLeave === 'function') {
            onLeave();
          }
        }}
      >
        <ModalContainer>
          <ModalContent
            width={width}
            padded={padded}
            background={background}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {children}
          </ModalContent>
          {showClickEverywhere && (
            <Text
              id="txt-tapAywhere"
              align="center"
              value="Tap anywhere to close"
              color={colors.white}
              size="12px"
              weight="500"
            />
          )}
        </ModalContainer>
      </ModalWrapper>,
      document.body,
    );
  }
  return null;
};

Modal.defaultProps = {
  onLeave: () => null,
  show: false,
  width: '100%',
  padded: true,
  showClickEverywhere: true,
  background: colors.white,
};

export default Modal;
