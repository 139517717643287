import { rgba } from 'polished';
import { colors } from 'src/constants';
import styled from 'styled-components';
import { ProfileLanguageContainer, UserCoinsContainer } from '../Profile/styled';
import { IsActiveChecker } from '../Practice/types';

export const TopNavContainer = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 480px !important;
  height: 56px;
  margin: auto;
  padding: 0.5rem 1rem;
  background: ${colors.white};
  box-shadow: 0px 0.6px 1.8px ${rgba(0, 0, 0, 0.11)}, 0px 3.2px 7.2px ${rgba(0, 0, 0, 0.13)};
  z-index: 50;
`;

export const CoinsChoicesContainer = styled(ProfileLanguageContainer)`
  align-items: flex-start;
  gap: 0;
  padding: 4.5rem 1rem 10rem;
`;

export const TopUpList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const TopUpListItem = styled.button<IsActiveChecker>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${({ isActive }) => (isActive ? '#ebe9fc' : colors.grey.G400)};
  padding: 0.75rem 1rem;
  border: 1px solid ${({ isActive }) => (isActive ? colors.primary : colors.grey.G400)};
  border-radius: 8px;
`;

export const TopUpCoinsContainer = styled(UserCoinsContainer)`
  gap: 8px;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  max-width: 480px !important;
  margin: auto;
  padding: 1rem;
  background: ${colors.white};
  box-shadow: 0px 0.6px 1.8px ${rgba(0, 0, 0, 0.11)}, 0px 3.2px 7.2px ${rgba(0, 0, 0, 0.13)};
  z-index: 50;
`;
