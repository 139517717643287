export type CurrencyFormatOptions = {
  decimalCount?: number;
  delimiter?: ',' | '.';
  currencySymbol?: string;
  spaceAfterSymbol?: boolean;
};

const defaultOptions: CurrencyFormatOptions = {
  decimalCount: 0,
  delimiter: '.',
  currencySymbol: 'Rp',
  spaceAfterSymbol: false,
};

function formatCurrency(
  belowZero: boolean,
  numberSections: string[],
  decimalDelimiter: string,
  currencySymbol?: string,
  spaceAfterSymbol?: boolean,
) {
  if (belowZero && currencySymbol) {
    numberSections[0] = numberSections[0].substring(1);
    return `-${currencySymbol}${spaceAfterSymbol ? ' ' : ''}${numberSections.join(
      decimalDelimiter,
    )}`;
  }

  return `${currencySymbol}${spaceAfterSymbol ? ' ' : ''}${numberSections.join(decimalDelimiter)}`;
}

export default function currencyFormat(
  amount: string | number,
  options: CurrencyFormatOptions = defaultOptions,
) {
  let number = amount || 0;
  const { decimalCount, delimiter, currencySymbol, spaceAfterSymbol } = {
    ...defaultOptions,
    ...options,
  };
  const decimalDelimiter = delimiter === '.' ? ',' : '.';

  if (typeof number === 'string') {
    number = parseFloat(number);
    if (Number.isNaN(number)) {
      return formatCurrency(false, ['0'], decimalDelimiter, currencySymbol, spaceAfterSymbol);
    }
  }

  const belowZero = number < 0;
  number = number.toFixed(decimalCount);
  number = number.toString().replace('.', decimalDelimiter);

  const numberSections = number.toString().split(decimalDelimiter);
  numberSections[0] = numberSections[0]
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${delimiter ?? ','}`);

  return formatCurrency(
    belowZero,
    numberSections,
    decimalDelimiter,
    currencySymbol,
    spaceAfterSymbol,
  );
}

export const sanitizePriceValue = (value: string): string => value?.replace(/[^0-9]+/g, '');
