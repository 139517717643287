import { FC } from 'react';
import { Modal, Text } from 'src/components';
import { ModalCoinConfirmationProps } from '../types';
import { ButtonModal, ModalCoinConfirmationContainer } from '../styled';
import { colors, paths } from 'src/constants';
import CoinsActiveIcon from 'src/assets/images/icon-avidx-coins-active.svg';
import currencyFormat from 'src/utils/currencyFormat';
import { useNavigate } from 'react-router-dom';

const ModalCoinConfirmation: FC<ModalCoinConfirmationProps> = ({
  title,
  description,
  price,
  onLeave,
  onAction,
  isValidBalance,
}) => {
  const navigate = useNavigate();

  return (
    <Modal id="modalExercises" show padded={false} onLeave={onLeave}>
      <ModalCoinConfirmationContainer>
        <Text id="txt-title-modal" value={title} size="17px" weight="600" align="center" />
        <Text
          id="txt-description"
          value={description}
          size="14px"
          color={colors.grey.G300}
          lineHeight="20px"
          margin="8px 0 24px"
          align="center"
        />
        <ButtonModal
          id="btn-modal"
          text={
            isValidBalance
              ? currencyFormat(price || 0, {
                  delimiter: ',',
                  currencySymbol: '',
                })
              : 'Purchase Coins Now'
          }
          background={colors.white}
          height="50px"
          icon={isValidBalance ? CoinsActiveIcon : undefined}
          iconSize="23px"
          iconPosition="left"
          customTextColor={colors.textPrimary}
          onClick={() => {
            if (isValidBalance) {
              onAction();
            } else {
              navigate(paths.PURCHASE_COINS);
            }
          }}
        />
        {!isValidBalance && (
          <Text
            id="txt-errorBalance"
            value="Your Coins are not sufficient."
            size="12px"
            weight="500"
            color={colors.red}
            lineHeight="20px"
            margin="3px 0 0"
          />
        )}
      </ModalCoinConfirmationContainer>
    </Modal>
  );
};

ModalCoinConfirmation.defaultProps = {
  isValidBalance: true,
};

export default ModalCoinConfirmation;
