import { useMutation } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { createTextFromTopics, generateTopicRecommendation } from 'src/api/generalEnglishSprint';
import { Button, Image, Loading, Radio, Text } from 'src/components';
import {
  InputTopics,
  PracticeStepContainer,
  TopicsRecommendationContainer,
  TopicsRecommendationItem,
} from '../styled';
import { colors } from 'src/constants';
import { CreateTextFromTopicsInputType } from 'src/api/types';
import { GeneralEnglishSprintStore } from 'src/contexts/generalEnglishSprint';
import { String } from 'src/utils';
import RandomIcon from 'src/assets/images/icon-donut.svg';
import { useMixpanel } from 'src/utils/hooks';

const Step1 = () => {
  const { dispatch } = useContext(GeneralEnglishSprintStore);
  const [outputType, setOutputType] = useState<string>('Paragraph');
  const [topic, setTopic] = useState<string>('');
  const [recommendations, setRecommendations] = useState<Array<string>>([]);
  const [selectedRecommendation, setSelectedRecommendation] = useState<string>('');
  const [isRandom, setIsRandom] = useState<boolean>(false);

  const { trackEvent } = useMixpanel();

  const { mutate: generateTopics, isLoading } = useMutation(generateTopicRecommendation, {
    onSuccess: (response) => {
      const topicRecommendations = response?.data?.topics;
      setRecommendations(topicRecommendations);
    },
  });

  const { mutate: generateText, isLoading: isLoadingCreateText } = useMutation(
    (input: CreateTextFromTopicsInputType) => createTextFromTopics(input),
    {
      onSuccess: (response) => {
        trackEvent('GES Generate Text', {
          outputType: outputType?.toLowerCase(),
          topicType:
            selectedRecommendation === '' && !isRandom
              ? 'user input'
              : selectedRecommendation !== '' && !isRandom
              ? 'recommendation'
              : 'random',
          topicText: isRandom ? 'random' : topic,
        });

        dispatch({
          type: 'SET_DATA',
          payload: {
            topic: isRandom ? '' : topic,
            mode: outputType?.toLowerCase(),
            generatedText: response?.data?.generated_text?.trim(),
          },
        });
        if (isRandom) setIsRandom(false);
      },
    },
  );

  const onGenerate = () => {
    generateText({
      mode: outputType?.toLowerCase(),
      topic,
    });
  };

  const onGenerateRandom = () => {
    setIsRandom(true);
    generateText({
      mode: outputType?.toLowerCase(),
      topic: '',
    });
  };

  useEffect(() => {
    generateTopics();
  }, []);

  return (
    <PracticeStepContainer>
      <Loading show={isLoading || isLoadingCreateText} />
      <Text id="txt-outputType" value="Output type:" />
      <Radio
        value={outputType}
        direction="horizontal"
        onChange={(val) => setOutputType(val?.target.value)}
        choices={[
          {
            text: 'Paragraph',
          },
          {
            text: 'Conversational',
          },
        ]}
      />
      <InputTopics
        id="txt-topic"
        big
        placeholder="Please enter any topic you can think of or choose from the recommendations below."
        value={topic}
        onChange={(e) => setTopic(e?.target?.value)}
      />
      <TopicsRecommendationContainer>
        {recommendations?.map((val) => {
          const isActive = val === selectedRecommendation;
          return (
            <TopicsRecommendationItem
              key={val}
              isActive={isActive}
              role="button"
              tabIndex={0}
              aria-hidden
              onClick={() => {
                setTopic(val);
                setSelectedRecommendation(val);
              }}
            >
              <Text
                id={`txt-${String.convertToCamelCase(val)}`}
                value={val}
                size="12px"
                color={isActive ? colors.primary : colors.grey.G300}
              />
            </TopicsRecommendationItem>
          );
        })}
        <TopicsRecommendationItem
          isActive={false}
          withIcon
          role="button"
          tabIndex={0}
          aria-hidden
          onClick={onGenerateRandom}
        >
          <Image id="image-random" src={RandomIcon} alt="Random" width="16px" />
          <Text id="txt-randomText" value="Random Text" size="12px" color={colors.grey.G300} />
        </TopicsRecommendationItem>
      </TopicsRecommendationContainer>
      <Button
        id="btn-generate"
        text="Generate"
        height="50px"
        onClick={onGenerate}
        disabled={!topic}
      />
    </PracticeStepContainer>
  );
};

export default Step1;
