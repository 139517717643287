import { FC, Fragment } from 'react';
import { TextProps } from './types';
import { colors } from 'src/constants';
import { TextBase } from './styled';

const Text: FC<TextProps> = ({
  id,
  value,
  size,
  align,
  weight,
  color,
  display,
  transform,
  lineHeight,
  margin,
  className,
  font,
}) => {
  return (
    <TextBase
      id={id}
      size={size}
      align={align}
      weight={weight}
      color={color}
      display={display}
      transform={transform}
      lineHeight={lineHeight}
      margin={margin}
      className={className}
      font={font}
    >
      {value?.includes('\n')
        ? value?.split('\n')?.map((val, index) => {
            return (
              <Fragment key={index}>
                {val} <br />
              </Fragment>
            );
          })
        : value}
    </TextBase>
  );
};

Text.defaultProps = {
  value: '',
  size: '1rem',
  align: 'left',
  weight: '400',
  color: colors.textPrimary,
  display: 'block',
  transform: '',
  lineHeight: 'normal',
  font: 'Inter',
};

export default Text;
