import Step2 from './Step2';
import Step3 from './Step3';
import { PracticeItemsType } from './types';

export const PRACTICE_ITEMS: PracticeItemsType = {
  1: {
    title: 'Writing Exam Trainer',
    description:
      'Answer the mockup exam writing question below to the best of your ability. The AI will provide feedback on how you can improve your answer.',
    Element: Step2,
  },
  2: {
    title: 'Writing Exam Trainer',
    description:
      'Re-read your answer and review the feedback given by the AI on your estimated score, grammar corrections, and suggestions for improvement.',
    Element: Step3,
  },
};
