import { api } from 'src/config/api';
import { Storage } from 'src/utils';
import {
  IGenerateQuestion,
  IGenerateResultPayload,
  IGenerateResultResponse,
} from 'src/interfaces/writingExamTrainer';
import { GenerateCourseResponseType } from 'src/interfaces/generalEnglishSprint';

export const createCourseID = async (): Promise<GenerateCourseResponseType> => {
  const token = Storage.getUserData()?.accessToken;
  const response = await api.post('course/exam-trainer-writing', null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const generateQuestion = async (): Promise<IGenerateQuestion> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const input = {
    course_id: courseID,
  };

  const response = await api.post('exam-trainer/writing/question', input, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const generateResult = async (
  data: IGenerateResultPayload,
): Promise<IGenerateResultResponse> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const input = {
    ...data,
    course_id: courseID,
  };

  const response = await api.post('exam-trainer/writing/assesment', input, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
