import styled from 'styled-components';

export const CreateProfileContainer = styled.div`
  padding: 1rem;
`;

export const CreateProfileContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

export const CreateProfileLanguageContainer = styled.div`
  width: 100%;
  margin-top: 2.5rem;
`;

export const CreateProfileNameContainer = styled(CreateProfileLanguageContainer)`
  margin-top: 1.5rem;
`;

export const LanguageOptionContainer = styled.div`
  margin-bottom: 1rem;
  :last-of-type {
    margin: 0;
  }
`;

export const ButtonSubmitContainer = styled.div`
  margin-top: 2.5rem;
`;
