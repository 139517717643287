import { colors } from 'src/constants';
import styled, { keyframes } from 'styled-components';
import { LoadingPulseProps } from './types';

export const Container = styled.div`
  position: fixed;
  inset: 0;
  background: ${colors.grey.G900};
  z-index: 30;
`;

export const LoadingWrapper = styled.div`
  position: fixed;
  inset: 0;
  display: grid;
  place-items: center;
  max-width: 480px !important;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  background: ${colors.white};
`;

export const LoadingContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  text-align: center;
`;

const dots = (color: string = colors.textPrimary) => keyframes`
  0%, 20% {
    color: ${colors.white};
    text-shadow:
      .25em 0 0 ${colors.white},
      .5em 0 0 ${colors.white};
    }

  40% {
    color: ${color};
    text-shadow:
      .25em 0 0 ${colors.white},
      .5em 0 0 ${colors.white};
    }

  60% {
    text-shadow:
      .25em 0 0 ${color},
      .5em 0 0 ${colors.white};
    }

  80%, 100% {
    text-shadow:
      .25em 0 0 ${color},
      .5em 0 0 ${color};
    }
  }
`;

export const LoadingPulse = styled.span<LoadingPulseProps>`
  color: ${({ color }) => color || colors.textPrimary};
  font-size: ${({ size }) => size || '1rem'};

  :after {
    content: ' .';
    margin-left: -0.3rem;
    animation: ${(props) => dots(props?.color)} 1.5s steps(5, end) infinite;
  }
`;

export const RowPulse = styled.div`
  display: flex;
  align-items: center;
  gap: 0.875rem;
`;
