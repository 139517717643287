import { useContext } from 'react';
import { Button, Text } from 'src/components';
import { GeneralEnglishSprintStore } from 'src/contexts/generalEnglishSprint';
import {
  Divider,
  GrammarCorrectionItem,
  GrammarCorrectionLists,
  PracticeStepContainer,
} from '../styled';
import { String } from 'src/utils';
import { useMixpanel } from 'src/utils/hooks';

const Step3 = () => {
  const { dispatch, state } = useContext(GeneralEnglishSprintStore);
  const { writingExamTrainer } = state;

  const { trackEvent } = useMixpanel();

  return (
    <PracticeStepContainer>
      <Text
        id="title-yourText"
        value="Mockup Exam Question"
        weight="500"
        size="17px"
        margin="0 0 0.5rem"
      />
      <Text id="txt-yourText" value={writingExamTrainer?.question} font="Gelasio" size="17px" />
      <Divider />

      <Text
        id="title-correctedText"
        value="Your Answer"
        weight="500"
        size="17px"
        margin="0 0 0.5rem"
      />
      <Text id="txt-correctedText" value={writingExamTrainer?.answer} font="Gelasio" size="17px" />
      <Divider />

      <Text
        id="title-ieltsEstimatedScore"
        value="IELTS Estimated Score"
        weight="500"
        size="17px"
        margin="0 0 0.5rem"
      />
      <div className="text-2xl font-medium bg-gray-primary border border-primary px-3 py-2 rounded-lg mb-3">
        {writingExamTrainer?.ielts_estimated_score}
      </div>
      <Text
        id="txt-ieltsEstimatedScoreFeedback"
        value={writingExamTrainer?.ielts_estimated_score_feedback}
        font="Gelasio"
        size="17px"
      />
      <Divider />

      <Text
        id="title-grammarCorrections"
        value="Grammar Corrections"
        weight="500"
        size="17px"
        margin="0 0 0.5rem"
      />
      <GrammarCorrectionLists>
        {writingExamTrainer?.grammar_corrections?.map((val) => {
          return (
            <GrammarCorrectionItem
              key={val}
              dangerouslySetInnerHTML={{ __html: String.specialSentenceWithDoubleQuotes(val) }}
            />
          );
        })}
      </GrammarCorrectionLists>
      <Divider />

      <Text
        id="title-suggestions"
        value="Suggestions"
        weight="500"
        size="17px"
        margin="0 0 0.5rem"
      />
      <GrammarCorrectionLists>
        {writingExamTrainer?.suggestions?.map((val) => {
          return <GrammarCorrectionItem key={val} dangerouslySetInnerHTML={{ __html: val }} />;
        })}
      </GrammarCorrectionLists>

      <Button
        id="btn-continue"
        text="Finish Training Session"
        height="50px"
        onClick={() => {
          trackEvent('WET Finish Practice', {
            mockupQuestion: writingExamTrainer?.question,
            ieltsScore: writingExamTrainer?.ielts_estimated_score,
          });
          dispatch({
            type: 'SET_FINISH',
          });
        }}
      />
    </PracticeStepContainer>
  );
};

export default Step3;
