import { api } from 'src/config/api';
import { Storage } from 'src/utils';
import {
  IChooseDifficultyPayload,
  IChooseDifficultyResponse,
  IGenerateFeedback,
  IGenerateQuestion,
  ISpeechAssessmentPayload,
  ISpeechAssessmentResponse,
} from 'src/interfaces/speakingExamTrainer';
import { ISpeechToTextPayload, ISpeechToTextResponse } from 'src/interfaces/speakingExamTrainer';
import { GenerateCourseResponseType } from 'src/interfaces/generalEnglishSprint';

export const createCourseID = async (): Promise<GenerateCourseResponseType> => {
  const token = Storage.getUserData()?.accessToken;
  const response = await api.post('course/exam-trainer-speaking', null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const chooseDifficulty = async (
  data: IChooseDifficultyPayload,
): Promise<IChooseDifficultyResponse> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const input = {
    ...data,
    course_id: courseID,
  };

  const response = await api.post('exam-trainer/speaking/choose-difficulty', input, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const generateQuestion = async (): Promise<IGenerateQuestion> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const input = {
    course_id: courseID,
  };

  const response = await api.post('exam-trainer/speaking/question', input, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const speechAssessment = async (
  input: ISpeechAssessmentPayload,
): Promise<ISpeechAssessmentResponse> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const formData = new FormData();
  formData.append('course_id', courseID as string);

  Object.entries(input).forEach(([key, value]) => {
    if (key === 'audio') {
      formData.append(key, value, 'audio.wav');
    } else {
      formData.append(key, value);
    }
  });

  const response = await api.post('exam-trainer/speaking/speech-assesment', formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
  return response?.data;
};

export const speechToText = async (input: ISpeechToTextPayload): Promise<ISpeechToTextResponse> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const formData = new FormData();
  formData.append('course_id', courseID as string);

  Object.entries(input).forEach(([key, value]) => {
    if (key === 'audio') {
      formData.append(key, value, 'audio.wav');
    } else {
      formData.append(key, value);
    }
  });

  const response = await api.post('exam-trainer/speaking/speech-to-text', formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
  return response?.data;
};

export const generateFeedback = async (): Promise<IGenerateFeedback> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const input = {
    course_id: courseID,
  };

  const response = await api.post('exam-trainer/speaking/feedback', input, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
