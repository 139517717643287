import { Button, Image } from 'src/components';
import { colors } from 'src/constants';
import styled from 'styled-components';

export const TopNavContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
`;

export const TopNav = styled.div`
  position: relative;
  max-width: 480px !important;
  background: ${colors.purple.P100};
  margin: auto;
  padding: 14px 1rem;
`;

export const BannerHome = styled.div`
  width: 100%;
  background: ${colors.purple.P100};
  padding: 3.5rem 1rem;
  border-radius: 0% 0% 100% 100% / 0% 0% 120px 120px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonStart = styled(Button)`
  border-radius: 100px;
`;

export const ContentHome = styled.div`
  width: 100%;
  padding: 19px 1rem 6rem;
`;

export const ExercisesListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem 0.5rem;
  margin-top: 1rem;
`;

export const ExercisesItem = styled.div`
  position: relative;
  border-radius: 8px;
  cursor: pointer;
`;

export const ExercisesImage = styled(Image)`
  border-radius: 8px;
`;

export const ExercisesModalImage = styled(Image)`
  border-radius: 8px;
  object-fit: cover;
`;

export const ExercisesTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0.5rem 0 0.25rem;
`;

export const ComingSoonBox = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  background: ${colors.grey.G900};
  padding: 0.25rem 0.5rem;
  border-radius: 8px;
`;

export const ExercisesModalContainer = styled.div`
  padding: 0.5rem 1rem 1rem;
`;

export const DescriptionList = styled.ul`
  margin: 0.5rem 0 1.5rem;
  padding-left: 1.25rem;
`;

export const ButtonModal = styled(Button)`
  border: 1px solid ${colors.primary};
`;

export const ModalCoinConfirmationContainer = styled(ExercisesModalContainer)`
  padding: 1rem 1rem;
`;
