import { useMutation } from '@tanstack/react-query';
import { signOut } from 'src/api/auth';
import Storage from '../storage';
import { useNavigate } from 'react-router-dom';

import { paths } from 'src/constants';
import { signOut as signOutGoogle } from 'firebase/auth';
import { auth } from 'src/config/firebase';
import useMixpanel from './useMixpanel';

const useLogout = () => {
  const navigate = useNavigate();

  const { trackEvent } = useMixpanel();

  const { mutate: logout, isLoading } = useMutation(signOut, {
    onSuccess: () => {
      Storage.logout();
      navigate(paths.LOGIN);
    },
  });

  const handleLogout = () => {
    trackEvent('Profile Logout');
    signOutGoogle(auth)
      .then(logout)
      .catch((error) => {
        console.error(error);
      });
  };

  return { isLoading, onLogout: handleLogout };
};

export default useLogout;
