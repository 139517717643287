import Modal from '../Modal/Modal';
import { colors } from 'src/constants';
import { String } from 'src/utils';
import Text from '../Text';
import Button from '../Button';
import { FC } from 'react';
import {
  Divider,
  ModalButtonContainer,
  ModalConfirmationFooterContainer,
  ModalConfirmationHeaderContainer,
} from './styled';

interface Props {
  title: string;
  subtitle?: string;
  showModalConfirmation?: boolean;
  onLeave?: VoidFunction;
  buttonProps: {
    left: {
      id: string;
      text?: string;
      onClick?: VoidFunction;
    };
    right: {
      id: string;
      text?: string;
      onClick?: VoidFunction;
    };
  };
}

const ModalConfirmation: FC<Props> = ({
  title,
  subtitle,
  showModalConfirmation,
  onLeave,
  buttonProps,
}) => {
  if (showModalConfirmation) {
    return (
      <Modal
        id="modalConfirmation"
        padded={false}
        show
        onLeave={onLeave}
        width="273px"
        showClickEverywhere={false}
      >
        <ModalConfirmationHeaderContainer>
          <Text
            id={`txt-${String.convertToCamelCase(title)}`}
            weight="600"
            size="17px"
            value={title}
            align="center"
          />
          {subtitle && (
            <Text
              id={`txt-${String.convertToCamelCase(subtitle)}`}
              size="13px"
              value={subtitle}
              margin="5px 0 0"
              align="center"
            />
          )}
        </ModalConfirmationHeaderContainer>
        <ModalConfirmationFooterContainer>
          <ModalButtonContainer>
            <Button
              background="transparent"
              customTextColor={colors.blue.B300}
              height="22px"
              {...buttonProps.left}
            />
          </ModalButtonContainer>

          <Divider />
          <ModalButtonContainer>
            <Button
              background="transparent"
              customTextColor={colors.blue.B300}
              height="22px"
              {...buttonProps.right}
            />
          </ModalButtonContainer>
        </ModalConfirmationFooterContainer>
      </Modal>
    );
  }
  return null;
};

export default ModalConfirmation;
