import { colors } from 'src/constants';
import Button from '../Button';
import BackIcon from 'src/assets/images/icon-chevron-left.svg';
import CloseIcon from 'src/assets/images/icon-close.svg';
import { FC } from 'react';

interface ButtonBackProps {
  onBackButton: VoidFunction;
  width?: string;
  isClose?: boolean;
}

const ButtonBack: FC<ButtonBackProps> = ({ onBackButton, width, isClose }) => {
  return (
    <Button
      id="btn-back"
      background="transparent"
      text={isClose ? 'Close' : 'Back'}
      customTextColor={colors.blue.B300}
      icon={isClose ? CloseIcon : BackIcon}
      iconPosition="left"
      iconSize="18px"
      width={width}
      height="42px"
      onClick={onBackButton}
    />
  );
};

ButtonBack.defaultProps = {
  width: '120px',
  isClose: false,
};

export default ButtonBack;
