import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { PracticeItemsType } from './types';

export const PRACTICE_ITEMS: PracticeItemsType = {
  1: {
    title: 'Speaking Exam Trainer',
    description:
      'Choose your practice mode. With easy mode, you will be provided with the answer, where you only need to train your pronunciation.',
    Element: Step1,
  },
  2: {
    title: 'Speaking Exam Trainer',
    description:
      'Read both the mockup question and its answer carefully. You will be asked to read them aloud to practice your pronunciation.',
    Element: Step2,
  },
  3: {
    title: 'Speaking Exam Trainer',
    description:
      'Re-read your answer and review the feedback given by the AI on your estimated score, grammar corrections, and suggestions for improvement.',
    Element: Step3,
  },
};
