import { useContext, useState } from 'react';
import { Button, Text } from 'src/components';
import { GeneralEnglishSprintStore } from 'src/contexts/generalEnglishSprint';
import {
  Divider,
  GrammarCorrectionItem,
  GrammarCorrectionLists,
  PracticeDescriptionContainer,
  PracticeStepContainer,
} from '../styled';
import { String } from 'src/utils';
import { useMixpanel } from 'src/utils/hooks';
import { colors } from 'src/constants';
import ModalDetail from '../ModalDetail';
import { IModalDetailData } from 'src/interfaces/speakingExamTrainer';
import AudioPlayer from '../AudioPlayer';

export const colorHandler = (overall: number) => {
  if (overall >= 0 && overall <= 50) {
    return 'text-red-500';
  }
  if (overall > 50 && overall <= 80) {
    return 'text-yellow-500';
  }
  return 'text-green-500';
};

const Step3Difficult = () => {
  const { dispatch, state } = useContext(GeneralEnglishSprintStore);
  const { speakingExamTrainer } = state;

  const { trackEvent } = useMixpanel();

  const [detailModalData, setDetailModalData] = useState<IModalDetailData>({} as IModalDetailData);

  return (
    <>
      <PracticeDescriptionContainer>
        <Text id="txt-heading" size="22px" weight="500" value={'Speaking Exam Trainer'} />
        <Text
          id="txt-description"
          color={colors.grey.G300}
          size="14px"
          margin="0.5rem 0 2rem"
          value={
            'Re-read your answer and review the feedback given by the AI on your estimated score, grammar corrections, and suggestions for improvement.'
          }
        />
      </PracticeDescriptionContainer>

      <PracticeStepContainer>
        <Text
          id="title-yourText"
          value="Mockup Exam Question"
          weight="500"
          size="17px"
          margin="0 0 0.5rem"
        />
        <Text id="txt-yourText" value={speakingExamTrainer?.question} font="Gelasio" size="17px" />
        <Divider />

        <Text
          id="title-correctedText"
          value="Your Answer"
          weight="500"
          size="17px"
          margin="0 0 0.5rem"
        />
        {speakingExamTrainer?.voice_eval?.[0].words?.length ? (
          <>
            <p>
              {speakingExamTrainer?.voice_eval[0].words.map((progress, idx) => {
                return (
                  <span
                    id={`txt-speech-${idx + 1}`}
                    key={idx}
                    className={`${colorHandler(progress?.scores?.overall)} font-[Gelasio]`}
                  >
                    {progress?.word}
                    {speakingExamTrainer?.voice_eval &&
                    speakingExamTrainer?.voice_eval[0].words.length - 1 === idx
                      ? ''
                      : ' '}
                  </span>
                );
              })}
            </p>
            <div className="flex justify-between items-center gap-3 mt-2">
              <AudioPlayer
                audioUrl={speakingExamTrainer.voice_url as string}
                className="!bg-opacity-100 !text-gray-500"
              />
              <div className="flex items-center gap-3">
                <span className={`${colorHandler(speakingExamTrainer?.voice_eval[0].overall)}`}>
                  {speakingExamTrainer?.voice_eval[0].overall}%
                </span>
                <button
                  className="text-primary text-sm border border-primary rounded-full px-4 py-2"
                  onClick={() => {
                    if (speakingExamTrainer?.voice_eval && speakingExamTrainer?.voice_eval[0]) {
                      setDetailModalData(speakingExamTrainer?.voice_eval[0]);
                    }
                  }}
                >
                  Details
                </button>
              </div>
            </div>
          </>
        ) : (
          <Text
            id="txt-yourText"
            value={speakingExamTrainer?.voice_eval?.[0].text}
            font="Gelasio"
            size="17px"
          />
        )}
        <Divider />

        <Text
          id="title-ieltsEstimatedScore"
          value="IELTS Estimated Score"
          weight="500"
          size="17px"
          margin="0 0 0.5rem"
        />
        <div className="text-2xl font-medium bg-gray-primary border border-primary px-3 py-2 rounded-lg mb-3">
          {speakingExamTrainer?.ielts_estimated_score}
        </div>
        <Text
          id="txt-ieltsEstimatedScoreFeedback"
          value={speakingExamTrainer?.ielts_estimated_score_feedback}
          font="Gelasio"
          size="17px"
        />
        <Divider />

        <Text
          id="title-grammarCorrections"
          value="Grammar Corrections"
          weight="500"
          size="17px"
          margin="0 0 0.5rem"
        />
        <GrammarCorrectionLists>
          {speakingExamTrainer?.grammar_corrections?.map((val) => {
            return (
              <GrammarCorrectionItem
                key={val}
                dangerouslySetInnerHTML={{ __html: String.specialSentenceWithDoubleQuotes(val) }}
              />
            );
          })}
        </GrammarCorrectionLists>
        <Divider />

        <Text
          id="title-suggestions"
          value="Suggestions"
          weight="500"
          size="17px"
          margin="0 0 0.5rem"
        />
        <GrammarCorrectionLists>
          {speakingExamTrainer?.suggestions?.map((val) => {
            return <GrammarCorrectionItem key={val} dangerouslySetInnerHTML={{ __html: val }} />;
          })}
        </GrammarCorrectionLists>

        <Button
          id="btn-continue"
          text="Finish Training Session"
          height="50px"
          onClick={() => {
            trackEvent('SET Finish Practice', {
              mockupQuestion: speakingExamTrainer?.question,
              ieltsScore: speakingExamTrainer?.ielts_estimated_score,
            });
            dispatch({
              type: 'SET_FINISH',
            });
          }}
        />
      </PracticeStepContainer>

      <ModalDetail
        data={detailModalData}
        onLeave={() => setDetailModalData({} as IModalDetailData)}
      />
    </>
  );
};

export default Step3Difficult;
