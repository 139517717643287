import { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Container, LoadingContent, LoadingPulse, LoadingWrapper, RowPulse } from './styled';
import { LoadingProps } from './types';
import RocketAnimation from 'src/assets/lottie/rocket.json';
import { colors } from 'src/constants';
import Lottie from 'react-lottie';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: RocketAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
const Loading: FC<LoadingProps> = ({ show }) => {
  const [container, setContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (show) {
      setContainer(document.body);
    } else {
      setContainer(null);
    }
    return () => setContainer(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return container
    ? createPortal(
        <Container id="loading">
          <LoadingWrapper>
            <LoadingContent>
              <Lottie options={defaultOptions} width={160} height={160} />
              <LoadingPulse size="1.25rem" color={colors.grey.G300}>
                Loading
              </LoadingPulse>
              <RowPulse>
                <LoadingPulse>Creating some magic</LoadingPulse>
                <LoadingPulse>Please wait</LoadingPulse>
              </RowPulse>
            </LoadingContent>
          </LoadingWrapper>
        </Container>,
        container,
      )
    : null;
};

export default Loading;
