import { ChangeEvent, FC } from 'react';
import { InputProps } from './types';
import { InputBase, InputContainer, TextAreaBase } from './styled';

const Input: FC<InputProps> = ({
  id,
  name,
  placeholder,
  disabled,
  readOnly,
  onChange,
  value,
  big,
  className,
  font,
  bigAutoResize,
}) => {
  const autoResize = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (bigAutoResize) {
      const textarea = event.target;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };
  return (
    <InputContainer big={big || bigAutoResize} className={className}>
      {big || bigAutoResize ? (
        <TextAreaBase
          id={id}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          onChange={onChange}
          onInput={autoResize}
          value={value}
          font={font}
        />
      ) : (
        <InputBase
          id={id}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          onChange={onChange}
          value={value}
          font={font}
        />
      )}
    </InputContainer>
  );
};

Input.defaultProps = {
  name: '',
  placeholder: '',
  disabled: false,
  readOnly: false,
  onChange: () => null,
  value: '',
  big: false,
  bigAutoResize: false,
  className: '',
  font: 'Inter',
};

export default Input;
