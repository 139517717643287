import { rgba } from 'polished';
import styled, { keyframes } from 'styled-components';
import { ModalContentProps } from './types';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: ${rgba(0, 0, 0, 0.4)};
  animation: ${fadeIn} 0.2s;
  z-index: 20;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 480px !important;
  width: 100%;
  padding: 1rem;
`;

export const ModalContent = styled.div<ModalContentProps>`
  position: relative;
  width: ${({ width }) => width};
  height: auto;
  background: ${({ background }) => background};
  margin-bottom: 0.5rem;
  padding: ${({ padded }) => (padded ? '1rem' : '0')};
  border-radius: 8px;
  overflow: auto;
`;
