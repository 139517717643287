import styled from 'styled-components';
import { CircleButtonBaseProps } from './types';
import { darken } from 'polished';

export const CircleButtonBase = styled.button<CircleButtonBaseProps>`
  display: grid;
  place-items: center;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background: ${({ background }) => background};
  border: none;
  border-radius: 50%;
  transition: 0.2s;
  :hover {
    cursor: pointer;
    background: ${({ background }) => darken(0.2, `${background}`)};
  }
  :disabled {
    cursor: not-allowed;
  }
`;
