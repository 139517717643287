import { api } from 'src/config/api';
import {
  GetProfileResponseType,
  LanguageResponseType,
  UpdateProfileInput,
} from 'src/interfaces/profile';
import { Storage } from 'src/utils';

export const getLanguage = async (): Promise<LanguageResponseType> => {
  const response = await api.get('languages');
  return response?.data;
};

export const getProfile = async (): Promise<GetProfileResponseType> => {
  const token = Storage.getUserData()?.accessToken;
  const response = await api.get('user/my-profile', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const updateProfile = async (input: UpdateProfileInput) => {
  const token = Storage.getUserData()?.accessToken;
  const response = await api.put('user/profile', input, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
