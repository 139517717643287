import styled from 'styled-components';
import { ButtonBaseProps } from './types';
import { darken, rgba } from 'polished';
import { colors } from 'src/constants';

export const ButtonBase = styled.button<ButtonBaseProps>`
  display: flex;
  justify-content: ${({ spaceBetweenIcon }) => (spaceBetweenIcon ? 'space-between' : 'center')};
  align-items: center;
  gap: 0.5rem;
  background: ${({ background }) => background};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding: 5px 10px;
  border: none;
  border-radius: 8px;
  text-align: center;
  white-space: normal;
  text-decoration: none;
  transition: 0.2s;
  :hover {
    cursor: pointer;
    background: ${({ background }) => darken(0.2, `${background}`)};
  }
  :disabled {
    box-shadow: 0 0.6px 1.8px ${rgba(0, 0, 0, 0.11)}, 0 3.2px 7.2px ${rgba(0, 0, 0, 0.13)};
    cursor: not-allowed;
    :hover {
      background: ${colors.grey.G100};
    }
  }
`;
