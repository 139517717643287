import { useState } from 'react';
import { MdVolumeUp, MdPause } from 'react-icons/md';

interface AudioPlayerProps {
  audioUrl: string;
  className?: string;
}

const AudioPlayer = ({ audioUrl, className }: AudioPlayerProps) => {
  const [audio] = useState(new Audio(audioUrl));
  const [isPlaying, setIsPlaying] = useState(false);

  const toggleAudio = () => {
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  audio.addEventListener('ended', () => setIsPlaying(false));

  return (
    <button
      className={`bg-white bg-opacity-20 rounded-full inline-block p-1 text-white ${className}`}
      onClick={toggleAudio}
    >
      {isPlaying ? <MdPause size={24} /> : <MdVolumeUp size={24} />}
    </button>
  );
};

export default AudioPlayer;
