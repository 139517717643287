import styled from 'styled-components';
import { TextBaseProps } from './types';

export const TextBase = styled.span<TextBaseProps>`
  position: relative;
  display: ${({ display }) => display};
  color: ${({ color }) => color};
  font-family: ${({ font }) => font};
  font-size: ${({ size }) => size};
  font-weight: ${({ weight }) => weight};
  text-align: ${({ align }) => align};
  text-transform: ${({ transform }) => transform};
  line-height: ${({ lineHeight }) => lineHeight};
  margin: ${({ margin }) => margin};
`;
