import { ButtonBack } from 'src/components';
import { PracticeContainer, TopNav, TopNavContainer } from './styled';
import { FC, useContext, useEffect, useState } from 'react';
import { paths } from 'src/constants';
import { GeneralEnglishSprintStore } from 'src/contexts/generalEnglishSprint';
import { useNavigate } from 'react-router-dom';
import { PRACTICE_ITEMS } from './constants';
import FinishStep from './FinishStep';
import ModalConfirmation from 'src/components/ModalConfirmation/ModalConfirmation';
import { useMixpanel } from 'src/utils/hooks';

const WritingExamTrainer: FC = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GeneralEnglishSprintStore);
  const { step } = state;
  const [showModalConfirmation, setShowModalConfirmation] = useState<boolean>(false);
  const Content = PRACTICE_ITEMS?.[step];

  const { trackPageview, trackEvent } = useMixpanel();

  const onBackButton = () => {
    if (state?.finish) {
      dispatch({
        type: 'INIT_STEP',
      });
      navigate(paths.HOME);
    } else {
      if (step === 1) setShowModalConfirmation(true);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [step]);

  useEffect(() => {
    trackPageview();
  }, []);

  return (
    <>
      <ModalConfirmation
        title="Exit Speaking Exam Trainer Session?"
        subtitle="All progress will not be saved."
        showModalConfirmation={showModalConfirmation}
        onLeave={() => setShowModalConfirmation(false)}
        buttonProps={{
          left: {
            id: 'btn-exit',
            text: 'Yes, Exit',
            onClick: () => {
              dispatch({
                type: 'SET_BACK_STEP',
              });
              trackEvent('SET Exit');
              navigate(paths.HOME);
            },
          },
          right: {
            id: 'btn-cancel',
            text: 'Cancel',
            onClick: () => setShowModalConfirmation(false),
          },
        }}
      />
      <TopNavContainer>
        <TopNav>
          <ButtonBack onBackButton={onBackButton} width="auto" isClose={state?.finish} />
        </TopNav>
      </TopNavContainer>
      <PracticeContainer>
        {state?.finish ? (
          <FinishStep />
        ) : (
          <>
            <Content.Element />
          </>
        )}
      </PracticeContainer>
    </>
  );
};

export default WritingExamTrainer;
