import { FC, Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MdOutlineInfo,
  MdClose,
  MdPauseCircleOutline,
  MdOutlinePlayCircleOutline,
  MdReplay,
} from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@tanstack/react-query';
import Lottie from 'react-lottie';
import AudioPlayerH5, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import toast from 'react-hot-toast';

import { createCourseID, getReply, getResult } from 'src/api/aiSpeakingPartner';
import { ProgressBar, ModalConfirmation } from 'src/components';
import { paths } from 'src/constants';
import { GeneralEnglishSprintStore } from 'src/contexts/generalEnglishSprint';
import { Storage } from 'src/utils';
import RobotIcon from 'src/assets/images/icon-robot.svg';
import { useMixpanel } from 'src/utils/hooks';
import {
  IChat,
  IChatResponse,
  IModalDetailData,
  IModalResultData,
  IReplyPayload,
} from 'src/interfaces/aiSpeakingPartner';
import loadingAnimation from 'src/assets/lottie/loading-spinner-dots.json';
import soundWaveSmallAnimation from 'src/assets/lottie/sound-wave-sm.json';
import soundWaveSmallWhiteAnimation from 'src/assets/lottie/sound-wave-sm-w.json';

import ChooseDifficulty from './ChooseDifficulty';
import AudioControl from './AudioControl';
import AudioPlayer from './AudioPlayer';
import ModalDetail from './ModalDetail';
import TopicRecommendation from './TopicRecommendation';
import ModalResult from './ModalResult';

export const colorHandler = (overall: number) => {
  if (overall >= 0 && overall <= 50) {
    return 'text-red-500';
  }
  if (overall > 50 && overall <= 80) {
    return 'text-yellow-500';
  }
  return 'text-green-500';
};

interface QuoteContentProps {
  content: string;
}

const QuoteContent = ({ content }: QuoteContentProps) => {
  return (
    <span className="font-bold">
      {'"'}
      {content}
      {'"'}
    </span>
  );
};

const replaceQuotesWithComponent = (input: string) => {
  // Regular expressions for both quotes and newlines
  const quoteRegex = /"([^"]+)"/g;
  const newlineRegex = /\n/g;

  // Split the input text based on both quotes and newlines
  const parts = input.split(quoteRegex);

  // Process each part
  const elements: React.ReactNode[] = [];
  parts.forEach((part, index) => {
    if (index % 2 === 0) {
      // Even index parts are not inside quotes
      // Replace newline characters with <br> tags in these parts
      const newlineParts = part.split(newlineRegex);
      newlineParts.forEach((newlinePart, newlineIndex) => {
        elements.push(
          <Fragment key={`part${index}_newline${newlineIndex}`}>{newlinePart}</Fragment>,
        );
        if (newlineIndex < newlineParts.length - 1) {
          elements.push(<br key={`br${index}_newline${newlineIndex}`} />);
        }
      });
    } else {
      // Odd index parts are inside quotes, so use the QuoteContent component
      elements.push(<QuoteContent key={`quote${index}`} content={part} />);
    }
  });

  return elements;
};

const renderChatMessage = (value: string) => {
  return replaceQuotesWithComponent(value);
};

const AISpeakingPartner: FC = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GeneralEnglishSprintStore);
  const { aiSpeakingPartner } = state;
  const difficulty = aiSpeakingPartner?.difficulty;
  const chats = aiSpeakingPartner?.chats;
  const status = aiSpeakingPartner?.status;
  const progress = aiSpeakingPartner?.progress;

  const [showModalConfirmation, setShowModalConfirmation] = useState<boolean>(false);

  // ----- create course -----
  const { mutate: createCourse, isLoading } = useMutation(createCourseID, {
    onSuccess: (response) => {
      const courseID = response?.data?.course_id;
      if (courseID) {
        Storage.setUserData({ courseID });
      }
    },
  });

  useEffect(() => {
    createCourse();
  }, []);

  // ----- chat initialization -----
  const userData = Storage.getUserData();
  const initialChat: IChat[] = [
    {
      id: uuidv4(),
      role: 'assistant',
      message: `Welcome, ${userData.name}! I am Avid, your AI-Speaking Partner. Before we commence today's practice session, please select the Practice mode.`,
      timestamp: '2023-09-05T03:09:52.355345Z',
      type: 'CHAT',
      voice_url: '', // 'https://unreal-tts-live-demo.s3-us-west-1.amazonaws.com/29a73e89.wav',
    },
  ];

  useEffect(() => {
    dispatch({
      type: 'SET_DATA',
      payload: {
        aiSpeakingPartner: {
          ...aiSpeakingPartner,
          chats: initialChat,
        },
      },
    });
  }, []);

  // ----- handle progress bar -----
  // const currentStep = chats?.filter((item) => item.role === 'user').length || 0;
  // const totalStep = 11; // 1 set topic + 10 user chat bubble
  const currentStep = progress?.count || 0;
  const totalStep = progress?.limit || 10;

  // ----- handle close button -----
  const onCloseButtonClick = () => {
    if (currentStep === totalStep && aiSpeakingPartner?.result) {
      const { result } = aiSpeakingPartner;
      trackEvent('ASP Finish Session', {
        difficulty,
        spokenWords: result.words_count,
        pronunciationScore: result.score,
      });
      setResultModalData({ score: result.score, words_count: result.words_count });
    } else {
      setShowModalConfirmation(true);
    }
  };

  // ----- handle modal -----
  const [detailModalData, setDetailModalData] = useState<IModalDetailData>({} as IModalDetailData);
  const [resultModalData, setResultModalData] = useState<IModalResultData>({} as IModalResultData);

  // ----- tracking -----
  const { trackPageview, trackEvent } = useMixpanel();

  useEffect(() => {
    trackPageview();
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // ----- handle reply -----

  const { mutate: getAIReply, isLoading: isReplyLoading } = useMutation(
    (input: IReplyPayload) => getReply(input),
    {
      onSuccess: (response) => {
        if (aiSpeakingPartner && aiSpeakingPartner.chats) {
          const { chats, count, limit, status } = response.data;
          const chatsWithID: IChat[] = chats.map((item: IChatResponse) => {
            return {
              ...item,
              id: uuidv4(),
            };
          });

          dispatch({
            type: 'SET_DATA',
            payload: {
              aiSpeakingPartner: {
                ...aiSpeakingPartner,
                chats: [...aiSpeakingPartner.chats, ...chatsWithID],
                isRecordingEnabled: count === limit ? false : true,
                status: 'IDLE',
                progress: {
                  status,
                  count,
                  limit,
                },
              },
            },
          });
        }
      },
      onError: () => {
        toast.error('Something went wrong. Please try again.', { position: 'bottom-center' });

        if (aiSpeakingPartner) {
          dispatch({
            type: 'SET_DATA',
            payload: {
              aiSpeakingPartner: {
                ...aiSpeakingPartner,
                isRecordingEnabled: false,
                status: 'ASSISTANT_CHAT_ERROR',
              },
            },
          });
        }
      },
    },
  );

  useEffect(() => {
    if (chats) {
      const lastChat = [...chats].pop();
      if (lastChat?.role === 'user' && lastChat.type === 'CHAT') {
        if (difficulty) {
          getAIReply({
            difficulty,
          });
        }
      }
      if (currentStep === totalStep) {
        getFinalResult();
      }
    }
  }, [chats]);

  // ----- set assistant chat loading -----
  useEffect(() => {
    if (isReplyLoading) {
      dispatch({
        type: 'SET_DATA',
        payload: {
          aiSpeakingPartner: {
            ...aiSpeakingPartner,
            status: 'ASSISTANT_CHAT_LOADING',
          },
        },
      });
    }
  }, [isReplyLoading]);

  // ----- handle auto scroll to bottom -----

  const chatsBottomSection = useRef(null);

  const scrollDown = (ref: React.RefObject<HTMLElement>) => {
    if (ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    scrollDown(chatsBottomSection);
    if (difficulty === 'hard') {
      updatePlayerStyle();
    }
  }, [chats, status]);

  // ----- handle custom audio player -----

  const addClassToElements = (className: string, newClasses: string) => {
    const elements = document.getElementsByClassName(className);
    for (let i = 0; i < elements.length; i++) {
      newClasses.split(' ').forEach((newClass) => {
        elements[i].classList.add(newClass);
      });
    }
  };

  const updatePlayerStyle = (timeoutMs = 1) => {
    setTimeout(() => {
      addClassToElements(
        'rhap_container',
        '!bg-primary !rounded-2xl !rounded-bl-none !p-4 !mt-4 !max-w-[85%]',
      );
      addClassToElements('rhap_progress-section', '!gap-1');
      addClassToElements('rhap_progress-indicator', '!bg-white !-top-[6px]');
      addClassToElements('rhap_progress-filled', '!bg-white !rounded-full');
      addClassToElements('rhap_progress-bar', '!rounded-full !h-2');
      addClassToElements('rhap_progress-bar-show-download', '!bg-[#201584] !bg-opacity-50');
      addClassToElements('rhap_download-progress', '!bg-[#201584] !rounded-full');
      addClassToElements('rhap_current-left-time', '!text-white !text-sm');
      addClassToElements('rhap_controls-section', '!hidden');
    }, timeoutMs);
  };

  useEffect(() => {
    updatePlayerStyle();
  }, []);

  // ----- handle info bubble -----
  const [info, setInfo] = useState('');
  const textInfoInit =
    'You can have a conversation on nearly any topic with your AI Partner. It will strive to maintain your engagement while also providing suggestions for improvement.';
  const textInfoHard =
    'In Difficult mode, AI will reply with audio only. Use headphones for optimal experience.';

  useEffect(() => {
    if (difficulty === '') {
      setInfo(textInfoInit);
    } else if (difficulty === 'hard') {
      setInfo(textInfoHard);
    } else {
      setInfo('');
    }
  }, [difficulty]);

  // ----- handle final result -----
  const { mutate: getFinalResult, isLoading: isResultLoading } = useMutation(() => getResult(), {
    onSuccess: (response) => {
      if (aiSpeakingPartner && aiSpeakingPartner.chats) {
        const { words_count, score } = response.data;

        dispatch({
          type: 'SET_DATA',
          payload: {
            aiSpeakingPartner: {
              ...aiSpeakingPartner,
              result: {
                words_count,
                score,
              },
            },
          },
        });
      }
    },
    onError: () => {
      toast.error('Something went wrong. Please try again.', { position: 'bottom-center' });

      if (aiSpeakingPartner) {
        dispatch({
          type: 'SET_DATA',
          payload: {
            aiSpeakingPartner: {
              ...aiSpeakingPartner,
              isRecordingEnabled: currentStep === totalStep ? false : true,
              status: 'IDLE',
            },
          },
        });
      }
    },
  });

  const onResultModalClose = () => {
    setResultModalData({} as IModalResultData);
    dispatch({
      type: 'INIT_STEP',
    });
    navigate(paths.HOME);
  };

  return (
    <>
      <ModalConfirmation
        title="Exit AI Speaking Partner session?"
        subtitle="All progress will not be saved."
        showModalConfirmation={showModalConfirmation}
        onLeave={() => setShowModalConfirmation(false)}
        buttonProps={{
          left: {
            id: 'btn-exit',
            text: 'Yes, Exit',
            onClick: () => {
              dispatch({
                type: 'INIT_STEP',
              });
              navigate(paths.HOME);
            },
          },
          right: {
            id: 'btn-cancel',
            text: 'Cancel',
            onClick: () => setShowModalConfirmation(false),
          },
        }}
      />

      {/* ----- Top Nav ----- */}
      <header className="p-4 w-full max-w-[480px] fixed shadow-lg bg-white z-[1]">
        <div className="flex flex-row justify-between items-center mb-3">
          <span className="text-base font-medium flex items-center gap-2">
            <img src={RobotIcon} alt="" className="bg-secondary p-[6px] rounded-full" />
            AI-Speaking Partner
          </span>
          <button className="p-3 cursor-pointer" onClick={onCloseButtonClick}>
            <MdClose size={24} />
          </button>
        </div>
        <ProgressBar totalStep={totalStep} currentStep={currentStep} />
      </header>

      {/* ----- Chat Section ----- */}
      <section className="p-4 pt-28 pb-32">
        {/* ----- Info Bubble ----- */}
        {info && (
          <div className="bg-blue-secondary rounded-lg p-3 flex gap-2 mb-6 mt-3">
            <div className="text-blue-primary">
              <MdOutlineInfo size={24} />
            </div>
            <p className="text-sm">{info}</p>
          </div>
        )}

        {/* ----- Chat Bubble ----- */}
        {chats &&
          chats.length > 0 &&
          chats.map(({ id, role, message, type, voice_url, word_eval }) => {
            return (
              <div
                key={id}
                className={`flex flex-col ${role === 'assistant' ? 'items-start ' : 'items-end'}`}
              >
                {role === 'assistant' ? (
                  <>
                    {difficulty === 'easy' || difficulty === '' ? (
                      <div
                        className={`rounded-2xl  p-4 mt-4 max-w-[85%] flex flex-col gap-2 ${
                          type === 'CHAT' || type === 'CHAT_PREGENERATE'
                            ? 'rounded-bl-none bg-primary'
                            : 'rounded-bl-none bg-blue-secondary'
                        }`}
                      >
                        <p
                          className={`${
                            type === 'CHAT' || type === 'CHAT_PREGENERATE'
                              ? 'text-white'
                              : 'text-black'
                          }`}
                        >
                          {renderChatMessage(message)}
                        </p>

                        {voice_url && (
                          <div className="text-right">
                            <AudioPlayer audioUrl={voice_url} />
                          </div>
                        )}
                      </div>
                    ) : (
                      <AudioPlayerH5
                        autoPlay={false}
                        autoPlayAfterSrcChange={false}
                        src={voice_url}
                        onPlay={() => updatePlayerStyle()}
                        onPlaying={() => updatePlayerStyle()}
                        onPause={() => updatePlayerStyle()}
                        onEnded={() => updatePlayerStyle()}
                        onLoadStart={() => updatePlayerStyle(200)}
                        onLoadedMetaData={() => updatePlayerStyle(300)}
                        onLoadedData={() => updatePlayerStyle()}
                        showSkipControls={false}
                        showJumpControls={false}
                        layout="stacked"
                        customControlsSection={[]}
                        customProgressBarSection={[
                          RHAP_UI.MAIN_CONTROLS,
                          RHAP_UI.PROGRESS_BAR,
                          RHAP_UI.CURRENT_LEFT_TIME,
                        ]}
                        customIcons={{
                          play: <MdOutlinePlayCircleOutline className="text-white" />,
                          pause: <MdPauseCircleOutline className="text-white" />,
                        }}
                      />
                    )}
                  </>
                ) : role === 'user' && word_eval ? (
                  <div
                    className={`rounded-2xl  p-4 mt-4 max-w-[85%] flex flex-col gap-2 rounded-br-none bg-gray-secondary`}
                  >
                    <p>
                      {word_eval.words.map((progress, idx) => {
                        return (
                          <span
                            id={`txt-speech-${idx + 1}`}
                            key={idx}
                            className={`${colorHandler(progress?.scores?.overall)}`}
                          >
                            {progress?.word}
                            {word_eval && word_eval.words.length - 1 === idx ? '' : ' '}
                          </span>
                        );
                      })}
                    </p>
                    <div className="flex justify-between items-center gap-3">
                      <AudioPlayer
                        audioUrl={voice_url}
                        className="!bg-opacity-100 !text-gray-500"
                      />
                      <div className="flex items-center gap-3">
                        <span className={`${colorHandler(word_eval.overall)}`}>
                          {word_eval.overall}%
                        </span>
                        <button
                          className="text-primary text-sm"
                          onClick={() => {
                            if (word_eval) {
                              setDetailModalData(word_eval);
                            }
                          }}
                        >
                          Details
                        </button>
                      </div>
                    </div>
                  </div>
                ) : role === 'user' ? (
                  <div
                    className={`rounded-2xl  p-4 mt-4 max-w-[85%] flex flex-col gap-2 rounded-br-none bg-gray-secondary`}
                  >
                    <p className="text-black">{message}</p>
                  </div>
                ) : null}
              </div>
            );
          })}

        {(status === 'ASSISTANT_CHAT_LOADING' || status === 'USER_CHAT_LOADING') && (
          <div
            className={`flex flex-col ${
              status === 'ASSISTANT_CHAT_LOADING' ? 'items-start' : 'items-end'
            }`}
          >
            <div
              className={`rounded-2xl p-1 mt-4 max-w-[85%] flex flex-col gap-2  ${
                status === 'ASSISTANT_CHAT_LOADING'
                  ? 'rounded-bl-none bg-primary'
                  : 'rounded-br-none bg-gray-secondary'
              }`}
            >
              <div className="z-[1]">
                <Lottie
                  options={{
                    ...defaultOptions,
                    animationData:
                      status === 'ASSISTANT_CHAT_LOADING'
                        ? soundWaveSmallWhiteAnimation
                        : soundWaveSmallAnimation,
                  }}
                  width={50}
                  height={50}
                />
              </div>
            </div>
          </div>
        )}

        {status === 'ASSISTANT_CHAT_ERROR' && (
          <div
            className={`flex flex-col ${
              status === 'ASSISTANT_CHAT_ERROR' ? 'items-start' : 'items-end'
            }`}
          >
            <button
              className={`rounded-full px-4 py-3 mt-4 flex flex-row gap-2 items-center bg-white border border-primary text-primary`}
              onClick={() => {
                if (difficulty) {
                  getAIReply({
                    difficulty,
                  });
                }
              }}
            >
              <MdReplay size={24} />
              <span>Retry</span>
            </button>
          </div>
        )}

        {/* ----- Difficulty Selection ----- */}
        {difficulty === '' && !isLoading && <ChooseDifficulty />}
        {(isLoading || isResultLoading) && (
          <Lottie options={defaultOptions} width={100} height={50} />
        )}

        <div ref={chatsBottomSection} />
      </section>

      {difficulty !== '' && currentStep === 0 && status === 'IDLE' && <TopicRecommendation />}

      <ModalDetail
        data={detailModalData}
        onLeave={() => setDetailModalData({} as IModalDetailData)}
      />
      <ModalResult data={resultModalData} onLeave={onResultModalClose} />

      <AudioControl />
    </>
  );
};

export default AISpeakingPartner;
