import { FC, useState, useEffect, ReactPortal } from 'react';
import { createPortal } from 'react-dom';
import { OptionsContainer, OptionContent, OptionList, OptionItem, OptionsWrapper } from './styled';
import { OptionsProps } from './types';
import Text from '../Text';
import { colors } from 'src/constants';
import Image from '../Image';
import CheckIcon from 'src/assets/images/icon-checklist.svg';

const Options: FC<OptionsProps> = ({
  id,
  title,
  dataList,
  onLeave,
  show,
  selected,
  onSelected,
}): ReactPortal | null => {
  const [rendered, setRendered] = useState(false);
  useEffect(() => {
    setRendered(true);
  }, []);

  if (rendered && show) {
    return createPortal(
      <OptionsWrapper
        id={id}
        onClick={(e) => {
          e.stopPropagation();
          if (typeof onLeave === 'function') {
            onLeave();
          }
        }}
      >
        <OptionsContainer>
          <OptionContent>
            {title && (
              <Text
                id="title-option"
                value={title}
                size="17px"
                weight="700"
                color={colors.primary}
              />
            )}
            <OptionList spacedTop={!!title}>
              {dataList?.map((item) => {
                const { label, value } = item;
                const isSelected = selected === value;
                return (
                  <OptionItem key={`key-${value}`}>
                    {isSelected ? (
                      <Image
                        id={`img-selcted-${id}`}
                        src={CheckIcon}
                        alt="Checklist"
                        width="24px"
                      />
                    ) : (
                      <div />
                    )}
                    <div
                      className="text-container"
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        if (typeof onSelected === 'function') {
                          onSelected(value, item);
                        }
                        if (typeof onLeave === 'function') {
                          onLeave();
                        }
                      }}
                      aria-hidden
                    >
                      <Text id="title-option" value={label} size="17px" />
                    </div>
                  </OptionItem>
                );
              })}
            </OptionList>
          </OptionContent>
        </OptionsContainer>
      </OptionsWrapper>,
      document.body,
    );
  }
  return null;
};

Options.defaultProps = {
  title: '',
  onLeave: () => null,
  show: false,
};

export default Options;
