import LanguageIcon from 'src/assets/images/icon-globe.svg';
import ChecklistIcon from 'src/assets/images/icon-checklist-white.svg';
import { Button, ButtonBack, Image, Loading, Text } from 'src/components';
import {
  ButtonNavContainer,
  ProfileLanguageContainer,
  ProfileLanguageContent,
  ProfileLanguageItem,
  TopNav,
  TopNavContainer,
} from '../styled';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getLanguage, updateProfile } from 'src/api/profile';
import { UpdateProfileInput } from 'src/api/types';
import { useNavigate } from 'react-router-dom';
import { paths } from 'src/constants';
import { Storage } from 'src/utils';
import { useEffect, useState } from 'react';
import { useMixpanel } from 'src/utils/hooks';

const ProfileNativeLanguage = () => {
  const navigate = useNavigate();
  const userDataStorage = Storage.getUserData();
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [isGetLanguage, setIsGetLanguage] = useState<boolean>(true);

  const { trackEvent } = useMixpanel();

  const { mutate: updateDataUser, isLoading: loadingUpdate } = useMutation(
    (userData: UpdateProfileInput) => updateProfile(userData),
    {
      onSuccess: () => Storage.setUserData({ language: selectedLanguage }),
    },
  );

  const { isLoading, data: lang } = useQuery(['language'], getLanguage, {
    select: (response) => response?.lang,
    enabled: isGetLanguage,
  });

  useEffect(() => {
    if (selectedLanguage) {
      updateDataUser({
        profile: {
          name: userDataStorage?.name as string,
          native_language: selectedLanguage,
        },
      });
    }
  }, [selectedLanguage]);

  useEffect(() => {
    if (isGetLanguage) {
      setIsGetLanguage(false);
    }
  }, [isGetLanguage]);

  return (
    <>
      <TopNavContainer>
        <TopNav>
          <ButtonNavContainer>
            <ButtonBack onBackButton={() => navigate(paths.PROFILE)} width="auto" />
          </ButtonNavContainer>
          <Text id="txt-heading" size="17px" value="Native Language" align="center" />
        </TopNav>
      </TopNavContainer>
      <ProfileLanguageContainer>
        <Loading show={isLoading || loadingUpdate} />
        <Image id="icon-language" src={LanguageIcon} alt="Language" width="80px" />
        <Text
          id="txt-headingCreateProfile"
          size="28px"
          weight="700"
          value="Change your native language?"
          align="center"
        />
        <ProfileLanguageContent>
          {lang &&
            lang?.length > 0 &&
            lang?.map((val) => {
              return (
                <ProfileLanguageItem key={`key-${val}`}>
                  <Button
                    id={`btn-${val}`}
                    text={val}
                    height="50px"
                    spaceBetweenIcon
                    icon={val === userDataStorage?.language ? ChecklistIcon : undefined}
                    iconPosition="right"
                    iconSize="24px"
                    onClick={() => {
                      trackEvent('Profile Change Language', {
                        before: userDataStorage?.language,
                        after: val,
                      });
                      setSelectedLanguage(val);
                    }}
                  />
                </ProfileLanguageItem>
              );
            })}
        </ProfileLanguageContent>
      </ProfileLanguageContainer>
    </>
  );
};

export default ProfileNativeLanguage;
