import { FC, useEffect, useMemo, useState } from 'react';
import {
  BannerHome,
  ButtonContainer,
  ButtonStart,
  ComingSoonBox,
  ContentHome,
  ExercisesImage,
  ExercisesItem,
  ExercisesListContainer,
  ExercisesTitleContainer,
  TopNav,
  TopNavContainer,
} from './styled';
import { Image, Loading, Text } from 'src/components';
import Logo from 'src/assets/images/logo.png';
import StartIcon from 'src/assets/images/icon-play-arrow.svg';
import LockIcon from 'src/assets/images/icon-lock.svg';
import { colors, paths } from 'src/constants';
import { Storage, String } from 'src/utils';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { generateCourseID } from 'src/api/generalEnglishSprint';
import ModalExercises from './ModalExercises';
import { ModalCoinConfirmationProps, ModalExercisesProps } from './types';
import { useMixpanel } from 'src/utils/hooks';
import { getProfile } from 'src/api/profile';
import ModalCoinConfirmation from './ModalCoinConfirmation';
import { getCourseList } from 'src/api/courseList';
import useCourses from 'src/contexts/courses/useCourses';
import { COURSES_IDS } from 'src/contexts/courses/constants';
import { getBalance } from 'src/api/wallet';

const Home: FC = () => {
  const { dispatch, state } = useCourses();
  const { courseList, isInitial } = state;
  const navigate = useNavigate();
  const [modalData, setModalData] = useState<ModalExercisesProps['data']>(
    {} as ModalExercisesProps['data'],
  );
  const [modalCoinConfirmation, setModalCoinConfirmation] = useState<ModalCoinConfirmationProps>(
    {} as ModalCoinConfirmationProps,
  );

  const { trackPageview, trackEvent } = useMixpanel();

  const { mutate: generate, isLoading } = useMutation(generateCourseID, {
    onSuccess: (response) => {
      trackEvent('GES Start Practice');
      const courseID = response?.data?.course_id;
      if (courseID) {
        Storage.setUserData({ courseID });
        navigate(paths.PRACTICE);
      }
    },
  });

  useQuery(['profile'], getProfile, {
    onSuccess: (response) => {
      const name = response?.data?.display_name;
      const language = response?.data?.native_language;
      Storage.setUserData({ name, language });
    },
  });

  const { data: dataCourse, isLoading: isLoadingCourse } = useQuery(['course'], getCourseList, {
    enabled: !!isInitial,
    select: (response) => response?.data?.courses,
    onSuccess: (courses) => {
      if (courses?.length > 0) {
        dispatch({
          type: 'SET_COURSES',
          payload: courses,
        });
      }
    },
  });

  const { data: balance } = useQuery(['balance'], getBalance, {
    select: (response) => response?.data?.balance,
  });

  useEffect(() => {
    trackPageview();
  }, []);

  const onCloseModalCoinConfirmation = () => {
    setModalCoinConfirmation({} as ModalCoinConfirmationProps);
  };

  useEffect(() => {
    const isNewUser = Storage.getIsNewUser();
    if (isNewUser) {
      setModalCoinConfirmation({
        title: 'Sign-up Bonus!',
        description:
          'Thanks for registering with AvidX. You have received 100 Coins to start practicing your English and make a breakthrough!',
        onAction: () => {
          Storage.removeIsNewUser();
          onCloseModalCoinConfirmation();
        },
        onLeave: () => {
          Storage.removeIsNewUser();
          onCloseModalCoinConfirmation();
        },
        price: 200,
        isValidBalance: true,
      });
    }
  }, []);

  const generalEnglishSprint = useMemo(() => {
    return dataCourse?.filter((val) => val?.course_id === COURSES_IDS.GENERAL_ENGLISH_SPRINT)?.[0];
  }, [dataCourse]);

  return (
    <>
      {Object.values(modalData)?.length > 0 && (
        <ModalExercises
          data={modalData}
          onLeave={() => setModalData({} as ModalExercisesProps['data'])}
        />
      )}

      {Object.values(modalCoinConfirmation)?.length > 0 && (
        <ModalCoinConfirmation
          title={modalCoinConfirmation?.title}
          description={modalCoinConfirmation?.description}
          price={modalCoinConfirmation?.price}
          onLeave={modalCoinConfirmation?.onLeave}
          onAction={modalCoinConfirmation?.onAction}
          isValidBalance={modalCoinConfirmation?.isValidBalance}
        />
      )}

      <TopNavContainer>
        <TopNav>
          <Image id="image-logo" alt="Logo" src={Logo} width="40px" />
        </TopNav>
      </TopNavContainer>
      <BannerHome>
        <Loading show={isLoading || isLoadingCourse} />

        <Text
          id="txt-bannerSlogan"
          align="center"
          value="Make a breakthrough today"
          color={colors.white}
          margin="2.5rem 0 0.5rem"
        />
        <Text
          id="txt-appName"
          align="center"
          value="General English Sprint"
          color={colors.purple.P200}
          size="28px"
          weight="700"
        />
        <Text
          id="txt-description"
          align="center"
          value="AI-powered deliberate daily English practice with your own choice of learning contents."
          color={colors.white}
          margin="4px 0 1.2rem"
        />
        <ButtonContainer>
          <ButtonStart
            id="btn-start"
            text="Start"
            icon={StartIcon}
            iconPosition="left"
            iconSize="20px"
            width="107px"
            height="44px"
            onClick={() => {
              const price = generalEnglishSprint?.course_price || 0;
              setModalCoinConfirmation({
                title: 'General English Sprint',
                description:
                  'Train your overall English skills using yourown choice of learning contents.',
                onAction: generate,
                onLeave: onCloseModalCoinConfirmation,
                price,
                isValidBalance: (balance || 0) >= price,
              });
            }}
          />
        </ButtonContainer>
      </BannerHome>
      <ContentHome>
        <Text id="txt-advancedExercises" value="Advanced Exercises" size="17px" weight="500" />
        <ExercisesListContainer>
          {courseList?.map((val) => {
            return (
              <ExercisesItem
                key={JSON.stringify(val)}
                role="button"
                tabIndex={0}
                onClick={() => {
                  trackEvent('Click Feature Card', {
                    feature: val?.title,
                  });
                  setModalData({
                    ...val,
                    isValidBalance: (balance || 0) > (val?.price || 0),
                  });
                }}
                aria-hidden
              >
                {val?.locked && !val.action && (
                  <ComingSoonBox>
                    <Text
                      id={`txt-coming-soon-${String.convertToCamelCase(val?.title)}`}
                      value="Coming Soon"
                      size="11px"
                      weight="500"
                      color={colors.primary}
                    />
                  </ComingSoonBox>
                )}
                <ExercisesImage
                  id={`image-${String.convertToCamelCase(val?.title)}`}
                  alt={val?.title}
                  src={val?.image}
                  width="100%"
                />
                <ExercisesTitleContainer>
                  {val?.locked && (
                    <Image id="image-locker" alt="Lock" src={LockIcon} width="16px" />
                  )}
                  <Text
                    id={`txt-${String.convertToCamelCase(val?.title)}`}
                    value={val?.title}
                    size="13px"
                    weight="600"
                  />
                </ExercisesTitleContainer>
                <Text
                  id={`txt-decs-${String.convertToCamelCase(val?.title)}`}
                  value={val?.description}
                  size="12px"
                  color={colors.grey.G300}
                  lineHeight="16px"
                />
              </ExercisesItem>
            );
          })}
        </ExercisesListContainer>
      </ContentHome>
    </>
  );
};

export default Home;
