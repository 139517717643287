import { FC } from 'react';
import { ImageProps } from './types';
import { ImageBase } from './styled';

const Image: FC<ImageProps> = ({ id, src, alt, width, height, className }) => {
  return (
    <ImageBase id={id} src={src} alt={alt} width={width} height={height} className={className} />
  );
};

Image.defaultProps = {
  width: '100%',
  height: 'auto',
};

export default Image;
