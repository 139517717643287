import { Button, Image, Loading, Text } from 'src/components';
import {
  Divider,
  GeneratedTextTitleContainer,
  InputTranslate,
  PracticeStepContainer,
  RefreshContainer,
} from '../styled';
import { useContext, useEffect, useState } from 'react';
import { GeneralEnglishSprintStore } from 'src/contexts/generalEnglishSprint';
import { Storage } from 'src/utils';
import { useMutation } from '@tanstack/react-query';
import RefreshIcon from 'src/assets/images/icon-refresh.svg';
import { createCourseID, generateQuestion, generateResult } from 'src/api/writingExamTrainer';
import { IGenerateResultPayload } from 'src/interfaces/writingExamTrainer';
import Lottie from 'react-lottie';
import loadingAnimation from 'src/assets/lottie/loading-skeleton-text.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Step2 = () => {
  const { dispatch, state } = useContext(GeneralEnglishSprintStore);
  const { writingExamTrainer } = state;
  const [answer, setAnswer] = useState<string>('');

  // ----- create course -----
  const { mutate: onCreateCourseID, isLoading: isLoadingCreateCourseID } = useMutation(
    createCourseID,
    {
      onSuccess: (response) => {
        const courseID = response?.data?.course_id;
        if (courseID) {
          Storage.setUserData({ courseID });
        }
      },
    },
  );

  const userData = Storage.getUserData();

  useEffect(() => {
    onCreateCourseID();
  }, []);

  useEffect(() => {
    if (userData.courseID !== '') {
      onGenerateQuestion();
    }
  }, [userData.courseID]);

  const { mutate: onGenerateQuestion, isLoading: isLoadingGenerateQuestion } = useMutation(
    () => generateQuestion(),
    {
      onSuccess: (response) => {
        dispatch({
          type: 'SET_DATA',
          payload: {
            writingExamTrainer: {
              ...writingExamTrainer,
              question: response.data.question,
            },
            step: 1,
          },
        });
      },
    },
  );

  const { mutate: onGenerateResult, isLoading: isLoadingGenerateResult } = useMutation(
    (input: IGenerateResultPayload) => generateResult(input),
    {
      onSuccess: (response, input) => {
        const {
          ielts_estimated_score,
          ielts_estimated_score_feedback,
          grammar_corrections,
          suggestions,
        } = response.data;
        dispatch({
          type: 'SET_DATA',
          payload: {
            writingExamTrainer: {
              ...writingExamTrainer,
              answer: input.answer,
              ielts_estimated_score,
              ielts_estimated_score_feedback,
              grammar_corrections,
              suggestions,
            },
          },
        });
      },
    },
  );

  const onRefresh = () => {
    onCreateCourseID();
  };

  const onSubmit = () => {
    onGenerateResult({
      answer,
    });
  };

  return (
    <PracticeStepContainer>
      <Loading show={isLoadingGenerateResult} />

      <GeneratedTextTitleContainer>
        <Text id="txt-titleGeneratedText" value="Mockup Exam Question" weight="500" size="17px" />
        <RefreshContainer role="button" tabIndex={0} onClick={onRefresh} aria-hidden>
          <Image id="image-refreshText" alt="Refresh" src={RefreshIcon} width="24px" />
        </RefreshContainer>
      </GeneratedTextTitleContainer>

      {isLoadingCreateCourseID || isLoadingGenerateQuestion ? (
        <div className="-mx-4 -my-5">
          <Lottie options={defaultOptions} />
        </div>
      ) : (
        <Text
          id="txt-generatedTextResult"
          value={writingExamTrainer?.question}
          weight="500"
          font="Gelasio"
        />
      )}
      <Divider />

      <Text
        id="txt-titleTranslateText"
        value="Write Your Answer Below"
        weight="500"
        size="17px"
        margin="0 0 0.5rem"
      />
      <InputTranslate
        id="txt-tranlate"
        bigAutoResize
        placeholder="Enter your answer...."
        value={answer}
        font="Gelasio"
        onChange={(e) => setAnswer(e?.target?.value)}
      />
      <Button
        id="btn-checkGrammar"
        text="Check Your Answer"
        height="50px"
        disabled={!answer}
        onClick={onSubmit}
      />
    </PracticeStepContainer>
  );
};

export default Step2;
