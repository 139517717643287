const voiceOver = [
  {
    label: 'Axel',
    value: 'male-0',
  },
  {
    label: 'Cooper',
    value: 'male-1',
  },
  {
    label: 'Hudson',
    value: 'male-2',
  },
  {
    label: 'Larry',
    value: 'male-3',
  },
];

export default voiceOver;
