import { api } from 'src/config/api';
import { Storage } from 'src/utils';
import {
  CheckGrammarInputType,
  CheckGrammarResponseType,
  CreateTextFromTopicsInputType,
  CreateTextFromTopicsResponseType,
  GenerateCourseResponseType,
  GenerateQuizInputType,
  GenerateQuizResponseType,
  GenerateTopicsResponseType,
  GeneratedTextSubmitInputType,
  GeneratedTextSubmitResponseType,
  ParaphraseTextInputType,
  ParaphraseTextResponseType,
  SpeechEvalInputType,
  SpeechEvalResponseType,
  SpeechFromTextInputType,
  SpeechFromTextResponseType,
} from 'src/interfaces/generalEnglishSprint';

export const generateCourseID = async (): Promise<GenerateCourseResponseType> => {
  const token = Storage.getUserData()?.accessToken;
  const response = await api.post('course/general-english-sprint', null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const generateTopicRecommendation = async (): Promise<GenerateTopicsResponseType> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const input = {
    course_id: courseID,
  };

  const response = await api.post('general-english-sprint/generate-topic-recommendation', input, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const createTextFromTopics = async (
  data: CreateTextFromTopicsInputType,
): Promise<CreateTextFromTopicsResponseType> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const input = {
    course_id: courseID,
    ...data,
  };

  const response = await api.post('general-english-sprint/create-text-from-topic', input, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const generatedTextSubmit = async (
  data: GeneratedTextSubmitInputType,
): Promise<GeneratedTextSubmitResponseType> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const input = {
    ...data,
    course_id: courseID,
  };

  const response = await api.post('general-english-sprint/generated-text-submit', input, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const checkGrammar = async (
  data: CheckGrammarInputType,
): Promise<CheckGrammarResponseType> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const input = {
    course_id: courseID,
    ...data,
  };

  const response = await api.post('general-english-sprint/check-grammar', input, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const generateQuiz = async (
  data: GenerateQuizInputType,
): Promise<GenerateQuizResponseType> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const input = {
    course_id: courseID,
    ...data,
  };

  const response = await api.post('general-english-sprint/reading-comprehension-quiz', input, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const paraphraseText = async (
  data: ParaphraseTextInputType,
): Promise<ParaphraseTextResponseType> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const input = {
    course_id: courseID,
    ...data,
  };

  const response = await api.post('general-english-sprint/paraphrase-text', input, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const speechFromText = async (
  data: SpeechFromTextInputType,
): Promise<SpeechFromTextResponseType> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const input = {
    course_id: courseID,
    ...data,
  };

  const response = await api.post('general-english-sprint/speech-from-text', input, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const speechEval = async (input: SpeechEvalInputType): Promise<SpeechEvalResponseType> => {
  const userData = Storage.getUserData();
  const token = userData?.accessToken;
  const courseID = userData?.courseID;
  const formData = new FormData();
  formData.append('course_id', courseID as string);

  Object.entries(input).forEach(([key, value]) => {
    if (key === 'audio') {
      formData.append(key, value, 'audio.wav');
    } else {
      formData.append(key, value);
    }
  });

  const response = await api.post('general-english-sprint/speech-eval', formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
  return response?.data;
};
