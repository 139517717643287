import { useState } from 'react';
import { Button, ButtonBack, Image, Text } from 'src/components';
import {
  CoinsChoicesContainer,
  TopNavContainer,
  TopUpListItem,
  TopUpList,
  TopUpCoinsContainer,
  ButtonContainer,
} from './styled';
import { useNavigate } from 'react-router-dom';
import { paths } from 'src/constants';
import RollbackIcon from 'src/assets/images/icon-rollback.svg';
import CoinsActiveIcon from 'src/assets/images/icon-avidx-coins-active.svg';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getTopUpPriceList, topUp } from 'src/api/wallet';
import currencyFormat from 'src/utils/currencyFormat';
import { TopUpInput } from 'src/interfaces/wallet';
import loadingAnimation from 'src/assets/lottie/loading-spinner-dots.json';
import Lottie from 'react-lottie';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const SUCCESS_URL = process.env.REACT_APP_PAYMENT_SUCCESS_URL || '';
const FAILED_URL = process.env.REACT_APP_PAYMENT_FAILED_URL || '';

const PurchaseCoins = () => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState<number | null>(null);

  const { data: topUpList, isLoading: isLoadingList } = useQuery(['topUpList'], getTopUpPriceList, {
    select: (response) => response?.data?.coin_price_list,
  });

  const { mutate: purchaseCoins, isLoading } = useMutation((input: TopUpInput) => topUp(input), {
    cacheTime: 0,
    onSuccess: (response) => {
      const a = document.createElement('a');
      a.href = response?.data?.payment_link;
      a.click();
    },
  });

  return (
    <>
      <TopNavContainer>
        <ButtonBack onBackButton={() => navigate(paths.PROFILE)} width="auto" />
        <Text id="txt-heading" size="17px" value="Purchase Coins" />
        <Button
          id="btn-checkHistory"
          width="auto"
          background="transparent"
          icon={RollbackIcon}
          iconSize="24"
          onClick={() => navigate(paths.TRANSACTION_HISTORY)}
        />
      </TopNavContainer>
      <CoinsChoicesContainer>
        <Text id="txt-getMoreCoins" size="17px" value="Get more coins" margin="0 0 14px" />
        {isLoadingList && <Lottie options={defaultOptions} width={160} height={160} />}
        <TopUpList>
          {!isLoadingList &&
            topUpList?.map((item) => {
              return (
                <TopUpListItem
                  id={`btn-topUp-${item?.price_in_rupiah}`}
                  onClick={() => setAmount(item?.amount)}
                  key={`${item?.amount}-${item?.price_in_rupiah}`}
                  isActive={amount === item?.amount}
                >
                  <TopUpCoinsContainer>
                    <Image id="image-userCoins" alt="user coins" src={CoinsActiveIcon} width="23" />
                    <Text
                      id="txt-coins"
                      size="15px"
                      weight="600"
                      value={currencyFormat(item?.amount || 0, {
                        delimiter: ',',
                        currencySymbol: '',
                      })}
                    />
                  </TopUpCoinsContainer>
                  <Text
                    id="txt-heading"
                    size="15px"
                    value={`${item?.formatted_price_in_rupiah} (${item?.formatted_price_in_usd})`}
                  />
                </TopUpListItem>
              );
            })}
        </TopUpList>
      </CoinsChoicesContainer>
      <ButtonContainer>
        {isLoading ? (
          <Lottie options={defaultOptions} width={160} height={50} />
        ) : (
          <Button
            id="btn-payNow"
            text="Pay Now"
            height="50px"
            disabled={!amount}
            onClick={() => {
              purchaseCoins({
                amount: amount as number,
                success_return_url: SUCCESS_URL,
                fail_return_url: FAILED_URL,
              });
            }}
          />
        )}
        <Text
          id="txt-paymentInfo"
          size="13px"
          color="#747474"
          margin="1rem 0 0"
          value="Coins are used to unlock practice session. The Coins required for each module may vary. The price of the Coins may change without prior notice."
        />
      </ButtonContainer>
    </>
  );
};

export default PurchaseCoins;
