const colors = {
  primary: '#594AE3',
  secondary: '#D7D3F8',
  purple: {
    P100: '#150E58',
    P200: '#AEA7F1',
    P300: '#EBE9FC',
  },
  grey: {
    G100: '#EBEBEB',
    G200: '#C7C7C7',
    G300: '#5C5C5C',
    G400: '#E3E8EF',
    G500: '#808080',
    G600: '#EFF2F5',
    G700: '#7C7B7B',
    G800: '#F9F9F9',
    G900: '#F2F2F7',
  },
  textPrimary: '#181818',
  blue: {
    B100: '#24A0ED',
    B200: '#4385F5',
    B300: '#007AFF',
    B400: '#0271E0',
    B500: '#E6F2FF',
    B600: '#F1F4F9',
  },
  white: '#FFFFFF',
  green: '#34C759',
  orange: '#FF9500',
  red: '#FF3B30',
};

export default colors;
