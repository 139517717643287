import { rgba } from 'polished';
import { Link } from 'react-router-dom';
import { colors } from 'src/constants';
import styled from 'styled-components';

export const TopNavContainer = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  z-index: 50;
`;

export const TopNav = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 480px !important;
  height: 56px;
  margin: auto;
  background: ${colors.white};
  box-shadow: 0px 0.6px 1.8px ${rgba(0, 0, 0, 0.11)}, 0px 3.2px 7.2px ${rgba(0, 0, 0, 0.13)};
`;

export const ButtonNavContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
`;

export const ProfileContainer = styled.div`
  height: 100%;
  padding: 3.5rem 0;
`;

export const PhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 1rem 1rem;
  border-bottom: 1px solid ${colors.grey.G400};
`;

export const SettingContainer = styled.div`
  padding: 2.5rem 0 1rem;
`;

export const SettingTitleContainer = styled.div`
  margin-bottom: 1rem;
  padding: 0 1rem;
`;

export const SettingMenu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const SettingMenuItem = styled.li`
  padding: 1rem;
  border: 1px solid ${colors.grey.G400};
`;

export const SettingMenuLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const SettingMenuOutsideLink = styled.a`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const SettingMenuLogout = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

export const ProfileLanguageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding-top: 5rem;
`;

export const ProfileLanguageContent = styled.div`
  width: 100%;
  margin-top: 2.5rem;
  padding: 1rem;
`;

export const ProfileLanguageItem = styled.div`
  margin-bottom: 1rem;
  :last-of-type {
    margin: 0;
  }
`;

export const UserCoinsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
`;
