const convertToCamelCase = (string: string): string => {
  return string
    .replace(/\s(.)/g, (a) => {
      return a.toUpperCase();
    })
    .replace(/\s/g, '')
    .replace(/^(.)/, (b) => {
      return b.toLowerCase();
    });
};

const replaceSpaceWithDash = (word = ''): string => word.replace(/\s/g, '-').toLowerCase();

const removeSpecialCharacter = (string: string): string => {
  return removeExtraWhiteSpace(string.replace(/[^a-zA-Z\d\s]/gm, ' '));
};

const removeWhiteSpace = (string: string): string => {
  return string.replace(/(\r\n|\n|\r)/gm, '').replace(/\s+/g, '');
};

const removeExtraWhiteSpace = (string: string): string => {
  return string.replace(/(\r\n|\n|\r)/gm, ' ').replace(/\s+/g, ' ');
};

const sanitizeAddress = (address: string): string => {
  return removeExtraWhiteSpace(address.replace(/[^A-Za-z0-9 #&'.\-/=+,()\s]/gm, ' '));
};

const convertToSentenceCase = (text: string): string => {
  const result = text.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

const capitalizeFirstLetter = (string: string): string => {
  const firstLetter = string?.slice(0, 1);
  const remainingLetter = string?.slice(1, string?.length);
  return `${firstLetter?.toUpperCase()}${remainingLetter?.toLowerCase()}`;
};

const specialSentenceWithDoubleQuotes = (string: string) => {
  const regex = /("[^"]*")/g;
  const matches = string.match(regex);

  let renderedText = string;
  if (matches) {
    matches.forEach((match) => {
      const trimmedMatch = match.replace(/"/g, '');
      renderedText = renderedText.replace(match, `"<span>${trimmedMatch}</span>"`);
    });
  }

  return renderedText;
};

const replaceCharacter = (string: string, index: number, replacement: string) => {
  return string?.slice(0, index) + replacement + string?.slice(index + replacement.length);
};

const formatFloatString = (numb: number) => {
  if (numb.toString().length === 1) {
    return numb.toFixed(1);
  }
  return numb.toString();
};

const writeString = (view: DataView, offset: number, string: string): void => {
  for (let i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
};

const String = {
  convertToCamelCase,
  replaceSpaceWithDash,
  convertToSentenceCase,
  removeWhiteSpace,
  removeExtraWhiteSpace,
  sanitizeAddress,
  removeSpecialCharacter,
  capitalizeFirstLetter,
  specialSentenceWithDoubleQuotes,
  replaceCharacter,
  formatFloatString,
  writeString,
};

export default String;
