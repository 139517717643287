import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import { PracticeItemsType } from './types';

export const PRACTICE_ITEMS: PracticeItemsType = {
  1: {
    title: 'Generate Text To Practice',
    description:
      'Choose any topic you wish to practice today, either in English or your native. The AI will then generate a text based on that input, in your chosen native language.',
    Element: Step1,
  },
  2: {
    title: 'Translate Text To English',
    description:
      'Translate your generated text into English to the best of your ability. Attempt to think in English rather than translating word for word.',
    Element: Step2,
  },
  3: {
    title: 'Check Your Grammar Error',
    description:
      'At times, we may make errors in grammar, including tense and sentence structure, and use a less correct vocabulary to convey our thoughts.',
    Element: Step3,
  },
  4: {
    title: 'Reading Comprehension',
    description:
      "It is a great way to check how well you understood what you read. It's also a fun way to improve your critical thinking and analytical skills.",
    Element: Step4,
  },
  5: {
    title: 'Listen, Repeat, And Shadow',
    description:
      'The AI has produced a text alternative to your translation, with a more natural-sounding tone. Use the text-to-speech to improve your listening skills.',
    Element: Step5,
  },
  6: {
    title: 'Test Your Pronunciation',
    description:
      "It's now time to hone your speaking skills! Read each sentence below out loud, and you will receive an assessment of your pronunciation performance.",
    Element: Step6,
  },
};

export const LETTER_OPTIONS = ['A', 'B', 'C', 'D', 'E'];
