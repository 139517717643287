import { useMutation } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { Button, Loading, Radio, Text } from 'src/components';
import { PracticeDescriptionContainer, PracticeStepContainer } from '../styled';
import { Storage } from 'src/utils';
import { GeneralEnglishSprintStore } from 'src/contexts/generalEnglishSprint';
import { chooseDifficulty, createCourseID, generateQuestion } from 'src/api/speakingExamTrainer';
import { colors } from 'src/constants';

const Step1 = () => {
  const { dispatch, state } = useContext(GeneralEnglishSprintStore);
  const { speakingExamTrainer } = state;
  const [difficulty, setDifficulty] = useState<string>('Easy');

  // ----- create course -----
  const { mutate: onCreateCourseID, isLoading: isLoadingCreateCourseID } = useMutation(
    createCourseID,
    {
      onSuccess: (response) => {
        const courseID = response?.data?.course_id;
        if (courseID) {
          Storage.setUserData({ courseID });
        }
      },
    },
  );

  // ----- choose difficulty -----
  const { mutate: onChooseDifficulty, isLoading: isLoadingChooseDifficulty } = useMutation(
    chooseDifficulty,
    {
      onSuccess: () => {
        onGenerateQuestion();
      },
    },
  );

  // ----- generate question -----
  const { mutate: onGenerateQuestion, isLoading: isLoadingGenerateQuestion } = useMutation(
    generateQuestion,
    {
      onSuccess: (response) => {
        dispatch({
          type: 'SET_DATA',
          payload: {
            speakingExamTrainer: {
              ...speakingExamTrainer,
              difficulty: difficulty.toLowerCase(),
              question: response.data?.question,
              answer: response.data?.answer,
              answer_voice_url: response.data?.answer_voice_url,
              answer_voice_timestamps: response.data?.answer_voice_timestamps,
            },
          },
        });
      },
    },
  );

  const onGenerate = async () => {
    onChooseDifficulty({ difficulty: difficulty.toLowerCase() });
    // onGenerateQuestion();
  };

  useEffect(() => {
    onCreateCourseID();
  }, []);

  return (
    <>
      <PracticeDescriptionContainer>
        <Text id="txt-heading" size="22px" weight="500" value={'Speaking Exam Trainer'} />
        <Text
          id="txt-description"
          color={colors.grey.G300}
          size="14px"
          margin="0.5rem 0 2rem"
          value={
            'Choose your practice mode. With easy mode, you will be provided with the answer, where you only need to train your pronunciation.'
          }
        />
      </PracticeDescriptionContainer>

      <PracticeStepContainer>
        <Loading show={isLoadingChooseDifficulty || isLoadingGenerateQuestion} />

        <Text id="txt-outputType" value="Practice mode:" />
        <Radio
          value={difficulty}
          direction="horizontal"
          onChange={(val) => setDifficulty(val?.target.value)}
          choices={[
            {
              text: 'Easy',
            },
            {
              text: 'Difficult',
            },
          ]}
        />

        <Button
          className="mt-8"
          id="btn-generate"
          text="Generate Question"
          height="50px"
          onClick={onGenerate}
          disabled={isLoadingCreateCourseID}
        />
      </PracticeStepContainer>
    </>
  );
};

export default Step1;
