import { FC } from 'react';
import { Progress, ProgressBarContainer } from './styled';
import { ProgressBarProps } from './types';

const ProgressBar: FC<ProgressBarProps> = ({ totalStep, currentStep }) => {
  const width = (currentStep / totalStep) * 100;

  return (
    <ProgressBarContainer>
      <Progress width={`${width}%`} />
    </ProgressBarContainer>
  );
};

export default ProgressBar;
