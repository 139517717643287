export const DIFFICULTIES = [
  {
    id: 'easy',
    title: 'Easy',
    description: 'AI will reply with text and audio',
  },
  {
    id: 'hard',
    title: 'Difficult',
    description: 'AI will reply with audio only',
  },
];
