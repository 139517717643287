import { FC, useEffect, useState } from 'react';
import { Modal, Text } from 'src/components';
import { ModalExercisesProps } from '../types';
import {
  ButtonModal,
  ComingSoonBox,
  DescriptionList,
  ExercisesModalContainer,
  ExercisesModalImage,
} from '../styled';
import { colors, paths } from 'src/constants';
import { String } from 'src/utils';
import NotificationIcon from 'src/assets/images/icon-notification.svg';
import NotifiedIcon from 'src/assets/images/icon-notification-active.svg';
import { useMixpanel } from 'src/utils/hooks';
import { useNavigate } from 'react-router-dom';
import CoinsActiveIcon from 'src/assets/images/icon-avidx-coins-active.svg';
import currencyFormat from 'src/utils/currencyFormat';

const ModalExercises: FC<ModalExercisesProps> = ({ data, onLeave }) => {
  const navigate = useNavigate();
  const [didMount, setDidMount] = useState<boolean>(true);
  const [notified, setNotified] = useState<boolean>(false);

  const { trackEvent } = useMixpanel();

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    const resizeHandler = () => {
      const imageElement = document.getElementById('image-modal');
      if (imageElement) {
        const width = imageElement?.offsetWidth;
        const height = width * 0.5;
        imageElement.style.height = `${height}px`;
      }
    };

    if (didMount) {
      timer = setTimeout(() => {
        resizeHandler();
        setDidMount(false);
      }, 100);
    }
    return () => clearTimeout(timer);
  }, []);

  return (
    <Modal id="modalExercises" show padded={false} onLeave={onLeave}>
      <ExercisesModalImage id="image-modal" alt={data?.title} src={data?.image} width="100%" />

      {data.locked && !data.action && (
        <ComingSoonBox>
          <Text
            id="txt-coming-soon-modal"
            value="Coming Soon"
            size="11px"
            weight="500"
            color={colors.primary}
          />
        </ComingSoonBox>
      )}
      <ExercisesModalContainer>
        <Text id={`txt-title-modal`} value={data?.title} size="17px" weight="600" />
        <DescriptionList>
          {data?.item?.map((val) => {
            return (
              <li key={val}>
                <Text
                  id={`txt-decs-${String.convertToCamelCase(val)}`}
                  value={val}
                  size="14px"
                  color={colors.grey.G300}
                  lineHeight="20px"
                />
              </li>
            );
          })}
        </DescriptionList>
        {!data.locked && data.action ? (
          <>
            <ButtonModal
              id="btn-modal"
              text={
                data?.isValidBalance
                  ? currencyFormat(data?.price || 0, {
                      delimiter: ',',
                      currencySymbol: '',
                    })
                  : 'Purchase Coins Now'
              }
              background={colors.white}
              height="50px"
              icon={data?.isValidBalance ? CoinsActiveIcon : undefined}
              iconSize="23px"
              iconPosition="left"
              customTextColor={colors.textPrimary}
              onClick={() => {
                if (data.action && data.actionName) {
                  trackEvent(data.actionName);
                  if (data?.isValidBalance) {
                    navigate(data.action);
                  } else {
                    navigate(paths.PURCHASE_COINS);
                  }
                }
              }}
            />
            {!data?.isValidBalance && (
              <Text
                id="txt-errorBalance"
                value="Your Coins are not sufficient."
                size="12px"
                weight="500"
                color={colors.red}
                margin="3px 0 0"
              />
            )}
          </>
        ) : (
          <ButtonModal
            id="btn-modal"
            text={notified ? 'You will be notified' : 'Notify me when available'}
            background={colors.white}
            height="50px"
            icon={notified ? NotifiedIcon : NotificationIcon}
            iconPosition="left"
            iconSize="20px"
            customTextColor={colors.primary}
            onClick={() => {
              trackEvent('Click Notify Me', {
                feature: data?.title,
              });
              setNotified(true);
            }}
          />
        )}
      </ExercisesModalContainer>
    </Modal>
  );
};

export default ModalExercises;
