import Step4 from './Step4';
import { PracticeItemsType } from './types';

export const PRACTICE_ITEMS: PracticeItemsType = {
  1: {
    title: 'Reading Exam Trainer',
    description:
      'Answer the mockup exam reading questions below to the best of your ability. The AI will provide feed-back on how you can improve your answer.',
    Element: Step4,
  },
  2: {
    title: 'Reading Exam Trainer',
    description:
      'Answer the mockup exam reading questions below to the best of your ability. The AI will provide feed-back on how you can improve your answer.',
    Element: Step4,
  },
};

export const LETTER_OPTIONS = ['A', 'B', 'C', 'D', 'E'];
