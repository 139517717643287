import { CommonProps } from 'src/constants';
import { FC, createContext, useReducer } from 'react';
import { dataExercises } from './constants';
import { Action, ContextType, CoursesData, DataState, ExercisesType } from './types';

const initialState: DataState = {
  courseList: dataExercises,
  isInitial: true,
  balance: 0,
};

const processedCourses = (courses: Array<ExercisesType>, newData: CoursesData) => {
  const priceData = newData?.reduce((acc, curr) => {
    return {
      ...acc,
      [curr?.course_id]: curr?.course_price,
    };
  }, {});

  return courses?.map((val) => {
    const priceKey = val?.id as keyof typeof priceData;
    if (priceData?.[priceKey]) {
      return {
        ...val,
        price: priceData?.[priceKey],
      };
    }
    return val;
  });
};

const coursesReducer = (currentState: DataState, action: Action): DataState => {
  const { payload, type } = action;

  switch (type) {
    case 'INIT_STEP': {
      return initialState;
    }
    case 'SET_COURSES': {
      return {
        ...currentState,
        courseList: processedCourses(currentState?.courseList, payload),
        isInitial: false,
      };
    }
    default:
      return initialState;
  }
};

const objContext: ContextType = {
  state: initialState,
  dispatch: () => null,
};

const CoursesStore = createContext(objContext);
const { Provider } = CoursesStore;

const CoursesProvider: FC<CommonProps> = ({ children }) => {
  const [state, dispatch] = useReducer(coursesReducer, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { CoursesStore, CoursesProvider };
