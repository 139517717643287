import { FC, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@tanstack/react-query';

import { chooseDifficulty } from 'src/api/aiSpeakingPartner';
import { GeneralEnglishSprintStore } from 'src/contexts/generalEnglishSprint';
import { IChat, IChatResponse } from 'src/interfaces/aiSpeakingPartner';
import { useMixpanel } from 'src/utils/hooks';

import { DIFFICULTIES } from '../constants';

const AISpeakingPartner: FC = () => {
  const { state, dispatch } = useContext(GeneralEnglishSprintStore);
  const { aiSpeakingPartner } = state;

  const { trackEvent } = useMixpanel();

  // ----- choose difficulty -----
  const { mutate } = useMutation(chooseDifficulty, {
    onSuccess: (response) => {
      const { chats, difficulty } = response.data;
      trackEvent('ASP Set Difficulty', { difficulty });
      const chatsWithID: IChat[] = chats.map((item: IChatResponse) => {
        return {
          ...item,
          id: uuidv4(),
        };
      });

      dispatch({
        type: 'SET_DATA',
        payload: {
          aiSpeakingPartner: {
            ...aiSpeakingPartner,
            difficulty,
            chats: chatsWithID,
            isRecordingEnabled: true,
            status: 'IDLE',
          },
        },
      });
    },
  });

  const setDifficulty = (difficulty: string) => () => {
    dispatch({
      type: 'SET_DATA',
      payload: {
        aiSpeakingPartner: {
          ...aiSpeakingPartner,
          chats: [],
          difficulty,
          status: 'ASSISTANT_CHAT_LOADING',
        },
      },
    });

    mutate({ difficulty });
  };

  return (
    <>
      {/* ----- Difficulty Selection ----- */}
      <div className="flex mt-5 gap-4 justify-center">
        {DIFFICULTIES.map((item) => (
          <button
            key={item.id}
            className={`p-2 text-center shadow-md rounded-lg border w-1/3 hover:border-primary hover:bg-secondary transition-all duration-150 ${
              item.id === state.aiSpeakingPartner?.difficulty
                ? 'border-primary bg-secondary'
                : 'border-gray-primary bg-white'
            }`}
            onClick={setDifficulty(item.id)}
          >
            <span className="font-medium block text-text-primary">{item.title}</span>
            <span className="block text-xs text-text-secondary">{item.description}</span>
          </button>
        ))}
      </div>
    </>
  );
};

export default AISpeakingPartner;
