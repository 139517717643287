import { useContext } from 'react';
import { GeneralEnglishSprintStore } from 'src/contexts/generalEnglishSprint';
import Step3Easy from './Step3Easy';
import Step3Difficult from './Step3Difficult';

const Step3 = () => {
  const { state } = useContext(GeneralEnglishSprintStore);
  const { speakingExamTrainer } = state;

  return <>{speakingExamTrainer?.difficulty === 'easy' ? <Step3Easy /> : <Step3Difficult />}</>;
};

export default Step3;
