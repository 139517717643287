import { Action, ContextType, DataState, PayloadTypes, SetAnswer } from './types';
import { CommonProps } from 'src/constants';
import { Storage } from 'src/utils';
import { FC, createContext, useReducer } from 'react';

const initialState: DataState = {
  step: 1,
  topic: '',
  mode: '',
  generatedText: '',
  translation: undefined,
  quizzes: [],
  paraphrasedText: '',
  speechFromText: undefined,
  finish: false,
  aiSpeakingPartner: {
    difficulty: '',
    chats: [],
    isRecordingEnabled: false,
    status: 'IDLE',
    progress: {
      status: '',
      count: 0,
      limit: 10,
    },
    result: {
      words_count: 0,
      score: '',
    },
  },
  writingExamTrainer: {
    question: '',
    answer: '',
    ielts_estimated_score: 0,
    ielts_estimated_score_feedback: '',
    grammar_corrections: [],
    suggestions: [],
  },
  speakingExamTrainer: {
    difficulty: '',
    question: '',
    answer: '',
    answer_voice_url: '',
    answer_voice_timestamps: [],
    status: 'IDLE',
    voice_url: '',
    voice_eval: [],
    ielts_estimated_score: 0,
    ielts_estimated_score_feedback: '',
    grammar_corrections: [],
    suggestions: [],
  },
  listeningExamTrainer: {
    audio_transcript_url: '',
    quizzes: [],
  },
  readingExamTrainer: {
    text: '',
    quizzes: [],
  },
};

const generalEnglishSprintReducer = (currentState: DataState, action: Action): DataState => {
  const { payload, type } = action;

  switch (type) {
    case 'INIT_STEP': {
      Storage.setUserData({ courseID: '' });
      return initialState;
    }
    case 'SET_BACK_STEP': {
      if (currentState?.step === 1) {
        Storage.setUserData({ courseID: '' });
        return initialState;
      }

      return {
        ...currentState,
        step: currentState?.step - 1,
      };
    }
    case 'SET_DATA': {
      return {
        ...currentState,
        ...(payload as PayloadTypes),
        step: (payload as PayloadTypes)?.step || currentState?.step + 1,
      };
    }

    case 'SET_ANSWER_QUIZ': {
      const idx = (payload as SetAnswer)?.index;
      const newQuizzes = [...(currentState?.quizzes || [])];

      newQuizzes[idx] = {
        ...newQuizzes[idx],
        userAnswer: (payload as SetAnswer)?.answer,
      };

      return {
        ...currentState,
        quizzes: newQuizzes,
      };
    }
    case 'SET_NEXT_STEP':
      return {
        ...currentState,
        step: currentState?.step + 1,
      };

    case 'SET_FINISH': {
      return {
        ...currentState,
        finish: true,
      };
    }

    default:
      return initialState;
  }
};

const objContext: ContextType = {
  state: initialState,
  dispatch: () => null,
};

const GeneralEnglishSprintStore = createContext(objContext);
const { Provider } = GeneralEnglishSprintStore;

const GeneralEnglishSprintProvider: FC<CommonProps> = ({ children }) => {
  const [state, dispatch] = useReducer(generalEnglishSprintReducer, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { GeneralEnglishSprintStore, GeneralEnglishSprintProvider };
